import React, { memo, useCallback, useEffect, useState } from 'react'
import queryBarStyle from '../SearchQueryBar/style.less'
import endOfDay from 'date-fns/endOfDay'
import { useSearchParams } from 'react-router-dom'
import DateRangePicker from 'rsuite/DateRangePicker'
import { predefinedRanges } from '@yaak/components/helpers/dateRangePicker'
import { useTagsStore } from '../stores/Tags'
import { useShallow } from 'zustand/react/shallow'
import { useTag } from '../../customHooks/useTag'
import { formatDateRange } from '@yaak/admin/src/helpers/time'
import { OPERATORS_SYMBOL_MAPPING } from '../SearchQueryBar/utils'

export interface FilterMenuInputTagProps {
  tagKey: string
}

const FilterMenuDateTag = ({ tagKey }: FilterMenuInputTagProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [range, setRange] = useState<[Date, Date] | null>()
  const { tags, update } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
      update: state.update,
    }))
  )
  const tag = useTag(tagKey, tags)

  useEffect(() => {
    const begin = searchParams.getAll('begin')[0]
    const end = searchParams.getAll('end')[0]
    if (begin && end) {
      setRange([new Date(begin), new Date(end)])
    } else {
      setRange(null)
    }
  }, [searchParams])

  const onDatePickerChange = useCallback(
    (value: any) => {
      if (value?.[0] && value?.[1]) {
        searchParams.set('begin', `${endOfDay(value[0]).toISOString()}`)
        searchParams.set('end', `${endOfDay(value[1]).toISOString()}`)
      } else {
        searchParams.delete('begin')
        searchParams.delete('end')
        setRange(null)
      }
      setSearchParams(searchParams)
      if (tag) {
        const queryParamToEdit = tag.tagValue.split(' ').filter((t) => t !== '')
        if (queryParamToEdit) {
          const newTag = [queryParamToEdit[0], queryParamToEdit[1]]
          newTag[2] =
            value?.[0] && value?.[1]
              ? formatDateRange(endOfDay(value[0]), endOfDay(value[1]))
              : ''
          tag.tagValue = newTag.join(' ')
          tag.tagUrl = [
            tag.name,
            OPERATORS_SYMBOL_MAPPING[queryParamToEdit[1]],
            value?.[0] && value?.[1]
              ? formatDateRange(endOfDay(value[0]), endOfDay(value[1]))
              : '',
          ].join(' ')
          tags && update(tags.filter((t) => (t.name === tagKey ? tag : t)))
        }
      }
    },
    [searchParams, tag, tagKey, tags]
  )

  return (
    <div className={queryBarStyle.datePickerContainer}>
      <DateRangePicker
        character={'-'}
        size="lg"
        ranges={predefinedRanges}
        placement={'bottomStart'}
        onChange={onDatePickerChange}
        value={range}
        editable={false}
      />
    </div>
  )
}

export default memo(FilterMenuDateTag)
