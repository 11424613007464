import {
  MetadataField,
  Scenario,
  SearchSessionData,
  SessionData,
} from '@yaak/components/services/api/api'
import { getDriveType } from './devices'
import React, { Dispatch, SetStateAction } from 'react'
import { toastType } from '@yaak/components/src/Toast/Toast'
import Progress from '@yaak/components/src/Progress'
import Icon from '@yaak/components/src/Icon'
import DataStatus from '../components/DataStatus'
import { transpose } from './common'
import { IconSizes } from '@yaak/components/src/Icon/Icon'
import { NavigateFunction } from 'react-router-dom'
import {
  formatTimestamp,
  formatTimestampToTime,
  toHoursMinutesSeconds,
} from './time'
import {
  BitRateGridElement,
  GridCopyIconElement,
  GridDriverElement,
  GridDriveStatusElement,
  GridInstructorElement,
  GridKitElement,
  GridPartnerElement,
  GridTextElement,
  GridVehicleElement,
  MemoGridLightingIcons,
  MemoGridWeatherIcons,
} from '@yaak/components/src/Grid/GridElements'
import { Version } from '@yaak/components/src/types'
import { STATUS_CODES } from './statusCodes'
import { getOffset } from '@yaak/nutron/src/utils/player'
import { getSafetyScoreBadgeType } from './score'
import Badge from '@yaak/components/src/Badge'

export const HEADERS = [
  'Time',
  'Partner',
  'Type',
  'Vehicle',
  'Kit ID',
  'Driver',
  'Instructor',
  'Status',
  'Data',
  'Bitrate',
  '',
]

const DSADMIN_HEADERS = ['Time', 'Type', 'Vehicle', 'Driver', 'Data']
const DSADMIN_HEADERS_STUDENTS = ['Time', 'Type', 'Vehicle', 'Data']
const DSADMIN_HEADERS_VEHICLES = [
  'Time',
  'Type',
  'Driver',
  'Instructor',
  'Data',
]

export const getCopyLink = (
  session: SessionData | Scenario | SearchSessionData,
  context: string | null,
  datasetId?: string
) => {
  const sessionData = session as SessionData
  const sessionScenario = session as Scenario

  const id = sessionScenario.sessionId || sessionData.id
  const { startOffset, endOffset } = sessionScenario
  const params =
    (session as any).type !== 'session' &&
    startOffset !== undefined &&
    endOffset !== undefined
      ? `?begin=${startOffset}&end=${endOffset}&context=${parseInt(
          context || '0'
        )}&offset=${getOffset(startOffset, parseInt(context || '0'))}`
      : ''
  const redirectUrl = `/dataset/session-logs/${id}${params}${
    datasetId ? `${params ? '&' : '?'}datasetId=${datasetId}` : ''
  }`
  const baseUrl = window.location.protocol + '//' + window.location.host
  return `${baseUrl}${redirectUrl}`
}

export type statusDataType = {
  metadataStatus?: number
  fullVideoStatus?: number
  safetyAIStatus?: number
}

type resultType = {
  value: number
  data: statusDataType
}

interface MappedDrivesTypeOptions {
  withPartner: boolean
  withInstructor: boolean
  withKit: boolean
  dsAdmin: boolean
  withDriver: boolean
  withVehicle: boolean
  fixedColumns: number
  yaakAdmin: boolean
  context: string | null
}

export const getDataComponent = (
  result: resultType,
  dsAdmin = false,
  version: Version = Version.v1
) => {
  return result.value === 10
    ? {
        text: (
          <DataStatus
            icon={<Progress percentage={result.value} />}
            data={result.data}
            type={2}
            dsAdmin={dsAdmin}
            version={version}
          />
        ),
        popover: true,
      }
    : result.value === 33
    ? {
        text: (
          <DataStatus
            icon={<Progress percentage={result.value} />}
            data={result.data}
            type={2}
            dsAdmin={dsAdmin}
            version={version}
          />
        ),
        popover: true,
      }
    : result.value === 67
    ? {
        text: (
          <DataStatus
            icon={<Progress percentage={result.value} />}
            data={result.data}
            type={2}
            dsAdmin={dsAdmin}
            version={version}
          />
        ),
        popover: true,
      }
    : result.value === 1
    ? {
        text: (
          <DataStatus
            icon={
              <Icon
                name={version === Version.v2 ? 'CheckmarkCircle' : 'Success'}
                version={version}
                size={IconSizes.medium}
              />
            }
            data={result.data}
            type={3}
            dsAdmin={dsAdmin}
            version={version}
          />
        ),
        popover: true,
      }
    : result.value === -1
    ? {
        text: (
          <DataStatus
            icon={
              <Icon name={'Error'} version={version} size={IconSizes.medium} />
            }
            data={result.data}
            type={4}
            dsAdmin={dsAdmin}
            version={version}
          />
        ),
        popover: true,
      }
    : result.value === 0
    ? {
        text: (
          <DataStatus
            icon={<Progress />}
            data={result.data}
            type={result.value}
            dsAdmin={dsAdmin}
            version={version}
          />
        ),
        popover: true,
      }
    : null
}

export const getSessionStatus = (data: statusDataType) => {
  const { metadataStatus, fullVideoStatus, safetyAIStatus } = data
  return ((metadataStatus === undefined || metadataStatus === null) &&
    fullVideoStatus === STATUS_CODES.PROCESSING &&
    safetyAIStatus === STATUS_CODES.PROCESSED) ||
    ((metadataStatus === undefined || metadataStatus === null) &&
      fullVideoStatus === STATUS_CODES.PROCESSED &&
      safetyAIStatus === STATUS_CODES.PROCESSING) ||
    (metadataStatus === STATUS_CODES.PROCESSING &&
      (fullVideoStatus === undefined || fullVideoStatus === null) &&
      safetyAIStatus === STATUS_CODES.PROCESSED) ||
    (metadataStatus === STATUS_CODES.PROCESSED &&
      (fullVideoStatus === undefined || fullVideoStatus === null) &&
      safetyAIStatus === STATUS_CODES.PROCESSING) ||
    (metadataStatus === STATUS_CODES.PROCESSING &&
      fullVideoStatus === STATUS_CODES.PROCESSED &&
      (safetyAIStatus === undefined || safetyAIStatus === null)) ||
    (metadataStatus === STATUS_CODES.PROCESSED &&
      fullVideoStatus === STATUS_CODES.PROCESSING &&
      (safetyAIStatus === undefined || safetyAIStatus === null))
    ? {
        value: 10,
        data: {
          metadataStatus,
          fullVideoStatus,
          safetyAIStatus,
        },
      }
    : (metadataStatus === STATUS_CODES.PROCESSED &&
        fullVideoStatus === STATUS_CODES.PROCESSING &&
        safetyAIStatus === STATUS_CODES.UPLOADING) ||
      (metadataStatus === STATUS_CODES.PROCESSED &&
        fullVideoStatus === STATUS_CODES.UPLOADING &&
        safetyAIStatus === STATUS_CODES.PROCESSING) ||
      (metadataStatus === STATUS_CODES.PROCESSING &&
        fullVideoStatus === STATUS_CODES.PROCESSED &&
        safetyAIStatus === STATUS_CODES.UPLOADING) ||
      (metadataStatus === STATUS_CODES.PROCESSING &&
        fullVideoStatus === STATUS_CODES.PROCESSED &&
        safetyAIStatus === STATUS_CODES.WAITING) ||
      (metadataStatus === STATUS_CODES.UPLOADING &&
        fullVideoStatus === STATUS_CODES.PROCESSED &&
        safetyAIStatus === STATUS_CODES.PROCESSING) ||
      (metadataStatus === STATUS_CODES.PROCESSING &&
        fullVideoStatus === STATUS_CODES.UPLOADING &&
        safetyAIStatus === STATUS_CODES.PROCESSED) ||
      (metadataStatus === STATUS_CODES.UPLOADING &&
        fullVideoStatus === STATUS_CODES.PROCESSING &&
        safetyAIStatus === STATUS_CODES.PROCESSED) ||
      (metadataStatus === STATUS_CODES.UPLOADING &&
        fullVideoStatus === STATUS_CODES.PROCESSING &&
        safetyAIStatus === STATUS_CODES.WAITING) ||
      (metadataStatus === STATUS_CODES.PROCESSED &&
        fullVideoStatus === STATUS_CODES.PROCESSING_FAILED &&
        safetyAIStatus === STATUS_CODES.UPLOADING) ||
      (metadataStatus === STATUS_CODES.PROCESSED &&
        fullVideoStatus === STATUS_CODES.UPLOADING &&
        safetyAIStatus === STATUS_CODES.PROCESSING_FAILED) ||
      (metadataStatus === STATUS_CODES.PROCESSING_FAILED &&
        fullVideoStatus === STATUS_CODES.PROCESSED &&
        safetyAIStatus === STATUS_CODES.UPLOADING) ||
      (metadataStatus === STATUS_CODES.PROCESSING_FAILED &&
        fullVideoStatus === STATUS_CODES.PROCESSED &&
        safetyAIStatus === STATUS_CODES.WAITING) ||
      (metadataStatus === STATUS_CODES.UPLOADING &&
        fullVideoStatus === STATUS_CODES.PROCESSED &&
        safetyAIStatus === STATUS_CODES.PROCESSING_FAILED) ||
      (metadataStatus === STATUS_CODES.PROCESSING_FAILED &&
        fullVideoStatus === STATUS_CODES.UPLOADING &&
        safetyAIStatus === STATUS_CODES.PROCESSED) ||
      (metadataStatus === STATUS_CODES.UPLOADING &&
        fullVideoStatus === STATUS_CODES.PROCESSING_FAILED &&
        safetyAIStatus === STATUS_CODES.PROCESSED) ||
      (metadataStatus === STATUS_CODES.UPLOADING &&
        fullVideoStatus === STATUS_CODES.PROCESSING_FAILED &&
        safetyAIStatus === STATUS_CODES.WAITING)
    ? {
        value: 33,
        data: {
          metadataStatus,
          fullVideoStatus,
          safetyAIStatus,
        },
      }
    : (metadataStatus === STATUS_CODES.PROCESSED &&
        fullVideoStatus === STATUS_CODES.PROCESSED &&
        safetyAIStatus === STATUS_CODES.PROCESSING) ||
      (metadataStatus === STATUS_CODES.PROCESSED &&
        fullVideoStatus === STATUS_CODES.PROCESSING &&
        safetyAIStatus === STATUS_CODES.PROCESSED) ||
      (metadataStatus === STATUS_CODES.PROCESSING &&
        fullVideoStatus === STATUS_CODES.PROCESSED &&
        safetyAIStatus === STATUS_CODES.PROCESSED)
    ? {
        value: 67,
        data: {
          metadataStatus,
          fullVideoStatus,
          safetyAIStatus,
        },
      }
    : metadataStatus === STATUS_CODES.PROCESSED &&
      fullVideoStatus === STATUS_CODES.PROCESSED &&
      safetyAIStatus === STATUS_CODES.PROCESSED
    ? {
        value: 1,
        data: {
          metadataStatus,
          fullVideoStatus,
          safetyAIStatus,
        },
      }
    : metadataStatus === STATUS_CODES.PROCESSING_FAILED ||
      fullVideoStatus === STATUS_CODES.PROCESSING_FAILED ||
      safetyAIStatus === STATUS_CODES.PROCESSING_FAILED
    ? {
        value: -1,
        data: {
          metadataStatus,
          fullVideoStatus,
          safetyAIStatus,
        },
      }
    : {
        value: 0,
        data: {
          metadataStatus,
          fullVideoStatus,
          safetyAIStatus,
        },
      }
}

const getRows = (
  data: SessionData[],
  activeDrivesIds: string[],
  setShowToast: Dispatch<SetStateAction<toastType | null>>,
  navigate: NavigateFunction,
  matches: boolean,
  options: MappedDrivesTypeOptions
) => {
  return data.map((session: SessionData) => {
    const row = []

    row.push(
      GridTextElement({
        text: formatTimestamp(session.startTimestamp),
        ellipsis: matches,
      })
    )
    options?.withPartner &&
      row.push(
        GridPartnerElement({
          partner: session.partner,
          ellipsis: true,
          navigate,
        })
      )
    row.push(
      GridTextElement({
        ellipsis: matches,
        text: getDriveType(session.driveType),
      })
    )
    options?.withVehicle &&
      row.push(
        GridVehicleElement({
          vin: session.vin,
          dongleId: session.dongleId,
          ellipsis: matches,
          navigate,
          dsAdmin: options?.dsAdmin,
        })
      )
    options?.withKit &&
      row.push(
        GridKitElement({
          id: session.dongleId,
          ellipsis: matches,
          navigate,
        })
      )
    options?.withDriver &&
      row.push(
        GridDriverElement({
          driver: session.driver,
          ellipsis: matches,
          navigate,
          dsAdmin: options.dsAdmin,
        })
      )
    options?.withInstructor &&
      row.push(
        GridInstructorElement({
          session,
          ellipsis: matches,
          navigate,
          dsAdmin: options.dsAdmin,
        })
      )
    !options?.dsAdmin &&
      row.push(
        GridDriveStatusElement({
          session,
          activeDrivesIds,
        })
      )
    row.push(
      getDataComponent(
        getSessionStatus({
          metadataStatus: session.metadataStatus,
          fullVideoStatus: session.fullVideoStatus,
          safetyAIStatus: session.safetyAIStatus,
        }),
        options?.dsAdmin
      )
    )
    !options?.dsAdmin &&
      row.push(
        BitRateGridElement({
          session,
        })
      )
    !options?.dsAdmin &&
      row.push(
        GridCopyIconElement({
          data: session,
          name: 'drive',
          setShowToast,
          text: 'Drive ID copied to clipboard',
        })
      )
    return row
  })
}

interface MappedDrivesType {
  sessionsData: SessionData[] | Scenario[]
  activeSessionsData: SessionData[]
  setShowToast: Dispatch<SetStateAction<toastType | null>>
  totalCount?: number
  matches: boolean
  classes?: any
  options?: MappedDrivesTypeOptions
  navigate: NavigateFunction
}

const getHeaders = (options?: MappedDrivesTypeOptions) => {
  let headers = HEADERS
  if (options?.dsAdmin) {
    headers = !options?.withVehicle
      ? DSADMIN_HEADERS_VEHICLES
      : options?.withDriver
      ? DSADMIN_HEADERS
      : DSADMIN_HEADERS_STUDENTS
  } else if (
    !options?.withPartner &&
    !options?.withInstructor &&
    !options?.withKit
  ) {
    headers = HEADERS.filter((header, idx) => idx !== 1)
      .filter((header, idx) => idx !== 5)
      .filter((header, idx) => idx !== 3)
  } else if (!options?.withPartner) {
    headers = HEADERS.filter((header, idx) => idx !== 1)
  } else if (!options?.withInstructor) {
    headers = HEADERS.filter((header, idx) => idx !== 6)
  } else if (!options?.withKit) {
    headers = HEADERS.filter((header, idx) => idx !== 4)
  }
  return headers
}

export const getMappedDrives = ({
  sessionsData,
  activeSessionsData = [],
  setShowToast,
  totalCount,
  matches,
  options = {
    withPartner: true,
    withInstructor: true,
    withKit: true,
    dsAdmin: false,
    withDriver: true,
    withVehicle: true,
    fixedColumns: 0,
    yaakAdmin: false,
    context: '',
  },
  navigate,
}: MappedDrivesType) => {
  const activeDrivesIds = activeSessionsData.map(
    (activeSession) => activeSession.id
  )

  const ids = activeSessionsData
    .concat(sessionsData as SessionData[])
    .map((session) => session.id)

  const columns = transpose(
    getRows(
      sessionsData as SessionData[],
      activeDrivesIds,
      setShowToast,
      navigate,
      matches,
      options
    )
  )

  columns.map((row: any, i: number) =>
    getHeaders(options).map((header, k) => i === k && row.unshift(header))
  )

  return {
    options: {
      fixedColumns: options?.fixedColumns || 4,
      activeDrivesIds,
      active: activeDrivesIds.map((id) => ids.indexOf(id)),
      totalCount,
    },
    rows: columns,
  }
}

const getDataRow = (type: string, value: string | number | string[]) => {
  if (['time'].some((v) => type.includes(v))) {
    return GridTextElement({
      text: formatTimestampToTime(value as string),
      version: Version.v2,
    })
  } else if (['duration'].some((v) => type.includes(v))) {
    return GridTextElement({
      text: toHoursMinutesSeconds(value as number),
      version: Version.v2,
    })
  } else if (['int', 'float'].some((v) => type.includes(v))) {
    return GridTextElement({
      text: value?.toString() || '-',
      version: Version.v2,
      align: 'right',
    })
  } else if (type === 'array_lighting') {
    const val = value as string[]
    return GridTextElement({
      text: val?.length > 0 ? <MemoGridLightingIcons lightning={val} /> : '-',
      version: Version.v2,
    })
  } else if (type === 'array_weather') {
    const val = value as string[]
    return GridTextElement({
      text:
        val?.length > 0 ? (
          <MemoGridWeatherIcons weatherConditions={val} />
        ) : (
          '-'
        ),
      version: Version.v2,
    })
  } else if (type === 'score') {
    const val = value as number
    return GridTextElement({
      text:
        val !== undefined ? (
          <Badge
            label={(val * 100).toFixed(0) || '-'}
            type={getSafetyScoreBadgeType(val)}
          />
        ) : (
          '-'
        ),
      version: Version.v2,
    })
  } else {
    return GridTextElement({
      ellipsis: true,
      text: value || '-',
      version: Version.v2,
    })
  }
}

interface DatasetMappedDrivesType {
  sessionsData: any[]
  setShowToast: Dispatch<SetStateAction<toastType | null>>
  totalCount?: number
  metadataFields: MetadataField[]
  context: string | null
  datasetId: string
}

export const getDatasetMappedDrives = ({
  sessionsData,
  totalCount,
  metadataFields,
  context,
  datasetId,
  setShowToast,
}: DatasetMappedDrivesType) => {
  const columns = metadataFields.map(({ displayName, name, type }) => {
    const columnData: any[] = sessionsData.map((data) =>
      getDataRow(
        type,
        data.properties?.[name] !== undefined
          ? data.properties?.[name]
          : data[name]
      )
    )
    return [displayName, ...columnData]
  })

  columns.push([
    '',
    ...sessionsData.map((session) => {
      return GridCopyIconElement({
        data: session,
        name: '',
        tooltipText: 'Copy link',
        copyText: getCopyLink(session, context, datasetId),
        setShowToast,
        text: 'Link copied to clipboard',
        version: Version.v2,
      })
    }),
  ])

  return {
    options: { fixedColumns: 1, activeDrivesIds: [], active: [], totalCount },
    rows: columns,
  }
}
