import { MenuItem, Select, TextField } from '@mui/material'
import style from './style.less'
import Typography, { TypographyTypes } from '@yaak/components/src/Typography'
import React from 'react'
import classNames from 'classnames'
import { Dataset } from '@yaak/components/services/api/api'

interface INewCollectionForm {
  newFormValue: {
    name: string
    datasetId: string | undefined
  }
  datasetId?: string
  isNewCollection?: boolean
  onFormChange: (name: string, value: string) => void
  datasetsList: Dataset[]
}

const NewCollectionForm = (props: INewCollectionForm) => {
  const {
    newFormValue,
    datasetsList,
    datasetId,
    isNewCollection,
    onFormChange,
  } = props
  return (
    <div className={style.form}>
      <div className={style.formItem}>
        <Typography type={TypographyTypes.label} color="color-neutral-040">
          Name*
        </Typography>
        <TextField
          placeholder="e.g. High-speed lane changes on highways"
          value={newFormValue.name}
          onChange={(e) => onFormChange('name', e.target.value)}
          InputProps={{ classes: { input: style.formPlaceholder } }}
        />
      </div>
      <div className={style.formItem}>
        <Typography type={TypographyTypes.label} color="color-neutral-040">
          Dataset*
        </Typography>
        <Select
          value={newFormValue.datasetId}
          disabled={!isNewCollection && !!datasetId}
          className={classNames(
            !(!isNewCollection && !!datasetId) ? style.select : undefined
          )}
          onChange={(e) => onFormChange('datasetId', e.target.value)}
        >
          {datasetsList.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={style.formItem}>
        <Typography type={TypographyTypes.label} color="color-neutral-040">
          Training
        </Typography>
        <div className={style.checkbox}>
          <input type="checkbox" disabled />
          <Typography type={TypographyTypes.label} color="color-neutral-075">
            Train with LeRobot (coming soon)
          </Typography>
        </div>
        <div className={style.checkbox}>
          <input type="checkbox" disabled />
          <Typography type={TypographyTypes.label} color="color-neutral-075">
            Train with rbyte (coming soon)
          </Typography>
        </div>
      </div>
    </div>
  )
}
export default NewCollectionForm
