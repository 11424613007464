import React, { ChangeEvent, MouseEvent } from 'react'
import style from './style.less'

export interface SwitchProps {
  onClick?: (event: MouseEvent<HTMLElement>) => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  checked: boolean
  disabled?: boolean
}

const Switch: React.FunctionComponent<SwitchProps> = ({
  onChange,
  onClick,
  checked,
  disabled = false,
}) => (
  <label
    aria-label="switch"
    className={style.switch}
    onClick={onClick}
    data-testid={'switch'}
  >
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      data-testid={'switchInput'}
      disabled={disabled}
    />
    <span className={style.round}></span>
  </label>
)

export default Switch
