import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import style from './style.less'
import Select from '@mui/material/Select'
import Icon from '../Icon/Icon'
import MenuItem from '@mui/material/MenuItem'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import Chip from '@mui/material/Chip'
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'
import { useClickOutside } from '../../customHooks/useClickOutside'
import Button from '../Button'
import SaveEditQueryDialog from './dialogs/SaveEditQueryDialog'
import classNames from 'classnames'
import WarningDialog from '../WarningDialog'
import DateRangePicker from 'rsuite/DateRangePicker'
import { predefinedRanges } from '../../helpers/dateRangePicker'
import endOfDay from 'date-fns/endOfDay'
import {
  deleteSavedQuery,
  editSavedQuery,
  getSavedQueries,
  saveSearchQuery,
} from '../../services/api/api'
import { ToastContext, ToastContextType } from '../../context/toastContext'
import { useAuth0 } from '@auth0/auth0-react'
import { parseSearchParams } from '../DrivesOverview/utils'
import {
  findQueryById,
  GROUPS,
  GROUPS_MAPPING,
  queryToTags,
  SUBGROUPS,
  SUBGROUPS_MAPPING,
} from './utils'

const uuid = require('uuid')

const AUTOCOMPLETE_TABS = {
  NEW: 'New query',
  SAVED: 'Saved queries',
}

const SEARCH_VALUES = [
  'drive_type',
  'kit_id',
  'bitrate',
  'driver',
  'drive_id',
  // 'location',
  'incident',
  'score',
  'brake_pedal_score',
  'gas_pedal_score',
  'steering_angle_score',
  'lighting',
  'conditions',
  'steering_angle',
  'brake_pedal',
  'gas_pedal',
  'gear',
  'speed',
  'acceleration',
  'turn_signal',
  'max_speed',
  'traffic_facilities',
  'traffic_junctions',
  'road_attributes',
  'road_type',
  'lane_count',
  'road_surface',
]

export type MergedListKey =
  | 'key'
  | 'value'
  | 'group'
  | 'subgroup'
  | 'savedQuery'

interface createOptionProps {
  id?: string
  conditions?: any
  value: string
  group?: string
  subgroup?: string
  key?: string
  onCopy?: (value: string) => void
  onEdit?: (value: string, id?: string) => void
  onDelete?: (value: string, id?: string) => void
  savedQuery?: boolean
  special?: boolean
}

export interface Option {
  id?: string
  conditions?: any
  value: string
  name: string
  item: (props: React.HTMLAttributes<HTMLDivElement>) => any
  group?: string
  subgroup?: string
  savedQuery: boolean
}

export const createOption = ({
  id,
  conditions,
  value,
  group,
  subgroup,
  key,
  onCopy,
  onEdit,
  onDelete,
  savedQuery = false,
  special,
}: createOptionProps): Option => {
  return {
    id,
    conditions,
    savedQuery,
    value: key ? `${key}_${value}` : `${uuid.v4()}_${value}`,
    name: value,
    item: (props: React.HTMLAttributes<HTMLDivElement>) =>
      subgroup ? (
        <div key={key || `${uuid.v4()}_${value}`}>
          <div
            className={classNames(
              style.subgroup,
              special ? style.notFirstSubgroup : undefined
            )}
          >
            {subgroup}
          </div>
          <div
            {...props}
            className={style.option}
            key={key || `${uuid.v4()}_${value}`}
          >
            {value}
          </div>
        </div>
      ) : (
        <div
          {...props}
          className={classNames(
            style.option,
            onEdit ? style.savedQueryOption : undefined
          )}
          key={key || `${uuid.v4()}_${value}`}
        >
          {value}
          {onEdit && (
            <div className={style.optionActions}>
              <Icon
                name={'Copy'}
                className={style.icon}
                onClick={(event) => {
                  event.stopPropagation()
                  onCopy?.(value)
                }}
              />
              <Icon
                name={'Edit'}
                className={style.icon}
                onClick={(event) => {
                  event.stopPropagation()
                  onEdit?.(value, id)
                }}
              />
              <Icon
                name={'Delete'}
                className={style.icon}
                onClick={(event) => {
                  event.stopPropagation()
                  onDelete?.(value, id)
                }}
              />
            </div>
          )}
        </div>
      ),
    group,
  }
}

interface OptionWithKey {
  value: string
  key: string
}

const createOptions = (options: string[] | OptionWithKey[], group?: string) =>
  options.map((option) =>
    createOption({
      value: ((option as OptionWithKey).value as string) || (option as string),
      key: (option as OptionWithKey).key as string,
      group,
    })
  )

type VALUES_KEYS =
  | 'drive_type'
  | 'incident'
  | 'lighting'
  | 'condition'
  | 'gear'
  | 'turn_signal'
  | 'road_type'
  | 'traffic_facilities'
  | 'traffic_junctions'
  | 'road_attributes'
  | 'road_surface'
  | 'max_speed'

const VALUES: Record<VALUES_KEYS, Option[]> = {
  drive_type: createOptions(['Student', 'Expert']),
  incident: createOptions(
    [
      'Visual check',
      'Distance',
      'Handling',
      'Turn signal',
      'Signs & lights',
      'Speed',
      'Placement',
      'Right of way',
      'Instructor pedal',
    ].sort()
  ),
  lighting: createOptions(['Day', 'Night', 'Transition']),
  condition: createOptions(
    [
      'Thunderstorm',
      'Drizzle',
      'Rain',
      'Snow',
      'Mist',
      'Smoke',
      'Haze',
      'Dust',
      'Fog',
      'Sand',
      'Ash',
      'Squall',
      'Tornado',
      'Clear',
      'Clouds',
    ].sort()
  ),
  gear: createOptions(
    ['Neutral', 'Reverse', 'Drive', 'Park', 'Breaking'].sort()
  ),
  turn_signal: createOptions(['Left', 'Right', 'Off']),
  road_type: createOptions(
    [
      'Highway',
      'Trunk',
      'Primary',
      'Secondary',
      'Tertiary',
      'Unclassified',
      'Residential',
      'Motorway Link',
      'Trunk Link',
      'Primary Link',
      'Tertiary Link',
      'Living Street',
      'Service',
      'Pedestrian',
      'Track',
      'Bus Guideway',
      'Escape',
      'Raceway',
      'Road',
      'Busway',
      'Footway',
      'Bridleway',
      'Steps',
      'Corridor',
      'Path',
      'Via Ferrata',
      'Sidewalk',
      'Crossing',
      'Traffic Island',
      'Cycleway',
    ].sort()
  ),
  traffic_facilities: createOptions(
    [
      'BUS_STOP',
      'LOWERED_KERB',
      'PEDESTRIAN_CROSSING',
      'TRAFFIC_CALMER',
      'TRAIN_CROSSING',
      'TRAM_TRACKS',
    ].sort()
  ),
  traffic_junctions: createOptions(
    [
      'ENTERING_MOVING_TRAFFIC',
      'MERGE_IN_OUT_ON_HIGHWAY',
      'MULTILANE_LEFT',
      'MULTILANE_RIGHT',
      'PROTECTED_LEFT',
      'RIGHT_BEFORE_LEFT',
      'JUNCTION_RIGHT_BEFORE_LEFT',
      'RIGHT_TURN_ON_RED',
      'ROAD_NARROWS',
      'ROUNDABOUT',
      'UNPROTECTED_LEFT',
      'UNPROTECTED_RIGHT_WITH_BIKE',
    ].sort()
  ),
  road_attributes: createOptions(
    [
      'GIVE_WAY',
      'HILL_DRIVE',
      'LIMITED_ACCESS_WAY',
      'LIVING_STREET',
      'LOW_SPEED_REGION',
      'ONE_WAY',
      'PRIORITY_FORWARD_BACKWARD',
      'PRIORITY_WAY',
      'RIGHT_OF_WAY',
      'TUNNEL',
    ].sort()
  ),
  max_speed: createOptions(['Variable', 'Walk', 'None']),
  road_surface: [
    ...createOptions(
      [
        'paved',
        'asphalt',
        'chipseal',
        'concrete',
        'concrete:lanes',
        'concrete:plates',
        'paving_stones',
        'sett',
        'unhewn_cobblestone',
        'cobblestone',
        'bricks',
        'metal',
        'wood',
        'stepping_stones',
        'rubber',
        'tiles',
      ].sort(),
      'PAVED'
    ),
    ...createOptions(
      [
        'unpaved',
        'compacted',
        'fine_gravel',
        'gravel',
        'shells',
        'rock',
        'pebblestone',
        'ground',
        'dirt',
        'earth',
        'grass',
        'grass_paver',
        'metal_grid',
        'mud',
        'sand',
        'woodchips',
        'snow',
        'ice',
        'salt',
      ].sort(),
      'UNPAVED'
    ),
    ...createOptions(
      [
        'clay',
        'tartan',
        'artificial_turf',
        'acrylic',
        'carpet',
        'plastic',
      ].sort(),
      'SPECIAL'
    ),
  ],
}

type OPERATORS_KEYS =
  | 'drive_type'
  | 'kit_id'
  | 'bitrate'
  | 'driver'
  | 'drive_id'
  // | 'location'
  | 'partner'
  | 'incident'
  | 'score'
  | 'brake_pedal_score'
  | 'gas_pedal_score'
  | 'steering_angle_score'
  | 'lighting'
  | 'condition'
  | 'steering_angle'
  | 'brake_pedal'
  | 'gas_pedal'
  | 'gear'
  | 'speed'
  | 'acceleration'
  | 'turn_signal'
  | 'max_speed'
  | 'traffic_facilities'
  | 'traffic_junctions'
  | 'road_attributes'
  | 'road_type'
  | 'lane_count'
  | 'road_surface'

type SUBGROUPS_OPERATORS_KEYS =
  | 'incident'
  | 'lighting'
  | 'steering_angle'
  | 'max_speed'
  | 'road_type'

const createAllOperators = () =>
  createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
    { value: '<', key: uuid.v4() },
    { value: '<=', key: uuid.v4() },
    { value: '>', key: uuid.v4() },
    { value: '>=', key: uuid.v4() },
  ])

const OPERATORS: Record<OPERATORS_KEYS, Option[]> = {
  drive_type: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  kit_id: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  bitrate: createAllOperators(),
  driver: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  drive_id: createOptions([{ value: '=', key: uuid.v4() }]),
  // location: createOptions([
  //   { value: '=', key: uuid.v4() },
  //   { value: '!=', key: uuid.v4() },
  // ]),
  partner: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  incident: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  score: createAllOperators(),
  brake_pedal_score: createAllOperators(),
  gas_pedal_score: createAllOperators(),
  steering_angle_score: createAllOperators(),
  lighting: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  condition: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  steering_angle: createAllOperators(),
  gas_pedal: createAllOperators(),
  brake_pedal: createAllOperators(),
  gear: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  speed: createAllOperators(),
  acceleration: createAllOperators(),
  turn_signal: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  max_speed: createAllOperators(),
  traffic_facilities: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  traffic_junctions: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  road_attributes: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  road_type: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
  lane_count: createAllOperators(),
  road_surface: createOptions([
    { value: '=', key: uuid.v4() },
    { value: '!=', key: uuid.v4() },
  ]),
}

const OPTIONS: Option[] = [
  createOption({ value: 'bitrate', group: GROUPS.GENERAL }),
  createOption({ value: 'driver', group: GROUPS.GENERAL }),
  createOption({ value: 'drive_id', group: GROUPS.GENERAL }),
  createOption({ value: 'drive_type', group: GROUPS.GENERAL }),
  createOption({ value: 'kit_id', group: GROUPS.GENERAL }),
  // createOption({ value: 'location', group: GROUPS.GENERAL }),
  createOption({ value: 'partner', group: GROUPS.GENERAL }),
  createOption({
    value: 'brake_pedal_score',
    group: GROUPS.DYNAMIC_DATA,
    subgroup: SUBGROUPS.DRIVER_PERFORMANCE,
  }),
  createOption({ value: 'gas_pedal_score', group: GROUPS.DYNAMIC_DATA }),
  createOption({
    value: 'incident',
    group: GROUPS.DYNAMIC_DATA,
  }),
  createOption({ value: 'score', group: GROUPS.DYNAMIC_DATA }),
  createOption({ value: 'steering_angle_score', group: GROUPS.DYNAMIC_DATA }),
  createOption({
    value: 'condition',
    group: GROUPS.DYNAMIC_DATA,
    subgroup: SUBGROUPS.ENVIRONMENTAL_CONDITION,
    special: true,
  }),
  createOption({
    value: 'lighting',
    group: GROUPS.DYNAMIC_DATA,
  }),
  createOption({
    value: 'acceleration',
    group: GROUPS.DYNAMIC_DATA,
    subgroup: SUBGROUPS.OBJECTS_AGENTS,
    special: true,
  }),
  createOption({ value: 'brake_pedal', group: GROUPS.DYNAMIC_DATA }),
  createOption({ value: 'gas_pedal', group: GROUPS.DYNAMIC_DATA }),
  createOption({ value: 'gear', group: GROUPS.DYNAMIC_DATA }),
  createOption({ value: 'speed', group: GROUPS.DYNAMIC_DATA }),
  createOption({
    value: 'steering_angle',
    group: GROUPS.DYNAMIC_DATA,
  }),
  createOption({ value: 'turn_signal', group: GROUPS.DYNAMIC_DATA }),
  createOption({
    value: 'max_speed',
    group: GROUPS.MAP_BASED_DATA,
    subgroup: SUBGROUPS.TRAFFIC_INFRASTRUCTURE,
  }),
  createOption({ value: 'road_attributes', group: GROUPS.MAP_BASED_DATA }),
  createOption({ value: 'traffic_facilities', group: GROUPS.MAP_BASED_DATA }),
  createOption({ value: 'traffic_junctions', group: GROUPS.MAP_BASED_DATA }),
  createOption({
    value: 'lane_count',
    group: GROUPS.MAP_BASED_DATA,
    subgroup: SUBGROUPS.ENVIRONMENTAL_CONDITION,
    special: true,
  }),
  createOption({ value: 'road_surface', group: GROUPS.MAP_BASED_DATA }),
  createOption({
    value: 'road_type',
    group: GROUPS.MAP_BASED_DATA,
  }),
]

interface SearchQueryBarProps {
  token: string
}

const SearchQueryBar = ({ token }: SearchQueryBarProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname.split('/')
  const listRef = useRef<HTMLUListElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const tagRef = useRef<HTMLDivElement>(null)
  const contextRef = useRef<HTMLInputElement>(null)
  const [value] = useState(pathname[pathname.length - 1])
  const [search, setSearch] = useState<(Option | string)[]>([])
  const [tags, setTags] = useState<Record<MergedListKey, string | boolean>[]>(
    []
  )
  const [showOperators, setShowOperators] = useState(false)
  const [showValues, setShowValues] = useState(false)
  const [disableCloseOnSelect, setDisableCloseOnSelect] = useState(true)
  const [clickedTagKey, setClickedTagKey] = useState<string | null>(null)
  const [editedTagKey, setEditedTagKey] = useState<string | null>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const [inputValueCopy, setInputValueCopy] = useState<string>('')
  const [editedTag, setEditedTag] = useState<string | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [context, setContext] = useState<string>('5s')
  const [contextEdited, setContextEdited] = useState<boolean>(false)
  const [showSaveQueryModal, setShowSaveQueryModal] = useState(false)
  const [autocompleteTab, setAutocompleteTab] = useState(AUTOCOMPLETE_TABS.NEW)
  const [savedQueries, setSavedQueries] = useState<Option[]>([])
  const [savedQueryToDelete, setSavedQueryToDelete] = useState<any>()
  const [savedQueryToEdit, setSavedQueryToEdit] = useState<any>()
  const [clickedOutsideTagData, setClickedOutsideTagData] = useState()
  const [range, setRange] = useState<[Date, Date] | null>()
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const { user } = useAuth0()

  useEffect(() => {
    setDisableCloseOnSelect(autocompleteTab !== AUTOCOMPLETE_TABS.SAVED)
  }, [autocompleteTab])

  const getQueries = async () => {
    const savedQueriesResponse = await getSavedQueries({
      userId: user?.sub,
      token,
      onAlert: setShowToast,
    })
    let savedQueriesOptions = [createOption({ value: '' })]
    if (savedQueriesResponse.data.length > 0) {
      savedQueriesOptions = savedQueriesResponse.data.map((savedQuery) => {
        const tags = queryToTags(savedQuery.conditions.query)
          .concat(queryToTags(savedQuery.conditions.filters))
          .join('&')
        return createOption({
          id: savedQuery.id,
          conditions: savedQuery.conditions,
          value: savedQuery.name,
          onCopy: () => {
            navigator.clipboard.writeText(
              `${
                window.location.origin
              }/dataset/scenarios?context=${context}&q=${encodeURIComponent(
                tags
              )}`
            )
          },
          onEdit: (value, id) => {
            setSavedQueryToEdit({ value, id })
          },
          onDelete: (value, id) => {
            setSavedQueryToDelete({ value, id })
          },
          savedQuery: true,
        })
      })
    }
    setSavedQueries(savedQueriesOptions)
  }

  useEffect(() => {
    token && user && getQueries()
  }, [token, user])

  useClickOutside(listRef, () => {
    setClickedTagKey(null)
    setEditedTagKey(null)
  })

  const updateTags = useCallback(() => {
    const updatedTags = [...tags]

    updatedTags.forEach((tag) => {
      if (tag.key === editedTagKey) {
        tag.value = editedTag || ''
      }
    })
    setTags(updatedTags)
    searchParams.set(
      'q',
      updatedTags.map((list) => encodeURIComponent(list.value)).join('&')
    )
    setSearchParams(searchParams)
    setEditedTag(null)
    setEditedTagKey(null)
  }, [tags, editedTag, editedTagKey, searchParams])

  useClickOutside(
    tagRef,
    (props) => {
      if (props.editedTagKey) {
        setClickedOutsideTagData(props)
      }
    },
    { editedTag, editedTagKey }
  )

  useEffect(() => {
    clickedOutsideTagData && updateTags()
  }, [clickedOutsideTagData])

  const onChange = useCallback(
    (event: React.SyntheticEvent, value: any, reason: string) => {
      const key = (event.target as HTMLElement)
        .closest('div')
        ?.getAttribute('data-key')

      if (reason === 'removeOption') {
        search.forEach((s, i) => {
          if ((s as Option).value === key) {
            value.splice(i, 3)
          }
        })
        setSearch([...value])
      }

      if (value[0]?.savedQuery) {
        const query = findQueryById(savedQueries, value[0].id)
        const tags = queryToTags(query.conditions.query)
          .concat(queryToTags(query.conditions.filters))
          .join('&')
        tags &&
          navigate(
            `/dataset/scenarios?context=${context}&q=${encodeURIComponent(
              tags
            )}`,
            { replace: true }
          )
      } else {
        if (showValues) {
          setShowValues(false)
        }
        setDisableCloseOnSelect((value.length + 1) % 3 !== 0)
        if ((value.length + 1) % 3 === 0) {
          setShowValues(true)
          setDisableCloseOnSelect(false)
          setShowOperators(false)
        } else if (
          value.length !== 3 &&
          value.length % 3 !== 0 &&
          !value[value.length - 1].savedQuery
        ) {
          setShowOperators(true)
        } else {
          setShowValues(false)
          setShowOperators(false)
        }
        setSearch(value)
      }
    },
    [search, showValues, savedQueries, context]
  )

  const renderTags = useCallback(
    (list: (Option | string)[], props: AutocompleteRenderGetTagProps) => {
      const displayList: string[] = list.map(
        (item) => (item as Option).name || (item as string)
      )
      const mergedList: Record<MergedListKey, string | string[] | boolean>[] =
        []

      for (let i = 0; i < displayList.length; i += 3) {
        mergedList.push({
          group: (search[0] as Option).group || '',
          subgroup: (search[0] as Option).subgroup || '',
          key: (list[i] as Option).value,
          value: [...displayList].splice(i, 3),
          savedQuery: (search[0] as Option).savedQuery,
        })
      }

      return (
        <div className={style.searchTags}>
          {mergedList.map((merged, i) => {
            return (
              <div key={`${uuid.v4()}_${i}`} className={style.searchResults}>
                {(merged.value as string[]).length % 3 === 0 ||
                merged.savedQuery ? (
                  <Chip
                    label={(merged.value as string[]).join(' ')}
                    variant="outlined"
                    {...props({ index: i })}
                    data-key={merged.key}
                    onDelete={(event) => {
                      onChange(event, list, 'removeOption')
                    }}
                  />
                ) : (
                  <div>{merged.value}</div>
                )}
              </div>
            )
          })}
        </div>
      )
    },
    [search]
  )

  const createSearchOptions = (
    tags: Record<MergedListKey, string | boolean>[],
    search: (Option | string)[]
  ) => {
    const mergedList: Record<MergedListKey, string | boolean>[] = tags
    const searchValues = []
    let value: string[] = []
    for (let i = 0; i <= search.length; i++) {
      if (search[i] && (i === 0 || i % 3 !== 0)) {
        value.push((search[i] as Option)?.name || (search[i] as string))
      } else {
        const option: Option = search[0] as Option
        mergedList.push({
          group: option?.group || '',
          subgroup: option?.subgroup || '',
          key:
            (search[option?.savedQuery ? i : i - 1] as Option)?.value ||
            uuid.v4(),
          value: value.length > 0 ? value.join(' ') : option.name,
          savedQuery: option?.savedQuery,
        })
        if (search[i]) {
          value = [(search[i] as Option).name]
        }
        searchValues.push(value.join(' '))
      }
    }
    return mergedList
  }

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' && !showOperators && !showValues) {
        const mergedList = createSearchOptions(tags, search)
        searchParams.set(
          'q',
          mergedList.map((list) => encodeURIComponent(list.value)).join('&')
        )
        setSearchParams(searchParams)
        const queryParam = searchParams.get('q')
        if (queryParam) {
          navigate(
            `/dataset/scenarios?q=${encodeURIComponent(
              queryParam
            )}&context=${context}`
          )
        }
        setTags(mergedList)
        setSearch([])
      }
    },
    [search, tags, showOperators, showValues, searchParams]
  )

  useEffect(() => {
    const params = searchParams.getAll('q')[0]
    const splitParams = params ? params.split('&') : []
    const createdTags = splitParams.map((value) => {
      const type = decodeURIComponent(value).split(' ')[0].replace(/ /g, '')
      return {
        group: GROUPS_MAPPING[type as OPERATORS_KEYS] || '',
        subgroup: SUBGROUPS_MAPPING[type as SUBGROUPS_OPERATORS_KEYS] || '',
        value: decodeURIComponent(value),
        key: `${uuid.v4()}_${decodeURIComponent(value)}`,
        savedQuery: false,
      }
    })
    setTags(createdTags)
  }, [searchParams])

  useEffect(() => {
    const params = searchParams.getAll('context')[0]
    const newContext = params || context
    setContext(newContext)
    searchParams.set('context', newContext)
    setSearchParams(searchParams)
  }, [searchParams, context])

  useEffect(() => {
    const begin = searchParams.getAll('begin')[0]
    const end = searchParams.getAll('end')[0]
    if (begin && end) {
      setRange([new Date(begin), new Date(end)])
    } else {
      setRange(null)
    }
  }, [searchParams])

  const onContextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setContext(event.target.value)
      searchParams.set('context', event.target.value)
      setSearchParams(searchParams)
      setContextEdited(true)
    },
    [searchParams]
  )

  const onContextKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Tab' || event.key === 'Enter') {
        const contextVal = context || '5s'
        const newContext =
          contextVal.indexOf('s') === -1 ? contextVal + 's' : contextVal
        setContext(newContext)
        searchParams.set('context', newContext)
        contextRef.current?.blur()
      }
    },
    [context]
  )

  const onContextFocusOut = useCallback(() => {
    if (!context) {
      setContext('5s')
    }
  }, [context])

  const onDatePickerChange = useCallback(
    (value: any) => {
      if (value?.[0] && value?.[1]) {
        searchParams.set('begin', `${endOfDay(value[0]).toISOString()}`)
        searchParams.set('end', `${endOfDay(value[1]).toISOString()}`)
      } else {
        searchParams.delete('begin')
        searchParams.delete('end')
        setRange(null)
      }
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  const onDeleteTag = useCallback(() => {
    const updatedTags = [...tags].filter((tag) => tag.key !== clickedTagKey)
    if (updatedTags.length > 0) {
      searchParams.set(
        'q',
        updatedTags.map((list) => encodeURIComponent(list.value)).join('&')
      )
      setSearchParams(searchParams)
    } else {
      navigate('/dataset/drives', { replace: true })
    }
    setTags(updatedTags)
    setClickedTagKey(null)
    setEditedTag(null)
  }, [tags, clickedTagKey, searchParams])

  const onInputChange = useCallback(
    (event: React.SyntheticEvent, value: string) => {
      setInputValue(value)
      setInputValueCopy(value)

      if (!value) {
        setShowValues(false)
      }

      if (SEARCH_VALUES.includes(value)) {
        setShowValues(false)
        setShowOperators(true)
        setSearch([...search, createOption({ value })])
        setInputValue('')
      } else if (OPERATORS[inputValueCopy as OPERATORS_KEYS]) {
        setShowValues(true)
        setSearch([...search, createOption({ value })])
        setInputValue('')
      }
    },
    [search, inputValueCopy]
  )

  return (
    <div>
      <div
        className={classNames(
          style.searchContainer,
          tags?.length === 0 ? style.searchContainerHeight : undefined
        )}
      >
        <div className={style.searchContext}>
          <Autocomplete
            id="search"
            multiple
            disableCloseOnSelect={disableCloseOnSelect}
            sx={{ width: '50%' }}
            componentsProps={{
              paper: {
                sx: {
                  maxWidth: '100%',
                },
              },
            }}
            freeSolo
            getOptionKey={(option: any) => (option as Option).value}
            onChange={onChange}
            onInputChange={onInputChange}
            inputValue={inputValue}
            className={style.autocomplete}
            clearOnBlur={false}
            renderTags={renderTags}
            value={search as any}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, autoComplete: 'off' }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <Icon
                          name={'Search'}
                          styles={{ fontSize: '1.25rem', color: '#7d8287' }}
                        />
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                placeholder={search.length === 0 ? 'Enter query' : undefined}
              />
            )}
            disabled={false}
            options={
              autocompleteTab === AUTOCOMPLETE_TABS.NEW
                ? inputValueCopy && showValues
                  ? VALUES[(search[0] as Option).name as VALUES_KEYS] || []
                  : showOperators && inputValueCopy
                  ? SEARCH_VALUES.indexOf(inputValueCopy) !== -1
                    ? OPERATORS[inputValueCopy as OPERATORS_KEYS]
                    : OPTIONS
                  : showValues && search.length > 0
                  ? VALUES[
                      (search[search.length - 2] as Option)?.name as VALUES_KEYS
                    ] || []
                  : showOperators && search.length > 0
                  ? OPERATORS[
                      (search[search.length - 1] as Option)
                        ?.name as OPERATORS_KEYS
                    ] || []
                  : OPTIONS
                : savedQueries
            }
            getOptionLabel={(option: any) => option.name}
            groupBy={(option: any) => option.group}
            renderGroup={(params) => {
              return (
                <div key={params.key}>
                  {parseInt(params.key) === 0 && (
                    <div className={style.newSavedQueries}>
                      <Typography
                        type={TypographyTypes.label}
                        className={classNames(
                          style.tab,
                          autocompleteTab === AUTOCOMPLETE_TABS.NEW
                            ? style.selectedTab
                            : undefined
                        )}
                        onClick={() =>
                          setAutocompleteTab(AUTOCOMPLETE_TABS.NEW)
                        }
                      >
                        {AUTOCOMPLETE_TABS.NEW}
                      </Typography>
                      <Typography
                        type={TypographyTypes.label}
                        className={classNames(
                          style.tab,
                          autocompleteTab === AUTOCOMPLETE_TABS.SAVED
                            ? style.selectedTab
                            : undefined
                        )}
                        onClick={() =>
                          setAutocompleteTab(AUTOCOMPLETE_TABS.SAVED)
                        }
                      >
                        {AUTOCOMPLETE_TABS.SAVED}
                      </Typography>
                    </div>
                  )}
                  <Typography
                    type={TypographyTypes.label}
                    className={style.group}
                  >
                    {params.group}
                  </Typography>
                  {params.children}
                </div>
              )
            }}
            renderOption={(props, option: any) => option.item(props)}
            forcePopupIcon={false}
            isOptionEqualToValue={() => false}
            onKeyDown={onKeyDown}
          />
          <div className={style.datePickerContainer}>
            <DateRangePicker
              character={'-'}
              size="lg"
              ranges={predefinedRanges}
              placement={'bottomEnd'}
              onChange={onDatePickerChange}
              value={range}
            />
          </div>
          <input
            className={classNames(
              style.contextInput,
              contextEdited ? style.contextInputEdited : undefined
            )}
            ref={contextRef}
            value={context}
            title={'Set context window'}
            onChange={onContextChange}
            onBlur={onContextFocusOut}
            onKeyDown={onContextKeyDown}
          />
          {tags.length > 0 && (
            <Button
              className={style.saveQuery}
              onClick={() => {
                setShowSaveQueryModal(true)
              }}
              text={'Save query'}
              secondary
            />
          )}
        </div>
        <Select
          disabled={tags.length === 0}
          className={style.subNavInputField}
          value={value}
          variant={'outlined'}
          onChange={(e) =>
            navigate(`/dataset/${e.target.value}${location.search}`)
          }
          IconComponent={(_props) => {
            const opened = _props.className.toString().includes('iconOpen')
            return (
              <Icon
                className={style.iconSelect}
                name={opened ? 'ExpandLess' : 'ExpandMore'}
              />
            )
          }}
        >
          <MenuItem value="drives">
            <p>Drives</p>
          </MenuItem>
          <MenuItem value="scenarios">
            <p>Scenarios</p>
          </MenuItem>
        </Select>
      </div>
      {tags && tags.length > 0 && (
        <div className={style.tagsContainer}>
          <div className={style.tags}>
            {tags.map((tag) => {
              return (
                <div
                  key={tag.key as string}
                  className={style.tagContainer}
                  ref={tagRef}
                >
                  <Chip
                    className={style.chip}
                    label={
                      editedTagKey === tag.key ? (
                        <>
                          <input
                            ref={inputRef}
                            className={style.editTagInput}
                            autoFocus={true}
                            value={editedTag || ''}
                            onChange={(event) =>
                              setEditedTag(event.target.value)
                            }
                            onKeyDown={(
                              event: React.KeyboardEvent<HTMLInputElement>
                            ) => {
                              if (event.key === 'Enter') {
                                updateTags()
                              }
                            }}
                          />
                          <Icon
                            className={style.icon}
                            name={'Check'}
                            onClick={() => {
                              updateTags()
                            }}
                          />
                          <Icon
                            className={style.icon}
                            name={'Close'}
                            onClick={() => {
                              setEditedTagKey(null)
                            }}
                          />
                        </>
                      ) : (
                        tag.value
                      )
                    }
                    variant="outlined"
                    key={tag.key as string}
                    deleteIcon={<Icon name={'MoreHorizontal'} />}
                    onDelete={
                      editedTagKey === tag.key
                        ? undefined
                        : () => {
                            setClickedTagKey(tag.key as string)
                          }
                    }
                    onClick={(event) => {
                      if (event.detail === 2) {
                        setEditedTagKey(tag.key as string)
                        setEditedTag(tag.value as string)
                        setClickedTagKey(null)
                      }
                    }}
                  />
                  {tag.key === clickedTagKey && (
                    <List
                      aria-label="tag actions"
                      ref={listRef}
                      className={style.editTagList}
                    >
                      <ListItem
                        onClick={() => {
                          setEditedTagKey(tag.key as string)
                          setEditedTag(tag.value as string)
                          setClickedTagKey(null)
                        }}
                      >
                        <ListItemText primary="Edit" />
                      </ListItem>
                      <ListItem onClick={onDeleteTag}>
                        <ListItemText primary="Delete" />
                      </ListItem>
                    </List>
                  )}
                </div>
              )
            })}
          </div>
          {tags.length > 0 && (
            <Button
              className={style.clearAll}
              onClick={() => {
                setTags([])
                setSearch([])
                searchParams.delete('q')
                setSearchParams(searchParams)
                navigate(`/dataset/drives?context=5s`, { replace: true })
              }}
              text={'Clear all'}
              secondary
            />
          )}
        </div>
      )}
      <SaveEditQueryDialog
        savedQueries={savedQueries}
        edit={savedQueryToEdit}
        open={showSaveQueryModal || savedQueryToEdit}
        tags={tags as any}
        onCancel={() => {
          setShowSaveQueryModal(false)
          setSavedQueryToEdit(null)
        }}
        onConfirmed={async (result, tags) => {
          const params = Object.values(
            Object.fromEntries(
              Object.entries(result).filter(([key]) => key !== 'name')
            )
          )
          const data = parseSearchParams(params as string[])
          setTags(tags)

          if (savedQueryToEdit) {
            await editSavedQuery({
              token,
              userId: user?.sub,
              onAlert: setShowToast,
              queryId: savedQueryToEdit.id,
              query: {
                name: result.name,
                conditions: {
                  ...data,
                },
              },
            })
            const queryTags = queryToTags(data.query)
              .concat(queryToTags(data.filters))
              .join('&')
            queryTags &&
              navigate(
                `/dataset/scenarios?context=${context}&q=${encodeURIComponent(
                  queryTags
                )}`,
                { replace: true }
              )
            setSavedQueryToEdit(null)
          } else {
            await saveSearchQuery({
              token,
              userId: user?.sub,
              onAlert: setShowToast,
              searchQuery: {
                name: result.name,
                conditions: {
                  range,
                  ...data,
                },
              },
            })
            setShowSaveQueryModal(false)
          }
          await getQueries()
        }}
      />
      <WarningDialog
        icon={null}
        isOpen={!!savedQueryToDelete}
        dialogContentText={
          <Typography type={TypographyTypes.title} size={TypographySizes.large}>
            Delete &quot;{savedQueryToDelete?.value}&quot;?
          </Typography>
        }
        dialogContentText2={`This action cannot be undone.`}
        dialogTitle={''}
        onSubmit={async () => {
          await deleteSavedQuery({
            token,
            queryId: savedQueryToDelete?.id,
            userId: user?.sub,
            onAlert: setShowToast,
          })
          await getQueries()
          setSavedQueryToDelete(undefined)
        }}
        onCancel={() => {
          setSavedQueryToDelete(undefined)
        }}
        buttons={{
          cancel: 'Cancel',
          submit: 'Delete',
        }}
      />
    </div>
  )
}

export default SearchQueryBar
