import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import style from './style.less'
import Button from '@yaak/components/src/Button'
import Icon from '@yaak/components/src/Icon'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'
import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import {
  addNewCollection,
  addToCollection,
  Collection,
  Dataset,
  getCollections,
  getDataset,
} from '@yaak/components/services/api/api'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import { ToastTypes } from '@yaak/components/src/Toast/Toast'
import WelcomeDialog from '../WelcomeDialog'
import { isSandboxUser } from '@yaak/nutron/src/utils/sandbox'
import { useAuth0 } from '@auth0/auth0-react'
import { getCopyLink } from '@yaak/admin/src/helpers/drives'
import Badge, { BadgeType } from '@yaak/components/src/Badge/Badge'
import CollectionsAutoComplete from './CollectionsAutocomplete'
import NewCollectionForm from './NewCollectionForm'
import ErrorDetails, { IErrorCollection } from './ErrorDetails'

interface IAddToCollectionsDialogProps {
  isOpen: boolean
  token: string
  selectedIds: string[]
  onCancel: (event?: any) => void
  onCollectionSave?: (event?: any) => void
  datasetId?: string
  isNewCollection?: boolean
  datasets?: Dataset[]
}

export const MAX_COLLECTIONS_PER_USER = 50

const AddToCollectionsDialog = ({
  isOpen,
  token,
  datasets,
  selectedIds,
  isNewCollection,
  datasetId,
  onCancel,
  onCollectionSave,
}: IAddToCollectionsDialogProps) => {
  const { user } = useAuth0()
  const isGuestUser = isSandboxUser()
  const [isNew, setIsNew] = useState(isNewCollection || false)

  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const [datasetsList, setDatasetsList] = useState<Dataset[]>([])
  const [selectedCollections, setSelectedCollections] = useState<Collection[]>(
    []
  )
  const [collections, setCollections] = useState<Collection[]>([])
  const [openRegisterDialog, setOpenRegisterDialog] = useState<boolean>(false)
  const [maxCollectionsReached, setMaxCollectionsReached] = useState(false)
  const [newFormValue, setNewFormValue] = useState({ name: '', datasetId })
  const [errorItems, setErrorItems] = useState<IErrorCollection[]>([])
  const [dontShowDuplicateInfo, setDontShowDuplicateInfo] = useState<boolean>(
    JSON.parse(localStorage.getItem('duplicateBanner') || 'false')
  )

  useEffect(() => {
    const getDatasetInfo = async (token?: string) => {
      if (datasetId) {
        const dataset = await getDataset({
          token,
          id: datasetId,
          onAlert: setShowToast,
        })
        if (dataset) setDatasetsList([dataset])
      }
    }

    isGuestUser ? getDatasetInfo() : token && getDatasetInfo(token)
  }, [token, datasetId])

  useEffect(() => {
    if (datasets) setDatasetsList(datasets)
  }, [datasets])

  const onClose = () => {
    !isNewCollection && setIsNew(false)
    setErrorItems([])
    onCancel()
  }

  const getCollectionsList = async (token?: string) => {
    if (datasetId) {
      const collectionsData = await getCollections({
        token,
        datasetIds: [datasetId],
        onAlert: setShowToast,
      })
      collectionsData.data.sort((x, y) => x.name.localeCompare(y.name))
      const userCollectionsCount = collectionsData.data.filter(
        ({ userId }) => user?.sub === userId
      ).length
      setMaxCollectionsReached(userCollectionsCount >= MAX_COLLECTIONS_PER_USER)
      setCollections(collectionsData.data)
    }
  }
  useEffect(() => {
    isGuestUser ? getCollectionsList() : token && getCollectionsList(token)
  }, [token, datasetId])

  const onSubmit = async () => {
    if (isSandboxUser()) {
      setOpenRegisterDialog(true)
      return
    }
    const items = selectedIds.map((id) => {
      const [sessionId, startOffset, endOffset, type] = id.split('&')
      return {
        sessionId,
        startOffset: parseInt(startOffset),
        endOffset: parseInt(endOffset),
        type,
      }
    })
    if (isNew) {
      const data = {
        datasetId: newFormValue.datasetId,
        name: newFormValue.name,
        items,
      }
      const res = await addNewCollection({ token, data, onAlert: setShowToast })
      if (res) {
        setShowToast({
          text: 'Collection successfully created.',
          type: ToastTypes.success,
        })
      }
      onClose()
      onCollectionSave?.()
    } else {
      const res = await Promise.all(
        selectedCollections.map((collection) =>
          addToCollection({
            collectionId: collection?.id || '',
            token,
            data: { items },
            onAlert: setShowToast,
          })
        )
      )
      const duplicates: IErrorCollection[] = res.reduce((acc, item, i) => {
        if (item.duplicateItems?.length > 0) {
          const { name } = selectedCollections[i]
          const duplicateCollection: IErrorCollection = {
            name,
            sessions: [],
            scenarios: [],
          }
          item.duplicateItems.forEach((duplicateItem: any) => {
            const url = getCopyLink(duplicateItem, '5s', datasetId)
            if (duplicateItem.type === 'session') {
              duplicateCollection.sessions.push(url)
            } else {
              duplicateCollection.scenarios.push(url)
            }
          })
          return [...acc, duplicateCollection]
        }
        return acc
      }, [])
      if (duplicates.length > 0 && !dontShowDuplicateInfo) {
        setErrorItems(duplicates)
      } else if (res) {
        setShowToast({
          text: 'Data successfully added to collection',
          type: ToastTypes.success,
        })
        onClose()
      }
      onCollectionSave?.()
    }
  }

  const onOk = () => {
    onClose()
    setShowToast({
      text: 'Data successfully added to collection',
      type: ToastTypes.success,
    })
  }

  const onShowDuplicateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDontShowDuplicateInfo(e.target.checked)
    localStorage.setItem('duplicateBanner', `${e.target.checked}`)
  }

  const hasErrors = isNew
    ? !newFormValue.name || !newFormValue.datasetId
    : selectedCollections.length === 0

  const onFormChange = (name: string, value: string) =>
    setNewFormValue((v) => ({ ...v, [name]: value }))

  return (
    <>
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={onClose}
        open={isOpen}
        sx={{ '& .MuiDialog-paper': { maxWidth: 480, width: '100%' } }}
      >
        <div>
          <DialogTitle className={style.formTitle}>
            {errorItems.length > 0 && (
              <div className={style.warningIcon}>
                <Badge
                  type={BadgeType.orange}
                  icon={
                    <Icon
                      name="Warning"
                      version={Version.v2}
                      color="new-color-orange-037"
                    />
                  }
                  label=""
                />
              </div>
            )}
            <Typography
              type={TypographyTypes.title}
              size={TypographySizes.large}
            >
              {errorItems.length > 0
                ? 'Duplicate items detected'
                : isNew
                ? 'New collection'
                : 'Add to collection'}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <div className={style.description}>
              <Typography type={TypographyTypes.body}>
                {errorItems.length > 0
                  ? 'We couldn’t add some items because they already exist in the collection(s).'
                  : isNew
                  ? 'Choose a memorable name and a parent dataset.'
                  : 'Sessions and scenarios can be added to collections.'}
              </Typography>
            </div>
            {errorItems.length > 0 ? (
              <ErrorDetails errorItems={errorItems} />
            ) : isNew ? (
              <NewCollectionForm
                newFormValue={newFormValue}
                datasetsList={datasetsList}
                datasetId={datasetId}
                onFormChange={onFormChange}
              />
            ) : (
              <CollectionsAutoComplete
                collections={collections}
                selectedCollections={selectedCollections}
                maxCollectionsReached={maxCollectionsReached}
                setSelectedCollections={setSelectedCollections}
                setIsNew={setIsNew}
              />
            )}
          </DialogContent>
          <DialogActions
            sx={{
              ...(errorItems.length > 0
                ? { justifyContent: 'space-between', padding: '8px 20px' }
                : {}),
            }}
          >
            {errorItems.length > 0 ? (
              <>
                <div className={style.checkbox}>
                  <input
                    type="checkbox"
                    checked={dontShowDuplicateInfo}
                    onChange={onShowDuplicateChange}
                  />
                  <Typography
                    type={TypographyTypes.body}
                    size={TypographySizes.small}
                    color="color-neutral-040"
                    version={Version.v2}
                  >
                    Don’t show again
                  </Typography>
                </div>
                <Button onClick={onOk} text="Ok" />
              </>
            ) : (
              <>
                <Button onClick={onClose} text="Cancel" tertiary />
                <Button onClick={onSubmit} text="Save" disabled={hasErrors} />
              </>
            )}
          </DialogActions>
        </div>
      </Dialog>
      <WelcomeDialog
        header="Join Nutron for free"
        open={openRegisterDialog}
        onClose={() => setOpenRegisterDialog(false)}
      />
    </>
  )
}

export default AddToCollectionsDialog
