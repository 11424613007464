// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__empty--a__Jj {\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  margin: 0 1rem;\n  height: 100%;\n}\n.style__body--LnRMm {\n  text-align: center;\n}\n.style__emptyButton--i1mFD {\n  margin-top: 1rem;\n  background: #2e8456;\n}\n", "",{"version":3,"sources":["webpack://./../components/src/Empty/style.less"],"names":[],"mappings":"AAGA;EAEI,mBAAA;EACA,uBAAA;EACA,OAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;EACA,YAAA;AAHJ;AALA;EAYI,kBAAA;AAJJ;AARA;EAgBI,gBAAA;EACA,mBAAA;AALJ","sourcesContent":["@import '@yaak/components/src/spacing';\n@import '@yaak/components/src/colors';\n\n:local {\n  .empty {\n    align-items: center;\n    justify-content: center;\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    margin: 0 @spacing-100;\n    height: 100%;\n  }\n\n  .body {\n    text-align: center;\n  }\n\n  .emptyButton {\n    margin-top: @spacing-100;\n    background: @color-green-035;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty": "style__empty--a__Jj",
	"body": "style__body--LnRMm",
	"emptyButton": "style__emptyButton--i1mFD"
};
export default ___CSS_LOADER_EXPORT___;
