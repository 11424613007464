import {
  Dataset,
  Scenario,
  SearchSessionData,
  SessionData,
} from '@yaak/components/services/api/api'
import { Tag } from '../stores/Tags'
import { MULTI_OPERATOR, RANGE_OPERATOR } from '../SearchQueryBar/utils'
import { FILTER_CATEGORY_MAPPING } from '../FilterMenu/consts'

const OPERATORS_MAPPING: Record<string, string> = {
  '=': '$eq',
  '!=': '$ne',
  '>': '$gt',
  '>=': '$gte',
  '<': '$lt',
  '<=': '$lte',
}

const formatScore = (value: string) => {
  const score = parseFloat(value)
  return score > 1 ? score / 100 : score
}

export const parseSearchParams = (
  tags: Tag[] | null,
  dataset: Dataset | null
): any => {
  return tags
    ? tags.reduce(
        (acc, tag) => {
          const [queryName, operator, ...rest] = (tag as Tag).tagUrl.split(' ')
          const value = rest.join(' ')
          if (queryName && queryName !== 'date' && queryName !== 'Seq:') {
            const isMulti = (tag as Tag).values
            const isScore = queryName.toLowerCase().includes('score')

            acc[(tag as Tag).filterType === 'filter' ? 'filters' : 'query'][
              queryName
            ] =
              operator === RANGE_OPERATOR
                ? {
                    [OPERATORS_MAPPING['>=']]: parseFloat(value.split('-')[0]),
                    [OPERATORS_MAPPING['<=']]: parseFloat(value.split('-')[1]),
                  }
                : {
                    [OPERATORS_MAPPING[operator] || operator]:
                      MULTI_OPERATOR.includes(operator)
                        ? value
                            .trim()
                            .split(',')
                            .map((val) =>
                              ['int', 'uint', 'float'].some((v) =>
                                tag.type.startsWith(v)
                              )
                                ? parseFloat(val)
                                : val.trim()
                            )
                        : tag.type === 'bool'
                        ? value === 'true'
                        : isScore
                        ? formatScore(value)
                        : isMulti
                        ? value.split(',')
                        : ['float64', 'float32'].includes(tag.type)
                        ? parseFloat(value)
                        : ['int', 'uint'].some((v) => tag.type?.startsWith(v))
                        ? parseInt(value)
                        : value.trim(),
                  }
          } else if (queryName === 'Seq:') {
            const sequence: any[] = []
            const tagUrl = (tag as Tag).tagUrl
              .replace('Seq: ', '')
              .replace(/[[\]]/g, '')
            const splitValue = tagUrl.split(' -> ')
            const sequenceTypes = []
            splitValue.forEach((val) => {
              const [param, value] = val.split(': ')
              const filter = dataset?.searchFilters.filter(
                (f) => f.name === param
              )[0]
              sequenceTypes.push(filter)
              sequence.push({
                [param]: {
                  $eq:
                    filter && ['float64', 'float32'].includes(filter.type)
                      ? parseFloat(value)
                      : ['int', 'uint'].some(
                          (v) => filter && filter.type.startsWith(v)
                        )
                      ? parseInt(value)
                      : value.trim(),
                },
              } as any)
            })
            acc.sequenceQuery = {
              duration: tag.duration,
              sequence,
            }
          }
          return acc
        },
        {
          query: {},
          filters: {},
        } as any
      )
    : {
        query: {},
        filters: {},
      }
}

export const areParamsValid = (params: string) =>
  // Special case fix for 'Signs & lights'
  params
    .replace(' & ', ' # ')
    ?.split(/[&=><!]+/)
    .join('$')
    .split(RANGE_OPERATOR)
    .join('$')
    .split(MULTI_OPERATOR[0])
    .join('$')
    .split(MULTI_OPERATOR[1])
    .join('$')
    .split('$')
    .filter((text) => !['', ' ', '-'].includes(text)).length %
    2 ===
  0

export const getSelectionId = (
  session: SessionData | Scenario | SearchSessionData,
  storeBothIds: boolean = false
) => {
  const sessionScenario = session as Scenario
  const id = storeBothIds
    ? sessionScenario.sessionId
    : (session as SessionData).id || sessionScenario.sessionId
  return `${id}&${sessionScenario.startOffset || 0}&${
    sessionScenario.endOffset || Math.floor(sessionScenario.durationMs / 1000)
  }&${sessionScenario.endOffset ? 'scenario' : 'session'}${
    storeBothIds ? `&${(session as SessionData).id}` : ''
  }`
}

export const areFiltersOnlySessionInfo = (tags: Tag[] | null) =>
  tags
    ? tags.every(
        (tag) =>
          FILTER_CATEGORY_MAPPING[tag.category]?.displayName ===
          FILTER_CATEGORY_MAPPING.sessionInformation.displayName
      )
    : false
