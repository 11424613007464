import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import style from './style.less'
import Icon from '@yaak/components/src/Icon'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'
import React, { Dispatch, SetStateAction } from 'react'
import classNames from 'classnames'
import { Collection } from '@yaak/components/services/api/api'
import { isSandboxUser } from '@yaak/nutron/src/utils/sandbox'
import { useAuth0 } from '@auth0/auth0-react'
import Badge, { BadgeType } from '@yaak/components/src/Badge/Badge'
import { MAX_COLLECTIONS_PER_USER } from './AddToCollectionsDialog'

interface ICollectionsAutoComplete {
  collections: Collection[]
  selectedCollections: Collection[]
  maxCollectionsReached: boolean
  setSelectedCollections: Dispatch<SetStateAction<Collection[]>>
  setIsNew: Dispatch<SetStateAction<boolean>>
}

const CollectionsAutoComplete = (props: ICollectionsAutoComplete) => {
  const {
    collections,
    selectedCollections,
    maxCollectionsReached,
    setSelectedCollections,
    setIsNew,
  } = props
  const isGuestUser = isSandboxUser()
  const { user } = useAuth0()

  return (
    <Autocomplete
      sx={{ width: '100%' }}
      options={[{ id: 'new', name: 'New collection' }, ...collections]}
      className={style.autocomplete}
      disableCloseOnSelect
      value={selectedCollections}
      multiple
      onChange={(e, value) => setSelectedCollections(value as Collection[])}
      getOptionDisabled={(option) =>
        (!isGuestUser &&
          option.id !== 'new' &&
          (option as Collection).userId !== user?.sub) ||
        (option as Collection).sessionCount +
          (option as Collection).scenarioCount >=
          (option as Collection).capacity
      }
      getOptionLabel={(option) => option.name}
      filterOptions={([newCollection, ...option], { inputValue }) => {
        const filtered = option.filter(({ name }) =>
          name.toLowerCase().includes(inputValue.toLowerCase())
        )
        return [newCollection, ...filtered]
      }}
      renderTags={(value) => (
        <Typography
          type={TypographyTypes.body}
          className={style.selectedCollections}
          version={Version.v2}
        >
          {value.map((v) => v.name).join(', ')}
        </Typography>
      )}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props
        if (option.id === 'new' && maxCollectionsReached) {
          return (
            <li
              key={key}
              {...optionProps}
              className={classNames(optionProps.className, style.option)}
            >
              <Badge
                type={BadgeType.orange}
                icon={
                  <Icon
                    name="Error"
                    version={Version.v2}
                    color="new-color-orange-037"
                  />
                }
                label={`You have created the maximum amount of collections for your account (${MAX_COLLECTIONS_PER_USER}).`}
              />
            </li>
          )
        }
        return (
          <li
            key={key}
            {...optionProps}
            className={classNames(
              optionProps.className,
              style.option,
              option.id === 'new' ? style.optionFirst : undefined
            )}
            onClick={
              option.id === 'new' ? () => setIsNew(true) : optionProps?.onClick
            }
          >
            {option.id === 'new' ? (
              <Icon name="Add" version={Version.v2} />
            ) : (
              <input type="checkbox" checked={selected} />
            )}
            <div className={style.content}>
              <Typography type={TypographyTypes.label} version={Version.v2}>
                {option.name}
              </Typography>
              {option.id !== 'new' && (
                <Typography
                  type={TypographyTypes.body}
                  size={TypographySizes.small}
                  version={Version.v2}
                >
                  {(option as Collection).sessionCount +
                    (option as Collection).scenarioCount}{' '}
                  / {(option as Collection).capacity}
                </Typography>
              )}
            </div>
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search collections"
          helperText="Collections can contain a maximum of 1,000 items."
          onKeyDown={(event: any) => {
            if (event.key === 'Backspace' || event.key === 'Delete') {
              event.stopPropagation()
            }
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <Icon
                    name={'Collection'}
                    color="color-neutral-040"
                    version={Version.v2}
                  />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default CollectionsAutoComplete
