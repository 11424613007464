import React, { useCallback, useEffect, useRef, useState } from 'react'
import style from './style.less'
import classNames from 'classnames'
import GridHeaderElement from './GridHeader'
import { isActiveRow, onMouseLeave, onMouseOver, onScroll } from './helpers'
import GridCellElement from './GridCellElement'
import { Version } from '../types'
import GridSelection, { IGridSelection } from './GridSelection'
import { useParams, useSearchParams } from 'react-router-dom'
import { Scenario } from '../../services/api/api'

interface GridProps {
  data: {
    rows: any[]
    options?: any
  }
  fetchData?: () => void
  headerSort: string[]
  onHeaderClicked?: ({
    index,
    sortOrder,
  }: {
    index: number
    sortOrder: any
  }) => void
  onRowClick?: (rowIndex: number) => void
  headers: string[]
  version?: Version
  rowSelection?: IGridSelection
  enableLink?: any
}

interface GridPopoverElementProps {
  idx: number
  column: any
  data: any
}

const usePrevious = (value: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const gridWithHeadersOnly = (dataRows: any) =>
  dataRows.map((col: any) => col.length === 1).indexOf(false) === -1

const GridPopoverElement = ({ idx, column, data }: GridPopoverElementProps) => {
  const isActive = isActiveRow(data, idx)
  return (
    <div
      className={classNames(
        style.gridItem,
        style.gridItemCenter,
        'gridItemSelector',
        `gridItem${idx}`,
        !isActive ? style.inactive : undefined
      )}
      onMouseOver={() => onMouseOver(idx)}
      onMouseLeave={() => onMouseLeave(idx)}
    >
      {column.popover}
      {column.text || column}
    </div>
  )
}

const Grid = ({
  data,
  headerSort,
  fetchData,
  onHeaderClicked,
  onRowClick,
  headers,
  version,
  rowSelection,
  enableLink,
}: GridProps) => {
  const [searchParams] = useSearchParams()
  const begin = searchParams.get('begin')
  const end = searchParams.get('end')
  const query = searchParams.get('q')
  const { datasetId } = useParams()
  const context = searchParams.get('context')
  const [dataRows, setDataRows] = useState<any[]>([])
  const [sortOrder, setSortOrder] = useState<(boolean | null)[]>([])
  const [loadingMore, setLoadingMore] = useState(false)
  const [top, setTop] = useState<number>()
  const gridEl = useRef(null)
  const prev = usePrevious(top)

  const fixedColumns = [...data.rows.keys()]
    .filter((i) => i >= data.rows.length - data.options?.fixedColumns)
    .reverse()

  useEffect(() => {
    const rows = document.querySelectorAll('.gridItemSelector')
    const lastRow = rows[rows.length - 1]
    const top = lastRow?.getBoundingClientRect().top
    setTop(top)
  }, [gridEl.current, dataRows[0]?.length])

  useEffect(() => {
    setDataRows(data.rows)
  }, [data.rows])

  useEffect(() => {
    const grid = document.querySelector('#gridContainer')
    const searchQuery = (
      document.querySelector('#searchInput') as HTMLInputElement
    )?.value
    const CONTAINER_HEIGHT =
      (grid?.getBoundingClientRect()?.height || 0) +
      (grid?.getBoundingClientRect()?.top || 0)
    const loadFullScreen = async () => {
      setLoadingMore(true)
      await fetchData?.()
      setLoadingMore(false)
    }

    if (top && top <= CONTAINER_HEIGHT && !searchQuery && !loadingMore) {
      if (prev && top !== prev) {
        loadFullScreen()
      } else if (!prev) {
        loadFullScreen()
      }
    }
  }, [prev, top, data, loadingMore, fetchData])

  useEffect(() => {
    const newSortOrder: (boolean | null)[] = []
    headers.map((header) =>
      headerSort.indexOf(header) !== -1
        ? newSortOrder.push(true)
        : newSortOrder.push(null)
    )
    setSortOrder(newSortOrder)
  }, [])

  useEffect(() => {
    const onScrollFun = () => onScroll(fetchData, loadingMore, setLoadingMore)
    window.addEventListener('scroll', onScrollFun, true)
    return () => window.removeEventListener('scroll', onScrollFun)
  }, [data.rows?.length, fetchData, loadingMore])

  const headerClick = useCallback(
    (i: number) => {
      const newSortOrder = sortOrder.map((sortOrderValue, index) =>
        index === i && sortOrderValue !== null
          ? !sortOrderValue
          : sortOrderValue !== null
          ? true
          : sortOrderValue
      )
      setSortOrder(newSortOrder)
      onHeaderClicked?.({
        index: i,
        sortOrder: newSortOrder,
      })
    },
    [sortOrder]
  )

  return (
    <div
      className={classNames(
        style.gridContainer,
        gridWithHeadersOnly(dataRows) && style.empty
      )}
      id={'gridContainer'}
    >
      <div ref={gridEl} className={classNames(style.grid)}>
        {rowSelection && <GridSelection {...rowSelection} />}
        {dataRows.map((columns, i) => (
          <div
            className={classNames(
              style.gridCol,
              fixedColumns?.indexOf(i) !== -1
                ? style.gridColFixedRight
                : undefined,
              style[`gridCOLUMNFixed${fixedColumns?.indexOf(i)}`],
              style[`gridCOLUMNSmall${data.options?.smallColumns?.indexOf(i)}`],
              i === dataRows.length - fixedColumns?.length
                ? style.gridCOLUMNShadow
                : undefined,
              data.options?.classes?.[`gridColFixedRight${i}`],
              data.options?.classes?.[`gridItem${i}`]
            )}
            key={i}
          >
            {columns.map((column: any, idx: number) => {
              const scenarios =
                enableLink?.[idx - 1]?.scenarios && query
                  ? encodeURIComponent(
                      JSON.stringify(enableLink[idx - 1]?.scenarios)
                    )
                  : ''
              return (
                <div
                  className={style.inline}
                  key={idx}
                  onClick={idx === 0 ? () => headerClick(i) : undefined}
                >
                  {idx === 0 ? (
                    <GridHeaderElement
                      key={i}
                      sortOrder={sortOrder[i]}
                      sortEnabled={
                        column &&
                        headerSort.indexOf(column.text || column) !== -1
                      }
                      column={column}
                      icon={columns[1]?.icon}
                      version={version}
                      align={columns[1]?.align}
                    />
                  ) : column?.popover ? (
                    <GridPopoverElement idx={idx} column={column} data={data} />
                  ) : enableLink ? (
                    <a
                      href={`/dataset/session-logs/${
                        enableLink[idx - 1]?.sessionId ||
                        enableLink[idx - 1]?.id
                      }?&context=${parseInt(context || '0')}${
                        enableLink[idx - 1]?.type !== 'session' &&
                        (begin ||
                          (enableLink[idx - 1] as Scenario)?.startOffset)
                          ? `&begin=${
                              begin ||
                              (enableLink[idx - 1] as Scenario)?.startOffset
                            }&end=${
                              end ||
                              (enableLink[idx - 1] as Scenario)?.endOffset
                            }`
                          : ''
                      }${datasetId ? `&datasetId=${datasetId}` : ''}${
                        scenarios ? `&scenarios=${scenarios}` : ''
                      }`}
                      className={style.gridItemLinkContainer}
                    >
                      <GridCellElement
                        i={i}
                        idx={idx}
                        column={column}
                        data={data}
                      />
                    </a>
                  ) : (
                    <GridCellElement
                      i={i}
                      idx={idx}
                      column={column}
                      data={data}
                      onRowClick={onRowClick}
                    />
                  )}
                </div>
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Grid
