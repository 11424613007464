import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react'
import style from './style.less'
import { getTagValue, useTagsStore } from '../stores/Tags'
import { useShallow } from 'zustand/react/shallow'
import { useTag } from '../../customHooks/useTag'
import { OPERATORS_URL_MAPPING, RANGE_OPERATOR } from '../SearchQueryBar/utils'
import Typography, { TypographySizes, TypographyTypes } from '../Typography'
import { Version } from '../types'
import { RANGE_NUM_REGEX } from './consts'

const formatPlaceholder = (type: string) => {
  return type === 'string'
    ? 'Enter text'
    : type.includes('int')
    ? `Enter integer`
    : 'Enter number'
}

export interface FilterMenuInputTagProps {
  tagKey: string
}

const FilterMenuInputTag = ({ tagKey }: FilterMenuInputTagProps) => {
  const { tags, update } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
      update: state.update,
    }))
  )
  const tag = useTag(tagKey, tags)
  const [inputValue, setInputValue] = useState('')
  const [newTagUrl, setNewTagUrl] = useState('')
  const [newTagValue, setNewTagValue] = useState('')
  const [minInputValue, setMinInputValue] = useState('')
  const [maxInputValue, setMaxInputValue] = useState('')

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (tag) {
        const inputValue = e.target.value.replace(/^\s+/g, '')

        setNewTagUrl(`${tag.name} ${tag.tagOperator} ${inputValue}`)
        setNewTagValue(
          `${tag.displayName} ${
            OPERATORS_URL_MAPPING[tag.tagOperator]
          } ${inputValue}`
        )
        setInputValue(inputValue)
      }
    },
    [tag]
  )

  const onMinChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMinInputValue(e.target.value)
  }, [])

  const onMaxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMaxInputValue(e.target.value)
  }, [])

  useEffect(() => {
    if (tag?.tagOperator === RANGE_OPERATOR) {
      const inputValue = `${minInputValue}-${maxInputValue}`
      if (tag && inputValue.match(RANGE_NUM_REGEX)) {
        const value = getTagValue(tag)

        setNewTagUrl(`${tag.tagUrl.replace(value, '')} ${inputValue}`)
        setNewTagValue(`${tag.tagValue.replace(value, '')} ${inputValue}`)
      }
    }
  }, [minInputValue, maxInputValue, tag])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        tags &&
        newTagUrl &&
        newTagValue &&
        tag &&
        tag.tagValue !== newTagValue
      ) {
        tag.tagValue = newTagValue
        tag.tagUrl = newTagUrl
        update(tags.filter((t) => (t.name === tagKey ? tag : t)))
      }
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [newTagUrl, newTagValue, tags, tagKey, tag])

  useEffect(() => {
    if (tag) {
      const value = getTagValue(tag)
      const rangeValue = value.split('-')
      if (rangeValue.length > 1) {
        setMinInputValue(rangeValue[0])
        setMaxInputValue(rangeValue[1])
      } else if (value) {
        setInputValue(value)
      }
    }
  }, [tag])

  return tag && tag.tagOperator === RANGE_OPERATOR ? (
    <div className={style.rangeContainer}>
      <div className={style.rangeInputContainer}>
        <Typography
          type={TypographyTypes.label}
          size={TypographySizes.small}
          version={Version.v2}
          color="color-neutral-040"
        >
          MIN. VALUE
        </Typography>
        <div className={style.filterMenuInputContainer}>
          <input
            className={style.filterMenuInput}
            value={minInputValue}
            placeholder={formatPlaceholder(tag.type)}
            onChange={onMinChange}
          />
        </div>
      </div>
      <div className={style.rangeInputContainer}>
        <Typography
          type={TypographyTypes.label}
          size={TypographySizes.small}
          version={Version.v2}
          color="color-neutral-040"
        >
          MAX. VALUE
        </Typography>
        <div className={style.filterMenuInputContainer}>
          <input
            className={style.filterMenuInput}
            value={maxInputValue}
            placeholder={formatPlaceholder(tag.type)}
            onChange={onMaxChange}
          />
        </div>
      </div>
    </div>
  ) : (
    tag && (
      <div className={style.filterMenuInputContainer}>
        <input
          className={style.filterMenuInput}
          value={inputValue}
          placeholder={formatPlaceholder(tag.type)}
          onChange={onChange}
        />
      </div>
    )
  )
}

export default memo(FilterMenuInputTag)
