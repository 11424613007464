import React, { memo, useCallback, useEffect, useState } from 'react'
import style from './style.less'
import Typography, {
  TypographySizes,
  TypographyStyles,
  TypographyTypes,
} from '../Typography'
import Icon from '../Icon/Icon'
import { Version } from '../types'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useTagsStore } from '../stores/Tags'
import { useShallow } from 'zustand/react/shallow'
import { useTag } from '../../customHooks/useTag'
import {
  OPERATORS_MAPPING,
  OPERATORS_MAPPING_SYMBOL,
  OPERATORS_SYMBOL_MAPPING,
  OPERATORS_URL_MAPPING,
} from '../SearchQueryBar/utils'

export interface OperatorSelectProps {
  tagKey: string
  setOperatorsRef: any
}

const OperatorSelect = ({ tagKey, setOperatorsRef }: OperatorSelectProps) => {
  const { tags, update } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
      update: state.update,
    }))
  )
  const tag = useTag(tagKey, tags)
  const [operatorValue, setOperatorValue] = useState<string>()

  useEffect(() => {
    if (tag) {
      setOperatorValue(OPERATORS_URL_MAPPING[tag.tagOperator])
    }
  }, [tag])

  const onChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      if (tag && tags) {
        const operator = event.target.value

        if (operator) {
          setOperatorValue(operator)

          const mappedOperator = OPERATORS_SYMBOL_MAPPING[operator]

          update(
            tags.map((t) =>
              t.name === tagKey
                ? {
                    ...tag,
                    tagUrl: tag.tagUrl.replace(tag.tagOperator, mappedOperator),
                    tagValue: `${tag.displayName}${tag.tagValue
                      .replace(tag.displayName, '')
                      .replace(
                        OPERATORS_URL_MAPPING[tag.tagOperator],
                        operator
                      )}`,
                    tagOperator: mappedOperator,
                  }
                : t
            )
          )
        }
      }
    },
    [tag, tags, tagKey]
  )

  return (
    tag &&
    operatorValue && (
      <Select
        sx={{
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        }}
        variant="standard"
        value={operatorValue}
        className={style.filterMenuOperatorsSelect}
        MenuProps={{
          ref: setOperatorsRef,
        }}
        disabled={tag?.tagValue?.split(' ')[0].toLowerCase() === 'date'}
        IconComponent={(_props) => {
          const opened = _props.className.toString().includes('iconOpen')
          return (
            <Icon
              className={style.iconSelect}
              version={Version.v2}
              name={opened ? 'ArrowDropUp' : 'ArrowDropDown'}
            />
          )
        }}
        onChange={onChange}
        renderValue={(selected) => {
          return (
            <Typography
              type={TypographyTypes.body}
              size={TypographySizes.small}
              styles={TypographyStyles.emphasis}
              version={Version.v2}
            >
              {selected}
            </Typography>
          )
        }}
      >
        {operatorValue &&
          tag.supportedOperators?.map((operator, index) => (
            <MenuItem
              key={index}
              value={OPERATORS_MAPPING[operator]}
              className={style.operatorMenuItem}
            >
              <Typography
                type={TypographyTypes.body}
                size={TypographySizes.small}
                version={Version.v2}
              >
                {OPERATORS_MAPPING[operator]}
              </Typography>
              <Typography
                type={TypographyTypes.body}
                size={TypographySizes.small}
                version={Version.v2}
              >
                {OPERATORS_MAPPING_SYMBOL[operator]}
              </Typography>
            </MenuItem>
          ))}
      </Select>
    )
  )
}

export default memo(OperatorSelect)
