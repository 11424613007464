import { User } from '@yaak/components/services/api/api'
import { User as Auth0User } from '@auth0/auth0-react'
import React, { Dispatch, SetStateAction } from 'react'
import AvatarNamePlate from '@yaak/components/src/AvatarNamePlate/AvatarNamePlate'
import config from '@yaak/components/services/api/config'
import { transpose } from './common'
import { toastType } from '@yaak/components/src/Toast/Toast'
import {
  GridActiveElement,
  GridCopyIconElement,
  GridDeleteIconElement,
  GridEditIconElement,
  GridPartnerElement,
  GridStartElement,
  GridSuccessCancelIconElement,
  GridTextElement,
  GridUserElement,
} from '@yaak/components/src/Grid/GridElements'
import { NavigateFunction } from 'react-router-dom'

export const DS_ADMINS_IN_PARTNERS_HEADERS = [
  'Name',
  'E-Mail',
  'Phone number',
  'Active',
  'Simulation',
  '',
  '',
  '',
]

export const INSTRUCTORS_IN_PARTNERS_HEADERS = [
  '',
  'Name',
  'E-Mail',
  'Phone number',
  'Active',
  'Simulation',
  '',
  '',
  '',
]

export const STUDENTS_IN_PARTNERS_HEADERS = [
  'Name',
  'E-Mail',
  'Phone number',
  'Active',
  'Simulation',
  'ToS',
  '',
  '',
  '',
]

export const STUDENTS_MAIN_HEADERS = [
  'Name',
  'Partner',
  'E-Mail',
  'Phone number',
  'Status',
  'Sim',
  'ToS',
  '',
  '',
  '',
]

export const INSTRUCTORS_MAIN_HEADERS = [
  '',
  'Name',
  'Partner',
  'E-Mail',
  'Phone number',
  'Active',
  '',
  '',
  '',
]

export const DS_ADMINS_MAIN_HEADERS = [
  'Name',
  'Partner',
  'E-Mail',
  'Phone number',
  'Active',
  'ToS',
  '',
  '',
  '',
]

export const TEAM_MAIN_HEADERS = [
  'Name',
  'E-Mail',
  'Phone number',
  'Active',
  '',
  '',
  '',
]

export interface UserOverviewOptions {
  withHeader?: boolean
  withPartner?: boolean
  searchPlaceholder?: string
  yaakAdmin?: boolean
  withInstructorAdmin?: boolean
  withToS?: boolean
  fixedColumns: number
  smallColumns?: number[]
}

interface ActionsType {
  onEditUser: (id: string) => void
  deleteUserAction: (user: User) => void
  navigate: NavigateFunction
}

const getRows = (
  users: User[],
  setShowToast: Dispatch<SetStateAction<toastType | null>>,
  actions: ActionsType,
  matches?: boolean,
  options?: UserOverviewOptions
) =>
  users.map((user) => {
    const { onEditUser, deleteUserAction, navigate } = actions
    const row = []
    options?.withInstructorAdmin &&
      row.push(
        GridStartElement({
          user,
        })
      )
    row.push(
      GridUserElement({
        ellipsis: matches,
        user,
        navigate,
      })
    )
    options?.withPartner &&
      row.push(
        GridPartnerElement({
          ellipsis: matches,
          partner: user.partner,
          navigate,
        })
      )
    row.push(
      GridTextElement({
        ellipsis: matches,
        text: user.email,
      })
    )
    row.push(
      GridTextElement({
        ellipsis: matches,
        text: user.phoneNumber || '-',
      })
    )
    row.push(
      GridActiveElement({
        condition: user.active,
      })
    )
    user.roles?.indexOf('ds-student') !== -1 &&
      row.push(
        GridSuccessCancelIconElement({
          condition: user.simEnabled,
          positive: 'Sim-enabled',
          negative: 'Not sim-enabled',
        })
      )
    options?.withToS &&
      row.push(
        GridSuccessCancelIconElement({
          condition: user.tosAccepted,
          positive: 'Accepted',
          negative: 'Not accepted',
        })
      )
    row.push(
      GridCopyIconElement({
        data: user,
        name: 'user',
        setShowToast,
        text: 'User ID copied to clipboard',
      })
    )
    row.push(
      GridEditIconElement({
        data: user.id,
        editAction: onEditUser,
        name: 'user',
      })
    )
    row.push(
      GridDeleteIconElement({
        data: user,
        tooltip: 'Delete user',
        deleteAction: deleteUserAction,
      })
    )
    return row
  })

export const getMappedUsers = (
  data: {
    users: User[]
    setShowToast: Dispatch<SetStateAction<toastType | null>>
    actions: ActionsType
    matches?: boolean
  },
  options: UserOverviewOptions = {
    withHeader: true,
    withPartner: true,
    withToS: true,
    fixedColumns: 0,
  },
  headers: string[]
) => {
  const { users, setShowToast, actions, matches } = data
  const columns = transpose(
    getRows(users, setShowToast, actions, matches, options)
  )

  columns.map((row: any, i: number) =>
    headers.map((header, k) => i === k && row.unshift(header))
  )

  return {
    options: {
      fixedColumns: options.fixedColumns,
      smallColumns: options.smallColumns,
    },
    rows: columns,
  }
}

const regexEmail =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

export const isEmailValid = (email: string) =>
  email !== undefined && regexEmail.test(email)

export const containsSpaces = (email: string) => /^\S+$/.test(email)

export const getUserRole = (auth0User?: Auth0User) => {
  const rolesKey = Object.keys(auth0User as any).filter(
    (key) => key.indexOf('roles') !== -1
  )[0]
  return auth0User?.[rolesKey].join(', ')
}

export const getUserLanguage = (auth0User?: Auth0User) => {
  const localesKey = Object.keys(auth0User as any).filter(
    (key) => key.indexOf('locale') !== -1
  )[0]
  const locale = auth0User?.[localesKey]
  return locale || config.locales.default
}

export const getRoleNameByRole = (role: string) => {
  switch (role) {
    case 'ds-admin':
      return 'DS Admin'
    case 'ds-instructor':
      return 'instructor'
    case 'ds-student':
      return 'student'
    case 'yaak-admin':
      return 'team member'
    default:
      return ''
  }
}

export const getHeaderByRole = (role: string) => {
  switch (role) {
    case 'ds-admin':
      return 'DS Admins'
    case 'ds-instructor':
      return 'Instructors'
    case 'ds-student':
      return 'Students'
    case 'yaak-admin':
      return 'Team members'
    case 'nutron-editor':
      return 'Nutrons'
    default:
      return ''
  }
}

export const userRow = (driver: User) =>
  driver ? (
    <AvatarNamePlate
      name={`${driver.firstName} ${driver.lastName}`}
      gravatarURL={driver.profileImageURL}
    />
  ) : (
    '—'
  )
