import React, { memo, useCallback, useEffect, useState } from 'react'
import { getTagValue, useTagsStore } from '../stores/Tags'
import { useShallow } from 'zustand/react/shallow'
import { useTag } from '../../customHooks/useTag'
import Switch from '../Switch'
import style from './style.less'

export interface FilterMenuInputTagProps {
  tagKey: string
}

const FilterMenuSwitchTag = ({ tagKey }: FilterMenuInputTagProps) => {
  const [checked, setChecked] = useState(false)
  const { tags, update } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
      update: state.update,
    }))
  )
  const tag = useTag(tagKey, tags)

  useEffect(() => {
    if (tag) {
      setChecked(getTagValue(tag) === 'true')
    }
  }, [tag])

  const onChange = useCallback(() => {
    if (tag) {
      const newValue = !checked
      setChecked(newValue)
      const value = getTagValue(tag).replace(/ /g, '')
      tag.tagUrl = value
        ? `${tag.tagUrl.replace(value, newValue.toString())}`
        : `${tag.tagUrl} ${newValue}`
      tag.tagValue = value
        ? `${tag.tagValue.replace(value, newValue.toString())}`
        : `${tag.tagValue} ${newValue}`
      update(tags?.filter((t) => (t.name === tagKey ? tag : t)))
    }
  }, [tag, checked, tagKey, tags])

  return (
    tag && (
      <div className={style.filterMenuSwitchContainer}>
        <Switch checked={checked} onChange={onChange} />
      </div>
    )
  )
}

export default memo(FilterMenuSwitchTag)
