import { create } from 'zustand'
import { Camera, SessionTimestamp } from '@yaak/components/services/api/api'

export interface GeoFeatureProperty {
  curriculum_type: string
  id: string
  stroke: string
  distance: number
  type: string
  tags: string[]
}

export interface GeoFeatureGeometry {
  coordinates: any
  type: string
}

export interface GeoFeature {
  geometry: GeoFeatureGeometry
  properties: GeoFeatureProperty
  type: string
}

interface VideosStoreState {
  cameras: Camera[]
  session: SessionTimestamp
  selectedCamera: string
  refresh: boolean
  cameraPositions: string[]
  initialCameras: string[]
  selectedCameras: string[]
  updateRefresh: (refresh: boolean) => void
  updateCameras: (cameras: Camera[]) => void
  updateSelectedCamera: (selectedCamera: string) => void
  updateSession: (session: SessionTimestamp) => void
  updateCameraPositions: (cameraPositions: string[]) => void
  updateInitialCameras: (selectedCameras: string[]) => void
  updateSelectedCameras: (selectedCameras: string[]) => void
}

export const useVideosStore = create<VideosStoreState>((set) => ({
  refresh: false,
  selectedCamera: '',
  cameras: [],
  initialCameras: [],
  selectedCameras: [],
  cameraPositions: [],
  session: {} as SessionTimestamp,
  updateRefresh: (refresh) => set(() => ({ refresh })),
  updateSelectedCamera: (selectedCamera) => set(() => ({ selectedCamera })),
  updateSession: (session) => set(() => ({ session })),
  updateCameras: (cameras) => set(() => ({ cameras })),
  updateCameraPositions: (cameraPositions: string[]) =>
    set(() => ({ cameraPositions })),
  updateSelectedCameras: (selectedCameras: string[]) =>
    set(() => ({ selectedCameras })),
  updateInitialCameras: (initialCameras: string[]) =>
    set((state) => ({
      initialCameras: [...state.initialCameras, ...initialCameras],
    })),
}))
