import React from 'react'
import style from './style.less'
import classNames from 'classnames'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '../Typography/Typography'
import { Version } from '../types'

export const ICON_POSITIONS = {
  LEFT: 'left',
  RIGHT: 'right',
}

export interface ButtonProps {
  className?: string
  disabled?: boolean
  onClick: (event: React.MouseEvent<HTMLElement>) => void
  text: string | React.ReactNode
  icon?: React.ReactNode
  secondary?: boolean
  tertiary?: boolean
  newButton?: boolean
  fullWidth?: boolean
  iconPosition?: string
}

const Button: React.FunctionComponent<ButtonProps> = ({
  className,
  text,
  onClick,
  disabled = false,
  icon,
  secondary = false,
  tertiary = false,
  fullWidth = false,
  newButton = false,
  iconPosition = ICON_POSITIONS.LEFT,
}) => (
  <button
    disabled={disabled}
    className={classNames(
      className,
      !newButton ? style.button : undefined,
      secondary ? style.secondary : undefined,
      tertiary ? style.tertiary : undefined,
      fullWidth ? style.fullWidth : undefined,
      newButton ? style.newButton : undefined
    )}
    onClick={onClick}
  >
    <>
      {iconPosition === ICON_POSITIONS.LEFT && icon}
      <Typography
        type={TypographyTypes.label}
        size={TypographySizes.large}
        version={Version.v2}
      >
        {text}
      </Typography>
      {iconPosition === ICON_POSITIONS.RIGHT && icon}
    </>
  </button>
)

export default Button
