import { Dataset } from '../../services/api/api'
import { Tag } from '../stores/Tags'

export const RANGE_OPERATOR = '($lte,$gte)'
export const MULTI_OPERATOR = ['$in', '$nin']
export const SEQUENCE_OPERATOR = ['seq']
export const SEQUENCE = 'Seq'
export const RANGE_OPERATORS = ['$lte', '$gte', '$lt', '$gt']

export const OPERATORS_MAPPING: Record<string, string> = {
  $eq: 'is',
  $in: 'is one of',
  $ne: 'is not',
  $nin: 'is not one of',
  $gt: 'is greater than',
  $gte: 'is greater than or equal to',
  $lt: 'is less than',
  $lte: 'is less than or equal to',
  '($lte,$gte)': 'is between',
}

export const OPERATORS_URL_MAPPING: Record<string, string> = {
  '=': 'is',
  $in: 'is one of',
  '!=': 'is not',
  $nin: 'is not one of',
  '>': 'is greater than',
  '>=': 'is greater than or equal to',
  '<': 'is less than',
  '<=': 'is less than or equal to',
  '($lte,$gte)': 'is between',
}

export const OPERATORS_SHORT_SYMBOL_MAPPING: Record<string, string> = {
  '=': '',
  $in: '',
  '!=': '!',
  $nin: '!',
  '>': '>',
  '>=': '>=',
  '<': '<',
  '<=': '<=',
}

export const OPERATORS_SYMBOL_MAPPING: Record<string, string> = {
  is: '=',
  'is one of': '$in',
  'is not': '!=',
  'is not one of': '$nin',
  'is greater than': '>',
  'is greater than or equal to': '>=',
  'is less than': '<',
  'is less than or equal to': '<=',
  'is between': '($lte,$gte)',
}

export const OPERATORS_MAPPING_SYMBOL: Record<string, string> = {
  $eq: '=',
  $in: '$in',
  $ne: '!=',
  $nin: '$nin',
  $gt: '>',
  $gte: '>=',
  $lt: '<',
  $lte: '<=',
  '($lte,$gte)': '(x,y)',
}

export const titleCase = (str: string) =>
  str[0].toUpperCase() + str.slice(1).toLowerCase()

export const queryToTags = (query: any) => {
  const result: string[] = []
  if (query) {
    const keys = Object.keys(query)
    if (keys.length > 0) {
      keys.forEach((key) => {
        const operators = Object.keys(query[key])
        const operator = operators?.[0]
        if (
          operators.length === 2 &&
          operators.every((v) => RANGE_OPERATORS.includes(v))
        ) {
          const greaterThanVal = query[key].$gt || query[key].$gte
          const lessThanVal = query[key].$lt || query[key].$lte
          const value = `${greaterThanVal}-${lessThanVal}`
          result.push(`${key} ${RANGE_OPERATOR} ${value}`)
        } else if (query[key][operator]) {
          const value = query[key][operator]
          result.push(`${key} ${OPERATORS_MAPPING_SYMBOL[operator]} ${value}`)
        }
      })
    }
  }
  return result
}

export const sequenceToTags = (query?: any) => {
  const result: string[] = []
  if (query) {
    query.forEach((q: any) => {
      const keys = Object.keys(q)
      if (keys.length > 0) {
        keys.forEach((key) => {
          const operators = Object.keys(q[key])
          const operator = operators?.[0]
          if (q[key][operator]) {
            const value = q[key][operator]
            result.push(`${key}: ${value}`)
          }
        })
      }
    })
    return [`Seq: [${result.join(' -> ')}]`]
  }
  return []
}

export const SEARCH_TYPE = {
  SCENARIOS: 'scenarios',
  SESSIONS: 'sessions',
}

export const createTagsFromURL = (params: string, dataset: Dataset) => {
  const splitParams = params ? params.split('&') : []
  const tags = splitParams
    .map((value) => {
      const params = decodeURIComponent(value)
      const tag = dataset?.searchFilters.filter(
        (filter) => filter.name === params.split(' ')[0]
      )[0]

      if (tag) {
        const dateTag = tag.name === 'date'
        if (dateTag) {
          const operatorAndValue = params.replace(tag.name, '')
          const operator = '='
          const value = operatorAndValue.replace(operator, '').trim()

          return {
            ...tag,
            tagValue: `${tag?.displayName} ${OPERATORS_URL_MAPPING[operator]} ${value}`,
            tagUrl: `${tag?.name} ${operator} ${value}`,
            tagOperator: operator,
          }
        } else {
          const operatorAndValue = params.replace(tag.name, '').trim()
          const operator = operatorAndValue.split(' ')[0]
          const v = operatorAndValue.replace(operator, '').replace(/^\s+/g, '')

          return {
            ...tag,
            tagValue: `${tag?.displayName} ${
              Object.keys(OPERATORS_URL_MAPPING).includes(operator)
                ? OPERATORS_URL_MAPPING[operator]
                : operator
            } ${v}`,
            tagUrl: `${tag?.name} ${
              Object.keys(OPERATORS_URL_MAPPING).includes(operator)
                ? operator
                : OPERATORS_SYMBOL_MAPPING[operator]
            } ${v}`,
            tagOperator: operator,
          }
        }
      } else if (isSequence(params)) {
        return {
          name: SEQUENCE,
          tagValue: params,
          tagUrl: params,
          tagOperator: SEQUENCE_OPERATOR,
        }
      } else {
        return null as any
      }
    })
    .filter(Boolean)

  return tags?.length > 0 ? tags : []
}

export const isValidDateTag = (tags: Tag[] | null) => {
  const dateTag = tags?.find((t) => t.name === 'date')
  return dateTag && dateTag.tagUrl.split(' ').length === 6
}

export const isSequence = (tag: string) => tag.includes('Seq:')

export const hasSequenceTag = (tags: Tag[] | null) =>
  tags?.find((t) => t.name === SEQUENCE)
