import React, { ChangeEvent } from 'react'
import style from './style.less'
import Typography, { TypographyTypes } from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'

export const ALL = 'All properties'

interface DataSetLineProps {
  label: string
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>, label: string) => void
}

const Property = ({ label, checked, onChange }: DataSetLineProps) => {
  return (
    <div className={style.settingsRow}>
      <input
        className={style.settingsRowCheckbox}
        type="checkbox"
        checked={checked}
        onChange={(event) => onChange(event, label)}
      />
      <Typography
        className={style.label}
        type={TypographyTypes.label}
        version={Version.v2}
      >
        {label}
      </Typography>
    </div>
  )
}

export default Property
