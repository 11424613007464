import { create } from 'zustand'
import { SearchFilter } from '../../services/api/api'
import { RANGE_OPERATOR } from '../SearchQueryBar/utils'

export type Tag = SearchFilter & {
  tagValue: string
  tagUrl: string
  tagOperator: string
  duration?: number
}

interface TagsStoreState {
  tags: Tag[] | null
  update: (tags?: Tag[], sequenceDuration?: number) => void
  sequenceDuration?: number
}

export const useTagsStore = create<TagsStoreState>((set) => ({
  tags: null,
  update: (tags, sequenceDuration) =>
    set(() => ({
      sequenceDuration,
      tags: tags
        ? [
            ...tags.map((t) => {
              if (
                t.supportedOperators?.includes('$gte') &&
                t.supportedOperators?.includes('$lte') &&
                !t.supportedOperators?.includes(RANGE_OPERATOR)
              ) {
                t.supportedOperators?.push(RANGE_OPERATOR)
              }
              return t
            }),
          ]
        : null,
    })),
}))

export const getTagValue = (tag: Tag) =>
  tag.tagUrl.replace(tag.name, '').replace(tag.tagOperator, '').trim()
