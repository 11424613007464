import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import style from './style.less'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import SaveEditQueryDialog from './dialogs/SaveEditQueryDialog'
import classNames from 'classnames'
import WarningDialog from '../WarningDialog'
import {
  Collection,
  deleteSavedQuery,
  editSavedQuery,
  getSavedQueries,
  saveSearchQuery,
  SearchFilter,
} from '../../services/api/api'
import { ToastContext, ToastContextType } from '../../context/toastContext'
import { useAuth0 } from '@auth0/auth0-react'
import {
  areFiltersOnlySessionInfo,
  areParamsValid,
  parseSearchParams,
} from '../DrivesOverview/utils'
import {
  createTagsFromURL,
  hasSequenceTag,
  isValidDateTag,
  OPERATORS_MAPPING_SYMBOL,
  OPERATORS_URL_MAPPING,
  queryToTags,
  sequenceToTags,
} from './utils'
import ToggleButton, {
  ToggleButtonTypes,
  ToggleColors,
} from '@yaak/components/src/ToggleButton'
import SearchQueryTag from './SearchQueryTag'
import { useShallow } from 'zustand/react/shallow'
import { Tag, useTagsStore } from '../stores/Tags'
import NLSAutocomplete from './NLSAutocomplete'
import { useDatasetStore } from '@yaak/nutron/src/stores/DatasetStore'
import { isSandboxUser } from '@yaak/nutron/src/utils/sandbox'
import WelcomeDialog from '@yaak/nutron/src/components/WelcomeDialog'
import Button from '../Button'

export interface SavedQuery {
  id?: string
  conditions?: any
  value: string
  group?: string
  onCopy: (savedQuery: SavedQuery) => void
  onEdit: (savedQuery: SavedQuery) => void
  onDelete: (savedQuery: SavedQuery) => void
  onClick: (savedQuery: SavedQuery) => void
}

export enum ViewTypeValues {
  list = 'list',
  grid = 'grid',
  sessionLogs = 'session-logs',
  scenarios = 'scenarios',
}

interface SearchQueryBarProps {
  collection?: Collection
  datasetId?: string
  token: string
  totalSessions?: number
  totalScenarios?: number
  selectionEnabled: number
}

const getSavedQueryUrl = ({
  collection,
  query,
  datasetId,
  context,
}: {
  collection?: Collection
  query: SavedQuery
  datasetId?: string
  context: string
}) => {
  const tags = queryToTags(query.conditions.query)
    .concat(queryToTags(query.conditions.filters))
    .concat(sequenceToTags(query?.conditions.sequenceQuery?.sequence))
    .join('&')

  return tags && collection
    ? `/collections/${datasetId}/${
        collection.id
      }/search/grid/scenarios?context=${context}&q=${encodeURIComponent(
        tags
      )}&sequenceDuration=${query?.conditions?.sequenceQuery?.duration}`
    : tags &&
        `/datasets/${datasetId}/search/grid/scenarios?context=${context}&q=${encodeURIComponent(
          tags
        )}&sequenceDuration=${query?.conditions?.sequenceQuery?.duration}`
}

const SearchQueryBar = ({
  collection,
  datasetId,
  token,
  totalSessions,
  totalScenarios,
  selectionEnabled,
}: SearchQueryBarProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { type, route } = useParams()
  const contextRef = useRef<HTMLInputElement>(null)
  const textFieldRef = useRef<HTMLInputElement | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [context, setContext] = useState<string>('5s')
  const [contextEdited, setContextEdited] = useState<boolean>(false)
  const [showSaveQueryModal, setShowSaveQueryModal] = useState(false)
  const [showOpenWelcomeDialog, setShowOpenWelcomeDialog] = useState(false)
  const [savedQueries, setSavedQueries] = useState<SavedQuery[]>([])
  const [savedQueryToDelete, setSavedQueryToDelete] = useState<any>()
  const [savedQueryToEdit, setSavedQueryToEdit] = useState<any>()
  const [range] = useState<[Date, Date] | null>()
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const { user } = useAuth0()
  const { tags, update, sequenceDuration } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
      update: state.update,
      sequenceDuration: state.sequenceDuration,
    }))
  )

  const { dataset } = useDatasetStore(
    useShallow((state) => ({
      dataset: state.dataset,
    }))
  )
  const isGuestUser = isSandboxUser()

  const onSavedQueryClick = useCallback(
    (query: SavedQuery) => {
      const url = getSavedQueryUrl({ collection, query, context, datasetId })
      url && navigate(url, { replace: true })
    },
    [datasetId, context, collection]
  )

  const onSavedQueryCopy = useCallback(
    (query: SavedQuery) => {
      const url = getSavedQueryUrl({ collection, query, context, datasetId })
      navigator.clipboard.writeText(`${window.location.origin}${url}`)
    },
    [collection, datasetId, context]
  )

  const getQueries = async () => {
    if (datasetId) {
      const savedQueriesResponse = await getSavedQueries({
        userId: user?.sub,
        datasetId,
        token,
        onAlert: setShowToast,
      })
      if (savedQueriesResponse?.data?.length > 0) {
        const savedQueries = savedQueriesResponse.data.map((savedQuery) => {
          return {
            id: savedQuery.id,
            conditions: savedQuery.conditions,
            value: savedQuery.name,
            onClick: onSavedQueryClick,
            onCopy: onSavedQueryCopy,
            onEdit: (savedQuery: SavedQuery) => {
              setSavedQueryToEdit(savedQuery)
            },
            onDelete: (savedQuery: SavedQuery) => {
              setSavedQueryToDelete(savedQuery)
            },
          }
        })
        setSavedQueries(savedQueries)
      }
    }
  }

  useEffect(() => {
    token && user && getQueries()
  }, [token, user])

  const createTags = useCallback(
    (tag: SearchFilter | null) => {
      if (tag) {
        const newTag: Tag[] = [
          {
            ...tag,
            tagValue: `${tag.displayName} ${
              OPERATORS_URL_MAPPING[
                OPERATORS_MAPPING_SYMBOL[tag.supportedOperators[0]]
              ]
            }${tag.type === 'bool' ? ' false' : ''}`,
            tagUrl: `${tag.name} ${
              OPERATORS_MAPPING_SYMBOL[tag.supportedOperators[0]]
            }${tag.type === 'bool' ? ' false' : ''}`,
            tagOperator: OPERATORS_MAPPING_SYMBOL[tag.supportedOperators[0]],
          },
        ]
        update(tags ? tags.concat(newTag) : newTag)
        return tags ? tags.concat(newTag) : newTag
      }
      return []
    },
    [tags]
  )

  useEffect(() => {
    const navigateTo = collection ? 'collections' : 'datasets'
    const addCollectionId = collection ? `/${collection.id}` : ''
    if (tags && tags.length > 0) {
      const begin = searchParams.getAll('begin')[0]
      const end = searchParams.getAll('end')[0]
      searchParams.set(
        'q',
        tags.map((tag) => encodeURIComponent(tag.tagUrl)).join('&')
      )
      if (sequenceDuration) {
        searchParams.set('sequenceDuration', sequenceDuration.toString())
      }

      const dateTag = tags?.find((t) => t.name === 'date')

      const scenariosSearch = searchParams.get('scenarios')
      const navigateToRoute = scenariosSearch === 'search' ? 'scenarios' : route
      const navigateToType = scenariosSearch === 'search' ? 'grid' : type

      if (begin && !dateTag) {
        searchParams.delete('begin')
        searchParams.delete('end')
      }

      setSearchParams(searchParams)
      const queryParam = searchParams.get('q')
      if (queryParam) {
        const validParams = areParamsValid(decodeURIComponent(queryParam))
        if (validParams || isValidDateTag(tags) || hasSequenceTag(tags)) {
          begin !== undefined && end !== undefined
            ? navigate(
                `/${navigateTo}/${datasetId}${addCollectionId}/search/${navigateToType}/${navigateToRoute}?q=${encodeURIComponent(
                  queryParam
                )}&context=${context}&begin=${begin}&end=${end}`
              )
            : navigate(
                `/${navigateTo}/${datasetId}${addCollectionId}/search/${navigateToType}/${navigateToRoute}?q=${encodeURIComponent(
                  queryParam
                )}&context=${context}`
              )
        }
      }
    } else {
      navigate(
        `/${navigateTo}/${datasetId}${addCollectionId}/search/list/session-logs`
      )
    }
  }, [tags, context, searchParams, sequenceDuration, route, type, collection])

  useEffect(() => {
    if (
      !tags &&
      dataset?.searchFilters?.length &&
      dataset?.searchFilters?.length > 0
    ) {
      const params = searchParams.getAll('q')[0]
      const sequenceDuration =
        parseInt(searchParams.getAll('sequenceDuration')[0]) || 0
      const createdTags = createTagsFromURL(params, dataset)
      createdTags.length > 0 && update(createdTags, sequenceDuration)
    }
  }, [tags, searchParams, dataset])

  useEffect(() => {
    const params = searchParams.getAll('context')[0]
    const newContext = params || context
    if (!contextEdited) {
      setContext(newContext)
    }
    searchParams.set(
      'context',
      newContext.includes('s') ? newContext : `${newContext}s`
    )
    setSearchParams(searchParams)
  }, [searchParams, context, contextEdited])

  const onContextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setContext(event.target.value)
      searchParams.set('context', event.target.value)
      setSearchParams(searchParams)
      setContextEdited(true)
    },
    [searchParams]
  )

  const onContextKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Tab' || event.key === 'Enter') {
        const contextVal = context || '5s'
        const newContext =
          contextVal.indexOf('s') === -1 ? contextVal + 's' : contextVal
        setContext(newContext)
        searchParams.set('context', newContext)
        contextRef.current?.blur()
      }
    },
    [context]
  )

  const onContextFocusOut = useCallback(() => {
    if (!context) {
      setContext('5s')
    } else if (!context.includes('s')) {
      setContext(`${context}s`)
    }
  }, [context])

  const onContextFocus = useCallback(() => {
    setContext('')
    setContextEdited(true)
  }, [])

  const onClearFilters = useCallback(() => {
    update()
    searchParams.delete('q')
    setSearchParams(searchParams)
    navigate(
      collection
        ? `/collections/${datasetId}/${collection.id}/search/list/session-logs?context=5s`
        : `/datasets/${datasetId}/search/list/session-logs?context=5s`,
      {
        replace: true,
      }
    )
  }, [searchParams, datasetId, navigate, collection])

  const onSavedQueryClicked = useCallback(
    (savedQuery: SavedQuery) => {
      savedQuery.onClick(savedQuery)
      update()
    },
    [tags]
  )

  const onFilterMenuClick = useCallback(
    (value: SearchFilter | null) => {
      const newTags = createTags(value)
      if (!areFiltersOnlySessionInfo(newTags)) {
        searchParams.set('scenarios', 'search')
        setSearchParams(searchParams)
      }
    },
    [createTags, searchParams]
  )

  const onToggleChange = useCallback(
    (value: string) => {
      navigate(
        `/datasets/${datasetId}/search/${type}/${value}${location.search}`
      )
    },
    [datasetId, type, location.search]
  )

  const onGridListToggle = useCallback(
    (value: string) => {
      navigate(
        collection
          ? `/collections/${datasetId}/${collection.id}/search/${value}/${route}${location.search}`
          : `/datasets/${datasetId}/search/${value}/${route}${location.search}`
      )
    },
    [datasetId, collection, route, location.search]
  )

  return (
    <div className={style.container}>
      <div className={style.searchContainer}>
        <div className={style.searchContext}>
          <NLSAutocomplete
            collection={collection}
            selectionEnabled={selectionEnabled}
            token={token}
            savedQueries={savedQueries}
            onClearFilters={onClearFilters}
            onFilterMenuClick={onFilterMenuClick}
            onSavedQueryClick={(savedQuery) => {
              update()
              onSavedQueryClicked(savedQuery)
            }}
            onSaveButtonClick={() =>
              isGuestUser
                ? setShowOpenWelcomeDialog(true)
                : setShowSaveQueryModal(true)
            }
            textFieldRef={textFieldRef}
          />
          <input
            className={classNames(
              style.contextInput,
              contextEdited ? style.contextInputEdited : undefined
            )}
            ref={contextRef}
            value={context}
            title={'Set context window'}
            onChange={onContextChange}
            onBlur={onContextFocusOut}
            onFocus={onContextFocus}
            onKeyDown={onContextKeyDown}
          />
        </div>
        <ToggleButton
          value={type || ViewTypeValues.list}
          toggleButtons={[
            { icon: 'ViewList', value: ViewTypeValues.list },
            {
              icon: 'ViewGrid',
              value: ViewTypeValues.grid,
              disabled: !tags || tags?.length === 0,
            },
          ]}
          onChange={onGridListToggle}
          color={ToggleColors.green}
          type={ToggleButtonTypes.icon}
        />
        <ToggleButton
          readOnly={!!collection}
          value={route || ViewTypeValues.sessionLogs}
          toggleButtons={[
            {
              text: 'Session logs',
              value: ViewTypeValues.sessionLogs,
              numberItem: totalSessions,
            },
            {
              text: 'Scenarios',
              value: ViewTypeValues.scenarios,
              numberItem: totalScenarios,
              disabled:
                !tags || tags?.length === 0 || areFiltersOnlySessionInfo(tags),
            },
          ]}
          onChange={onToggleChange}
          color={ToggleColors.green}
          type={ToggleButtonTypes.text}
        />
      </div>
      {tags && tags.length > 0 && (
        <div className={style.tagsContainer}>
          <div className={style.tags}>
            {tags.map((tag) => (
              <SearchQueryTag key={`${tag.name}`} tagKey={`${tag.name}`} />
            ))}
            <Button
              onClick={onClearFilters}
              text={'Clear filters'}
              tertiary
              className={style.clearFiltersButton}
            />
          </div>
        </div>
      )}
      <SaveEditQueryDialog
        sequenceDuration={sequenceDuration}
        savedQueries={savedQueries}
        edit={savedQueryToEdit}
        open={showSaveQueryModal || !!savedQueryToEdit}
        onCancel={() => {
          setShowSaveQueryModal(false)
          setSavedQueryToEdit(null)
        }}
        tags={tags ? [...tags] : []}
        onConfirmed={async (result, tags, sequenceDuration) => {
          const data = parseSearchParams(tags, dataset)
          update(tags)

          if (sequenceDuration && data.sequenceQuery) {
            data.sequenceQuery.duration = sequenceDuration
          }

          if (savedQueryToEdit && datasetId) {
            await editSavedQuery({
              token,
              userId: user?.sub,
              onAlert: setShowToast,
              queryId: savedQueryToEdit.id,
              query: {
                name: result.name,
                datasetId,
                conditions: {
                  ...data,
                },
              },
            })
            if (dataset) {
              const queryTags = queryToTags(data.query)
                .concat(queryToTags(data.filters))
                .concat(sequenceToTags(data.sequenceQuery?.sequence))
                .join('&')
              queryTags &&
                navigate(
                  `/datasets/${datasetId}/${type}?context=${context}&q=${encodeURIComponent(
                    queryTags
                  )}`,
                  { replace: true }
                )
            }
            setSavedQueryToEdit(null)
          } else {
            await saveSearchQuery({
              token,
              userId: user?.sub,
              datasetId,
              onAlert: setShowToast,
              searchQuery: {
                name: result.name,
                conditions: {
                  range,
                  ...data,
                },
              },
            })
            setShowSaveQueryModal(false)
          }
          await getQueries()
        }}
      />
      <WarningDialog
        icon={null}
        isOpen={!!savedQueryToDelete}
        dialogContentText={
          <Typography type={TypographyTypes.title} size={TypographySizes.large}>
            Delete &quot;{savedQueryToDelete?.value}&quot;?
          </Typography>
        }
        dialogContentText2={`This action cannot be undone.`}
        dialogTitle={''}
        onSubmit={async () => {
          await deleteSavedQuery({
            token,
            queryId: savedQueryToDelete?.id,
            userId: user?.sub,
            onAlert: setShowToast,
          })
          await getQueries()
          setSavedQueryToDelete(undefined)
        }}
        onCancel={() => {
          setSavedQueryToDelete(undefined)
        }}
        buttons={{
          cancel: 'Cancel',
          submit: 'Delete',
        }}
      />
      <WelcomeDialog
        header="Join Nutron for free"
        open={showOpenWelcomeDialog}
        onClose={() => setShowOpenWelcomeDialog(false)}
      />
    </div>
  )
}

export default SearchQueryBar
