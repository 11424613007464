import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@yaak/components/src/Button'
import yaakLogo from '@yaak/components/assets/images/yaakLogo2.0.svg'
import style from './style.less'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'
import Icon from '@yaak/components/src/Icon'
import Divider from '@yaak/components/src/Divider'
import config from '@yaak/components/services/api/config'
import RegistrationDialog from '../RegistrationDialog'

interface WelcomeDialogProps {
  header: string
  description?: string
  open: boolean
  onClose: () => void
  showInfo?: boolean
}

const WelcomeDialog = ({
  header,
  description,
  open,
  onClose,
  showInfo = false,
}: WelcomeDialogProps) => {
  const [openRegisterDialog, setOpenRegisterDialog] = useState(false)

  const onRegister = () => {
    onClose()
    setOpenRegisterDialog(true)
  }

  const onRegisterClose = () => setOpenRegisterDialog(false)
  return (
    <>
      <Dialog onClose={onClose} open={open}>
        <div>
          <DialogTitle id="form-dialog-title">
            <div className={style.titleContainer}>
              <div className={style.logoContainer}>
                <img src={yaakLogo} alt="Logo" className={style.logo} />
              </div>
              <Icon
                name={'Close'}
                version={Version.v2}
                className={style.closeIcon}
                onClick={onClose}
              />
            </div>
            <Typography version={Version.v2} type={TypographyTypes.headline}>
              {header}
            </Typography>
            {description && (
              <Typography
                version={Version.v2}
                type={TypographyTypes.body}
                size={TypographySizes.large}
              >
                Register or log in to access all features.
              </Typography>
            )}
          </DialogTitle>
          <DialogContent>
            <div className={style.boxContainer}>
              <div className={style.box}>
                <Icon
                  version={Version.v2}
                  className={style.icon}
                  name={'Collection'}
                />
                <Typography version={Version.v2} type={TypographyTypes.body}>
                  Curate collections with natural language search
                </Typography>
              </div>
              <div className={style.box}>
                <Icon
                  version={Version.v2}
                  className={style.icon}
                  name={'ModelTraining'}
                />
                <Typography version={Version.v2} type={TypographyTypes.body}>
                  Train AI (
                  <a
                    className={style.link}
                    href={'https://huggingface.co/lerobot'}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    LeRobot
                  </a>
                  ,
                  <a
                    className={style.link}
                    href={'https://github.com/yaak-ai/rbyte'}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    rbyte
                  </a>
                  ) on collections
                </Typography>
              </div>
            </div>
          </DialogContent>
          <DialogActions className={style.left}>
            <div className={style.leftButtons}>
              <Button
                secondary
                className={style.registerButton}
                onClick={onRegister}
                text={'Register now'}
              />
              <Button
                tertiary
                onClick={() => {
                  onClose()
                  window.location.href = config.nutronUrl
                }}
                text={'Log in'}
              />
            </div>
            {showInfo && (
              <Button
                className={style.continueButton}
                tertiary
                onClick={() => {
                  onClose()
                }}
                text={'Continue as guest'}
              />
            )}
          </DialogActions>
          <div className={style.footer}>
            <Divider />
            <div className={style.footerContent}>
              <div className={style.links}>
                <Typography
                  version={Version.v2}
                  type={TypographyTypes.body}
                  size={TypographySizes.extraSmall}
                  url={'https://yaak.ai/contact'}
                  blank
                >
                  Contact us
                </Typography>
                <Typography
                  version={Version.v2}
                  type={TypographyTypes.body}
                  size={TypographySizes.extraSmall}
                  url={'https://yaak.ai/privacy-policy/nutron'}
                  blank
                >
                  Privacy policy
                </Typography>
                <Typography
                  version={Version.v2}
                  type={TypographyTypes.body}
                  size={TypographySizes.extraSmall}
                  url={'https://yaak.ai/nutron-terms'}
                  blank
                >
                  Terms of Use
                </Typography>
              </div>
              {showInfo && (
                <div>
                  <Typography
                    className={style.disclaimer}
                    version={Version.v2}
                    type={TypographyTypes.body}
                    size={TypographySizes.extraSmall}
                  >
                    Limited feature set
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      </Dialog>
      {openRegisterDialog && (
        <RegistrationDialog
          open={openRegisterDialog}
          onClose={onRegisterClose}
        />
      )}
    </>
  )
}

export default WelcomeDialog
