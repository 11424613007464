// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__dialog--m2GnL {\n  border-radius: 0.75rem !important;\n}\n.style__dialogActions--xjlni {\n  justify-content: space-between !important;\n  padding: 1.5rem !important;\n}\n.style__row--Bt9cL {\n  display: flex;\n  gap: 1rem;\n}\n.style__warningIcon--aPTve {\n  color: #e5b300;\n  background-color: #fffae5;\n  padding: 1rem;\n  border-radius: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./../components/src/WarningDialog/style.less"],"names":[],"mappings":"AAGA;EAEI,iCAAA;AAHJ;AACA;EAMI,yCAAA;EACA,0BAAA;AAJJ;AAHA;EAWI,aAAA;EACA,SAAA;AALJ;AAPA;EAgBI,cAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;AANJ","sourcesContent":["@import '@yaak/components/src/colors';\n@import '@yaak/components/src/spacing';\n\n:local {\n  .dialog {\n    border-radius: @spacing-75 !important;\n  }\n\n  .dialogActions {\n    justify-content: space-between !important;\n    padding: @spacing-150 !important;\n  }\n\n  .row {\n    display: flex;\n    gap: @spacing-100;\n  }\n\n  .warningIcon {\n    color: @color-yellow-050;\n    background-color: @color-yellow-099;\n    padding: @spacing-100;\n    border-radius: @spacing-200;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "style__dialog--m2GnL",
	"dialogActions": "style__dialogActions--xjlni",
	"row": "style__row--Bt9cL",
	"warningIcon": "style__warningIcon--aPTve"
};
export default ___CSS_LOADER_EXPORT___;
