import React, { useEffect, useState } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import Router from './Routes'
import SideNavigation from '@yaak/components/src/SideNavigation'
import bundledRelease from '../public/release.json'
import Profile from '@yaak/components/src/Profile'
import en from '@yaak/ds-admin/src/lang/en.json'
import de from '@yaak/ds-admin/src/lang/de.json'
import { IntlProvider } from 'react-intl'
import { getUserLanguage } from './helpers/users'
import Icon from '@yaak/components/src/Icon'
import { IconSizes, IconTypes } from '@yaak/components/src/Icon/Icon'
import {
  getPairingRequests,
  PairingResponse,
} from '@yaak/components/services/api/api'
import Toast, { toastType } from '@yaak/components/src/Toast/Toast'
import { ToastContext } from '@yaak/components/context/toastContext'
import { displayWaitingPairingRequests } from './helpers/pairingRequests'
import style from './style.less'
import classNames from 'classnames'
import { isSimAdmin } from './utils/simAdmin'
import { Version } from '@yaak/components/src/types'

const sideNavigationLabelsSimAdmin = [
  [
    {
      icon: (
        <Icon
          name={'Partner'}
          size={IconSizes.large}
          type={IconTypes.regular}
        />
      ),
      text: 'Partners',
    },
    {
      icon: (
        <Icon
          name={'SteeringWheel'}
          size={IconSizes.large}
          type={IconTypes.regular}
        />
      ),
      text: 'Drives',
    },
    {
      icon: (
        <Icon name={'Star'} size={IconSizes.large} type={IconTypes.regular} />
      ),
      text: 'DS Admins',
    },
    {
      icon: (
        <Icon
          name={'Instructor'}
          size={IconSizes.large}
          type={IconTypes.regular}
        />
      ),
      text: 'Instructors',
    },
    {
      icon: (
        <Icon
          name={'Student'}
          size={IconSizes.large}
          type={IconTypes.regular}
        />
      ),
      text: 'Students',
    },
    {
      icon: (
        <Icon name={'VR'} size={IconSizes.large} type={IconTypes.regular} />
      ),
      text: 'Simulator rigs',
    },
  ],
]

const sideNavigationLabels = (waitingPairingRequests?: PairingResponse) => [
  [
    {
      icon: (
        <Icon
          name={'Partner'}
          size={IconSizes.large}
          type={IconTypes.regular}
        />
      ),
      text: 'Partners',
    },
    {
      icon: (
        <Icon
          name={'SteeringWheel'}
          size={IconSizes.large}
          type={IconTypes.regular}
        />
      ),
      text: 'Drives',
      subItems: [
        {
          text: 'On-road',
        },
        {
          text: 'Simulation',
        },
      ],
    },
    {
      icon: (
        <Icon name={'Star'} size={IconSizes.large} type={IconTypes.regular} />
      ),
      text: 'DS Admins',
    },
    {
      icon: (
        <Icon
          name={'Instructor'}
          size={IconSizes.large}
          type={IconTypes.regular}
        />
      ),
      text: 'Instructors',
    },
    {
      icon: (
        <Icon
          name={'Student'}
          size={IconSizes.large}
          type={IconTypes.regular}
        />
      ),
      text: 'Students',
    },
    {
      icon: (
        <Icon
          name={'Robot'}
          size={IconSizes.large}
          type={IconTypes.regular}
          version={Version.v2}
        />
      ),
      text: 'Nutrons',
    },
    {
      icon: (
        <Icon name={'Car'} size={IconSizes.large} type={IconTypes.regular} />
      ),
      text: 'Vehicles',
    },
    {
      icon: (
        <Icon name={'VR'} size={IconSizes.large} type={IconTypes.regular} />
      ),
      text: 'Simulator rigs',
    },
    {
      icon: (
        <Icon name={'Device'} size={IconSizes.large} type={IconTypes.regular} />
      ),
      text: 'Kits',
      badge: displayWaitingPairingRequests(waitingPairingRequests),
    },
  ],
]

const sideNavigationTooltips = {
  collapse: 'Collapse menu',
  expand: 'Expand menu',
  knowledgeBase: 'Knowledge base',
}

export const LIGHT_THEME = 'light'
export const DARK_THEME = 'dark'

interface ThemeTypeContext {
  theme: string
  setTheme: React.Dispatch<React.SetStateAction<string>>
}
export const ThemeContext = React.createContext<ThemeTypeContext>({
  theme: LIGHT_THEME,
  setTheme: () => {},
})

const App = () => {
  const [page, setPage] = useState(-1)
  const [token, setToken] = useState<string>('')
  const [toast, setShowToast] = useState<toastType | null>(null)
  const [theme, setTheme] = useState(LIGHT_THEME)

  const { getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()
  const location = useLocation()

  const { user } = useAuth0()
  const language = getUserLanguage(user)
  const simAdmin = isSimAdmin(user)

  const [waitingPairingRequests, setWaitingPairingRequests] =
    useState<PairingResponse>()

  const fetchWaitingPairingRequests = async (token: string) => {
    const result = await getPairingRequests({
      token,
      status: 'WAITING',
      onAlert: setShowToast,
    })
    setWaitingPairingRequests(result)
  }

  const navigationLabels = simAdmin
    ? sideNavigationLabelsSimAdmin
    : sideNavigationLabels(waitingPairingRequests)

  useEffect(() => {
    token && !simAdmin && fetchWaitingPairingRequests(token)
  }, [token])

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently()
      setToken(token)
    }
    getToken()
  }, [])

  useEffect(() => {
    if (location) {
      simAdmin
        ? setCurrentPageIndexSimAdmin(location.pathname)
        : setCurrentPageIndex(location.pathname)
    }
  }, [location])

  const setCurrentPageIndexSimAdmin = (pathname: string) => {
    if (matchPath({ path: '/partners', end: false }, pathname)) {
      setPage(0)
    } else if (
      matchPath({ path: '/drives/simulation', end: false }, pathname)
    ) {
      setPage(1)
    } else if (matchPath({ path: '/dsadmins', end: false }, pathname)) {
      setPage(2)
    } else if (matchPath({ path: '/instructors', end: false }, pathname)) {
      setPage(3)
    } else if (matchPath({ path: '/students', end: false }, pathname)) {
      setPage(4)
    } else if (matchPath({ path: '/nutrons', end: false }, pathname)) {
      setPage(5)
    } else if (matchPath({ path: '/simulatorrigs', end: false }, pathname)) {
      setPage(6)
    } else {
      setPage(-1)
    }
  }

  const navigateToPageSimAdmin = (page: number) => {
    switch (page) {
      case 0:
        return navigate('/partners')
      case 1:
        return navigate('/drives/simulation/active')
      case 2:
        return navigate('/dsadmins')
      case 3:
        return navigate('/instructors')
      case 4:
        return navigate('/students')
      case 5:
        return navigate('/nutrons')
      case 6:
        return navigate('/simulatorrigs')
      default:
        return navigate('/')
    }
  }

  const setCurrentPageIndex = (pathname: string) => {
    if (matchPath({ path: '/partners', end: false }, pathname)) {
      setPage(0)
    } else if (matchPath({ path: '/drives/onroad', end: false }, pathname)) {
      setPage(1.1)
    } else if (
      matchPath({ path: '/drives/simulation', end: false }, pathname)
    ) {
      setPage(1.2)
    } else if (matchPath({ path: '/dsadmins', end: false }, pathname)) {
      setPage(2)
    } else if (matchPath({ path: '/instructors', end: false }, pathname)) {
      setPage(3)
    } else if (matchPath({ path: '/students', end: false }, pathname)) {
      setPage(4)
    } else if (matchPath({ path: '/nutrons', end: false }, pathname)) {
      setPage(5)
    } else if (matchPath({ path: '/vehicles', end: false }, pathname)) {
      setPage(6)
    } else if (matchPath({ path: '/simulatorrigs', end: false }, pathname)) {
      setPage(7)
    } else if (matchPath({ path: '/kits', end: false }, pathname)) {
      setPage(8)
    } else {
      setPage(-1)
    }
  }

  const navigateToPage = (page: number) => {
    switch (page) {
      case 0:
        return navigate('/partners')
      case 1:
        return navigate('/drives/onroad')
      case 1.1:
        return navigate('/drives/onroad')
      case 1.2:
        return navigate('/drives/simulation/active')
      case 2:
        return navigate('/dsadmins')
      case 3:
        return navigate('/instructors')
      case 4:
        return navigate('/students')
      case 5:
        return navigate('/nutrons')
      case 6:
        return navigate('/vehicles')
      case 7:
        return navigate('/simulatorrigs')
      case 8:
        return navigate('/kits/paired')
      default:
        return navigate('/')
    }
  }

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <IntlProvider locale={language} messages={language === 'en' ? en : de}>
        <ToastContext.Provider value={{ toast, setShowToast }}>
          <div className={classNames(style[theme], style.app)}>
            <Profile
              bundledRelease={bundledRelease}
              onSettingsClicked={() => {
                setPage(-1)
              }}
            />
            <SideNavigation
              admin={true}
              labels={navigationLabels}
              tooltips={sideNavigationTooltips}
              currentPage={page}
              onPageChange={(page) => {
                setPage(page)
                simAdmin ? navigateToPageSimAdmin(page) : navigateToPage(page)
              }}
            />
            <div className={style.main}>
              <Router token={token} />
              {toast && <Toast toast={toast} setShowToast={setShowToast} />}
            </div>
          </div>
        </ToastContext.Provider>
      </IntlProvider>
    </ThemeContext.Provider>
  )
}

export default withAuthenticationRequired(App, {})
