import React, { ChangeEvent, useContext, useMemo, useState } from 'react'
import style from './style.less'
import yaakLogo from '@yaak/components/assets/images/yaakLogo2.0.svg'
import Typography, {
  TypographySizes,
  TypographyStyles,
  TypographyTypes,
} from '@yaak/components/src/Typography'
import ReCAPTCHA from 'react-google-recaptcha'
import { Dialog, DialogContent, InputAdornment, TextField } from '@mui/material'
import Icon from '@yaak/components/src/Icon'
import { Version } from '@yaak/components/src/types'
import Button from '@yaak/components/src/Button'
import { EMAIL_REGEX, PASSWORD_REGEX } from '@yaak/nutron/src/utils'
import { registerUser } from '@yaak/components/services/api/api'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import config from '@yaak/components/services/api/config'

const termsLink = (
  <a
    href="https://www.yaak.ai/nutron-terms"
    target="_blank"
    rel="noreferrer"
    className={style.link}
  >
    Terms of Use
  </a>
)
const privacyLink = (
  <a
    href="https://www.yaak.ai/privacy-policy/nutron"
    target="_blank"
    rel="noreferrer"
    className={style.link}
  >
    Privacy Policy.
  </a>
)
const termsAndConditions = (
  <>
    I agree to the {termsLink} and {privacyLink}
  </>
)

interface IRegistrationDialogProps {
  open: boolean
  onClose: () => void
}

const RegistrationDialog = ({ open, onClose }: IRegistrationDialogProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [accountCreated, setAccountCreated] = useState<boolean>(false)
  const [captchaValue, setCaptchaValue] = useState<string>('')
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    isAgreed: false,
  })
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const onFormValueChanged =
    (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setFormValues((value) => ({
        ...value,
        [name]: name === 'isAgreed' ? e.target.checked : e.target.value,
      }))
    }

  const isFormValid = useMemo(
    () =>
      Object.entries(formValues).every(([, val]) => !!val) &&
      PASSWORD_REGEX.test(formValues.password) &&
      EMAIL_REGEX.test(formValues.email),
    [formValues]
  )

  const getValidationIcon = (isValid: boolean) =>
    isValid ? (
      <Icon name="Checkmark" version={Version.v2} className={style.success} />
    ) : (
      <Icon name="Close" version={Version.v2} className={style.error} />
    )
  const changeVisibility = () => setShowPassword((v) => !v)

  const onSubmit = async () => {
    const data = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      password: formValues.password,
      captcha: captchaValue,
    }
    const user = await registerUser({ data, onAlert: setShowToast })
    if (user) {
      setAccountCreated(true)
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent sx={{ position: 'relative' }}>
        <div className={style.container}>
          <div className={style.logo}>
            <img src={yaakLogo} alt="Logo" />
          </div>
          <Icon
            name="Close"
            version={Version.v2}
            className={style.closeIcon}
            onClick={onClose}
          />
          {accountCreated ? (
            <div className={style.title}>
              <Typography
                type={TypographyTypes.headline}
                size={TypographySizes.small}
                version={Version.v2}
              >
                Account created
              </Typography>
              <Typography type={TypographyTypes.body} version={Version.v2}>
                To continue to Nutron, please confirm your email.
              </Typography>
            </div>
          ) : (
            <>
              <div className={style.title}>
                <Typography
                  type={TypographyTypes.headline}
                  size={TypographySizes.small}
                  version={Version.v2}
                >
                  Register
                </Typography>
                <Typography type={TypographyTypes.body} version={Version.v2}>
                  Create your Nutron account
                </Typography>
              </div>
              <div className={style.form}>
                <div className={style.name}>
                  <TextField
                    label="First name"
                    fullWidth
                    required
                    value={formValues.firstName}
                    onChange={onFormValueChanged('firstName')}
                  />
                  <TextField
                    label="Last name"
                    fullWidth
                    required
                    value={formValues.lastName}
                    onChange={onFormValueChanged('lastName')}
                  />
                </div>
                <TextField
                  label="Email address"
                  fullWidth
                  required
                  value={formValues.email}
                  onChange={onFormValueChanged('email')}
                />
                {formValues.email && !EMAIL_REGEX.test(formValues.email) && (
                  <div>
                    <div className={style.validation}>
                      {getValidationIcon(false)}
                      <Typography type={TypographyTypes.label}>
                        Invalid email address
                      </Typography>
                    </div>
                  </div>
                )}
                <TextField
                  label="Password"
                  fullWidth
                  required
                  type={showPassword ? 'text' : 'password'}
                  value={formValues.password}
                  onChange={onFormValueChanged('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Icon
                          name={showPassword ? 'VisibilityOff' : 'Visibility'}
                          version={Version.v2}
                          className={style.visibilityIcon}
                          onClick={changeVisibility}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                {formValues.password &&
                  !PASSWORD_REGEX.test(formValues.password) && (
                    <div>
                      <div className={style.validation}>
                        {getValidationIcon(formValues.password.length >= 12)}
                        <Typography type={TypographyTypes.label}>
                          At least 12 characters
                        </Typography>
                      </div>
                      <div className={style.validation}>
                        {getValidationIcon(
                          /^(?=.*[A-Z])/.test(formValues.password)
                        )}
                        <Typography type={TypographyTypes.label}>
                          1 UPPERCASE letter
                        </Typography>
                      </div>
                      <div className={style.validation}>
                        {getValidationIcon(
                          /^(?=.*[a-z])/.test(formValues.password)
                        )}
                        <Typography type={TypographyTypes.label}>
                          1 lowercase letter
                        </Typography>
                      </div>
                      <div className={style.validation}>
                        {getValidationIcon(
                          /^(?=.*[0-9])/.test(formValues.password)
                        )}
                        <Typography type={TypographyTypes.label}>
                          1 number
                        </Typography>
                      </div>
                      <div className={style.validation}>
                        {getValidationIcon(
                          /^(?=.*[!@$%^&*+#])/.test(formValues.password)
                        )}
                        <Typography type={TypographyTypes.label}>
                          1 special character (!@$%^&*+#){' '}
                        </Typography>
                      </div>
                    </div>
                  )}
                <div className={style.checkbox}>
                  <input
                    type="checkbox"
                    checked={formValues.isAgreed}
                    onChange={onFormValueChanged('isAgreed')}
                  />
                  <Typography
                    type={TypographyTypes.body}
                    size={TypographySizes.small}
                    version={Version.v2}
                  >
                    {termsAndConditions}
                  </Typography>
                </div>
              </div>
              {isFormValid && !captchaValue && (
                <ReCAPTCHA
                  sitekey={config.reCaptchaSiteKey}
                  onChange={(value) => {
                    if (value) setTimeout(() => setCaptchaValue(value), 500)
                  }}
                />
              )}
              <Button
                text="Create account"
                onClick={onSubmit}
                disabled={!(isFormValid && captchaValue)}
              />
              <div className={style.login}>
                <Typography type={TypographyTypes.body} version={Version.v2}>
                  Already have an account?
                </Typography>
                <Typography
                  type={TypographyTypes.body}
                  styles={TypographyStyles.emphasis}
                  version={Version.v2}
                >
                  <a href={config.nutronUrl} className={style.link}>
                    Log in
                  </a>
                </Typography>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default RegistrationDialog
