import React, { useEffect, useState, useRef, memo } from 'react'
import { useMetadataStore } from '../../stores/MetadataStore'
import {
  Gnss,
  YAAKMetadata,
  ROBOTICS_SCHEMA_NAME_MAPPING,
  RoboticsMetadata,
  RoboticsObservationRGBPrimary,
  YAAK_SCHEMA_NAME_MAPPING,
} from '../../utils/protobufParse'
import { usePlayerStore } from '../../stores/PlayerStore'
import { useShallow } from 'zustand/react/shallow'
import { useVideosStore } from '../../stores/VideosStore'
import { getOffset } from '@yaak/nutron/src/utils/player'
import { IPanel, useMosaicStore } from '@yaak/nutron/src/stores/MosaicStore'

const merge = (...objs: any) =>
  [...objs].reduce(
    (acc, obj) =>
      Object.keys(obj).reduce((a, k) => {
        acc[k] = Object.hasOwn(acc, k)
          ? [].concat(acc[k]).concat(obj[k])
          : obj[k]
        return acc
      }, {}),
    {}
  )

const getTopicsFromPanels = (panels: IPanel[]): string[] => {
  const finalTopics: string[] = []
  panels.forEach(({ properties: { topics } }) => {
    topics.forEach((topic) => {
      if (!finalTopics.includes(topic)) finalTopics.push(topic)
    })
  })

  return finalTopics
}

interface MetadataLoaderProps {
  sessionId?: string
}

const MetadataLoader: React.FunctionComponent<MetadataLoaderProps> = ({
  sessionId,
}) => {
  const [metadata, setMetadata] = useState<YAAKMetadata>({})
  const { url, updateMetadata, updateSeconds, updateMetadataLoadingFinished } =
    useMetadataStore(
      useShallow((state) => ({
        url: state.url,
        type: state.type,
        updateMetadata: state.updateMetadata,
        updateSeconds: state.updateSeconds,
        updateMetadataLoadingFinished: state.updateMetadataLoadingFinished,
      }))
    )
  const [finished, setFinished] = useState<boolean>(false)

  const { begin, context, end } = usePlayerStore(
    useShallow((state) => ({
      begin: state.begin,
      end: state.end,
      context: state.context,
    }))
  )
  const { session } = useVideosStore(
    useShallow((state) => ({
      session: state.session,
    }))
  )
  const { configPanels } = useMosaicStore(
    useShallow((state) => ({
      configPanels: state.configPanels,
    }))
  )
  const workerRef = useRef<Worker>()
  const [worker, setWorker] = useState<Worker | null>(null)

  useEffect(() => {
    if (!workerRef.current && sessionId && url[sessionId]) {
      updateMetadataLoadingFinished(sessionId, false)
      const newWorker: Worker = new Worker(
        new URL('./../../utils/worker', import.meta.url)
      )
      setWorker(newWorker)
      workerRef.current = newWorker
    }

    return () => {
      const worker = workerRef.current
      if (worker) {
        worker.terminate()
      }
      workerRef.current = undefined
    }
  }, [url, workerRef, sessionId])

  useEffect(() => {
    if (
      sessionId &&
      worker &&
      url[sessionId] &&
      configPanels &&
      session.startTimestamp
    ) {
      const topics = getTopicsFromPanels(configPanels)

      worker.postMessage({
        url: url[sessionId],
        begin:
          new Date(session.startTimestamp).getTime() / 1000 +
          getOffset(begin, context),
        end: end
          ? new Date(session.startTimestamp).getTime() / 1000 + end + context
          : new Date(session.endTimestamp).getTime() / 1000,
        ...(topics.length > 0 ? { topics } : {}),
      })
    }
  }, [worker, url, begin, end, context, configPanels, session, sessionId])

  useEffect(() => {
    if (worker) {
      worker.onmessage = function (event) {
        if (event.data.log) {
          setMetadata((prevMetadata) => {
            return merge(prevMetadata, event.data.log)
          })
        }

        if (event.data.finished) {
          setFinished(true)
        }
      }
    }
  }, [begin, worker, session])

  useEffect(() => {
    if (finished && sessionId) {
      updateMetadataLoadingFinished(sessionId, true)
      const worker = workerRef.current
      if (worker) {
        worker.terminate()
      }
      workerRef.current = undefined
    }
  }, [finished, workerRef, sessionId])

  const updateSecondsData = (
    data: Gnss[] | RoboticsObservationRGBPrimary[]
  ) => {
    if (data) {
      const seconds = data.map(
        (d) =>
          (
            (d as Gnss).time_stamp ||
            (d as RoboticsObservationRGBPrimary).timestamp
          ).seconds
      )
      updateSeconds(seconds)
    }
  }

  useEffect(() => {
    if (metadata && sessionId) {
      updateMetadata(sessionId, metadata)
      const gnss = metadata[YAAK_SCHEMA_NAME_MAPPING.gnss] as Gnss[]
      const images = (metadata as RoboticsMetadata)[
        ROBOTICS_SCHEMA_NAME_MAPPING[
          'observation/rgb/primary'
        ] as keyof RoboticsMetadata
      ] as RoboticsObservationRGBPrimary[]
      updateSecondsData(gnss || images)
    }
  }, [sessionId, metadata])

  return <></>
}

export default memo(MetadataLoader)
