import React, { ChangeEvent, memo, useCallback, useMemo, useState } from 'react'
import style from './style.less'
import Icon from '../Icon/Icon'
import { Version } from '../types'
import { Popover, PopoverVirtualElement } from '@mui/material'
import { NestedMenuItem } from 'mui-nested-menu'
import MenuItem from '@mui/material/MenuItem'
import Typography, { TypographySizes, TypographyTypes } from '../Typography'
import { FILTER_CATEGORY_MAPPING } from './consts'
import { useTagsStore } from '../stores/Tags'
import { useShallow } from 'zustand/react/shallow'
import FilterSearch from './FilterSearch'
import classNames from 'classnames'
import { useDatasetStore } from '@yaak/nutron/src/stores/DatasetStore'
import { SearchFilter } from '../../services/api/api'

interface FilterMenuItemProps {
  text: string
  selected: boolean
  numberOfItems?: number
}

const FilterMenuItem = ({
  text,
  selected,
  numberOfItems,
}: FilterMenuItemProps) => {
  return (
    <div className={style.filterMenuItem}>
      <Typography
        className={style.filterMenuItemText}
        version={Version.v2}
        type={TypographyTypes.label}
      >
        {text}
      </Typography>
      <div className={style.filterMenuItemRight}>
        {selected && <span className={style.filterMenuItemDot}></span>}
        <Typography
          className={style.filterMenuItemText}
          version={Version.v2}
          type={TypographyTypes.label}
        >
          {numberOfItems}
        </Typography>
      </div>
    </div>
  )
}

interface ISubmenuProps {
  subMenuKey: string
  subMenuItems: SearchFilter[]
  open: boolean
  isSelected: (text: string) => boolean
  onClick: (tag: SearchFilter) => any
  numberOfItems?: number
}

const SubMenu = ({
  subMenuKey,
  subMenuItems,
  open,
  isSelected,
  onClick,
  numberOfItems,
}: ISubmenuProps) => (
  <NestedMenuItem
    leftIcon={
      <Icon
        name={FILTER_CATEGORY_MAPPING[subMenuKey]?.icon}
        version={Version.v2}
      />
    }
    key={subMenuKey}
    label={
      (
        <FilterMenuItem
          text={FILTER_CATEGORY_MAPPING[subMenuKey]?.displayName || subMenuKey}
          selected={isSelected(
            FILTER_CATEGORY_MAPPING[subMenuKey]?.displayName || subMenuKey
          )}
          numberOfItems={numberOfItems}
        />
      ) as any
    }
    className={classNames(
      !FILTER_CATEGORY_MAPPING[subMenuKey] ? style.subMenu : undefined
    )}
    parentMenuOpen={open}
  >
    {subMenuItems?.map((item) => (
      <MenuItem
        onClick={() => {
          onClick(item)
        }}
        key={item.name}
      >
        <FilterMenuItem
          text={item.displayName}
          selected={isSelected(item.name)}
        />
      </MenuItem>
    ))}
  </NestedMenuItem>
)

interface FilterMenuProps {
  onClick: (tag: SearchFilter) => any
  onClearFilters: () => void
  showClearAction: boolean
}

const FilterMenu = ({
  showClearAction,
  onClick,
  onClearFilters,
}: FilterMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | null
    | undefined
  >(null)
  const open = Boolean(anchorEl)
  const [search, setSearch] = useState<string>('')
  const { tags } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
    }))
  )

  const { dataset } = useDatasetStore(
    useShallow((state) => ({
      dataset: state.dataset,
    }))
  )

  const onIconClick = (e: MouseEvent) => setAnchorEl(e.currentTarget as Element)

  const onClose = () => {
    setAnchorEl(null)
    setSearch('')
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value || '')
  }

  const isSelected = useCallback(
    (filter: string) =>
      !!tags?.find(
        (tag) =>
          tag.name === filter ||
          (tag.category
            ? FILTER_CATEGORY_MAPPING[
                tag.category
              ].displayName.toLowerCase() === filter.toLowerCase()
            : filter === 'Other')
      ),
    [tags]
  )

  const categories: Record<string, SearchFilter[]> | undefined = useMemo(() => {
    if (dataset) {
      const categoriesData = dataset.searchFilters.reduce((acc, filter) => {
        const category = filter.category || 'other'

        if (!acc[category]) {
          acc[category] = [] as SearchFilter[]
        }
        acc[category].push(filter)
        return acc
      }, {} as Record<string, SearchFilter[]>)

      const sortedCategories = Object.keys(categoriesData)
        .sort()
        .reduce((acc, key) => {
          acc[key] = categoriesData[key]
          return acc
        }, {} as Record<string, SearchFilter[]>)

      const { other, ...rest } = sortedCategories

      return {
        ...rest,
        other,
      }
    }
  }, [dataset])

  const filteredItems: Record<string, SearchFilter[]> = useMemo(
    () =>
      Object.entries(categories || {}).reduce((acc, [category, items]) => {
        const filtered = items?.filter((item) =>
          item.displayName.toLowerCase().includes(search.toLowerCase())
        )

        if (search && filtered.length > 0) {
          return {
            ...acc,
            [FILTER_CATEGORY_MAPPING[category].displayName || category]:
              filtered,
          }
        }
        return acc
      }, {}),
    [search, categories]
  )

  return (
    <div>
      <button
        className={classNames(
          style.filterButton,
          open ? style.filterButtonOpen : undefined
        )}
      >
        <Icon name={'Filter'} version={Version.v2} onClick={onIconClick} />
      </button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{ '& .MuiPopover-paper': { padding: '8px 0' } }}
        className={style.filterMenu}
      >
        <FilterSearch value={search} onChange={handleSearchChange} />
        {!search &&
          categories &&
          Object.keys(categories).map((key) => (
            <SubMenu
              key={key}
              subMenuKey={key}
              subMenuItems={categories[key]?.sort((a, b) =>
                a.displayName > b.displayName ? 1 : -1
              )}
              open={open}
              isSelected={isSelected}
              onClick={(item) => {
                onClick(item)
                onClose()
              }}
              numberOfItems={categories[key]?.length}
            />
          ))}
        {search && (
          <div className={style.filteredContainer}>
            {Object.entries(filteredItems).map(([category, items]) => (
              <>
                <Typography
                  type={TypographyTypes.label}
                  size={TypographySizes.small}
                  className={style.category}
                >
                  {category}
                </Typography>
                {items.map((item) => (
                  <MenuItem
                    key={item.name}
                    onClick={() => {
                      onClick(item)
                      onClose()
                    }}
                  >
                    <FilterMenuItem
                      text={item.displayName}
                      selected={isSelected(item.displayName)}
                    />
                  </MenuItem>
                ))}
              </>
            ))}
          </div>
        )}
        {showClearAction && (
          <div className={style.clearButtonContainer}>
            <button className={style.clearButton} onClick={onClearFilters}>
              <Typography type={TypographyTypes.label}>
                Clear filters
              </Typography>
            </button>
          </div>
        )}
      </Popover>
    </div>
  )
}

export default memo(FilterMenu)
