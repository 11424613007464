import React, { ReactNode } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@yaak/components/src/Button'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '../Typography/Typography'
import Icon from '../Icon'
import style from './style.less'
import { Version } from '../types'

interface WarningDialogProps {
  buttons: {
    cancel?: string | React.ReactNode
    submit?: string | React.ReactNode
  }
  classNames?: {
    dialogContentText: string
    dialogContentText2: string
  }
  dialogTitle: string | React.ReactNode
  dialogContentText?: string | React.ReactNode
  dialogContentText2?: string | React.ReactNode
  icon?: ReactNode
  isOpen: boolean
  onCancel?: (event?: any) => void
  onSubmit: (event?: React.MouseEvent<HTMLElement>) => void
  showAgainOption?: string
}

const WarningDialog: React.FunctionComponent<WarningDialogProps> = ({
  isOpen,
  onCancel,
  onSubmit,
  classNames,
  dialogContentText,
  dialogContentText2,
  dialogTitle,
  buttons,
  icon,
  showAgainOption,
}) => {
  const onSubmitClick = async (event: React.MouseEvent<HTMLElement>) => {
    await onSubmit(event)
  }

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={onCancel}
      open={isOpen}
      classes={{ paper: style.dialog }}
    >
      <div>
        <DialogTitle>
          <div>
            {icon === undefined ? (
              <Icon className={style.warningIcon} name={'Warning'} />
            ) : (
              icon
            )}
          </div>
          <br />
          <Typography type={TypographyTypes.title} size={TypographySizes.large}>
            {dialogTitle}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classNames?.dialogContentText}>
            <Typography type={TypographyTypes.body}>
              {dialogContentText}
            </Typography>
          </DialogContentText>
          {dialogContentText2 && (
            <DialogContentText className={classNames?.dialogContentText2}>
              {dialogContentText2}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions className={style.dialogActions}>
          {showAgainOption && (
            <div className={style.row}>
              <input
                type="checkbox"
                onChange={(event) => {
                  const { checked } = event.target
                  checked
                    ? localStorage.setItem(showAgainOption, 'true')
                    : localStorage.removeItem(showAgainOption)
                }}
              />
              <Typography version={Version.v2} type={TypographyTypes.body}>
                Don’t show again
              </Typography>
            </div>
          )}
          <div className={style.row}>
            {onCancel && (
              <Button onClick={onCancel} text={buttons.cancel} secondary />
            )}
            {buttons.submit && (
              <Button onClick={onSubmitClick} text={buttons.submit} />
            )}
          </div>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default WarningDialog
