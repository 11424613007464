import React, { useEffect, useRef, useState } from 'react'
import Popover from '@yaak/components/src/Popover'
import Divider from '@yaak/components/src/Divider'
import Typography from '@yaak/components/src/Typography'
import { TypographyTypes } from '@yaak/components/src/Typography/Typography'
import { Version } from '@yaak/components/src/types'
import style from './style.less'
import { statusDataType } from '../../helpers/drives'
import { FormattedMessage } from 'react-intl'

interface DataStatusProps {
  icon: React.ReactNode
  data: statusDataType
  type: number
  dsAdmin: boolean
  version?: Version
}

const getLabel = (value?: number) =>
  !value
    ? 'Not started'
    : value === 3
    ? 'Complete'
    : value === 4
    ? 'Error'
    : value === 2
    ? 'In progress'
    : value === 0
    ? 'Uploading'
    : ''

const getClassName = (value?: number) =>
  !value
    ? style.valueNotStarted
    : value === 3
    ? style.valueComplete
    : value === 4
    ? style.valueError
    : value === 2
    ? style.valueInProgress
    : value === 0
    ? style.valueUploading
    : undefined

const getClassNameIcon = (value?: number) =>
  !value
    ? style.valueNotStartedIcon
    : value === 3
    ? style.valueCompleteIcon
    : value === 4
    ? style.valueErrorIcon
    : value === 2
    ? style.valueInProgressIcon
    : value === 0
    ? style.valueUploadingIcon
    : undefined

const getPopoverClassName = (value: number) =>
  value === 0
    ? style.popoverNotStarted
    : value === 3
    ? style.popoverCompleted
    : value === 4
    ? style.popoverError
    : value === 2
    ? style.popoverInProgress
    : undefined

const DataStatus: React.FunctionComponent<DataStatusProps> = ({
  icon,
  data,
  type,
  dsAdmin,
  version,
}) => {
  const [open, setOpen] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const gridContainer = document.getElementById('gridContainer')
  const iconWrapper = useRef<HTMLDivElement>(null)

  const onScrollFun = () => {
    setOpen(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScrollFun, true)
    return () => window.removeEventListener('scroll', onScrollFun)
  }, [])

  useEffect(() => {
    if (isHovering) {
      setOpen(true)
    } else if (gridContainer) {
      setOpen(false)
    }
  }, [isHovering])

  return (
    <div
      ref={iconWrapper}
      onMouseLeave={(event) => {
        event.preventDefault()
        event.stopPropagation()
        if (event.currentTarget === iconWrapper.current) {
          setIsHovering(false)
        }
      }}
      onMouseEnter={(event) => {
        event.preventDefault()
        event.stopPropagation()
        if (event.currentTarget === iconWrapper.current) {
          setIsHovering(true)
        }
      }}
    >
      <div className={getClassNameIcon(type)}>{icon}</div>
      <div className={style.popoverWrapper}>
        <Popover
          position={'left'}
          className={getPopoverClassName(type)}
          header={
            dsAdmin ? (
              <FormattedMessage id={'data_processing'} />
            ) : (
              'Data processing'
            )
          }
          content={
            <div className={style.dataStatus}>
              <div className={style.row}>
                <Typography
                  type={TypographyTypes.label}
                  className={style.title}
                  version={version}
                >
                  {dsAdmin ? <FormattedMessage id={'map_data'} /> : 'Metadata'}
                </Typography>
                <Typography
                  type={TypographyTypes.label}
                  className={getClassName(data.metadataStatus)}
                  version={version}
                >
                  {getLabel(data.metadataStatus)}
                </Typography>
              </div>
              <Divider />
              <div className={style.row}>
                <Typography
                  type={TypographyTypes.label}
                  className={style.title}
                  version={version}
                >
                  {dsAdmin ? <FormattedMessage id={'video'} /> : 'Raw video'}
                </Typography>
                <Typography
                  type={TypographyTypes.label}
                  className={getClassName(data.fullVideoStatus)}
                  version={version}
                >
                  {getLabel(data.fullVideoStatus)}
                </Typography>
              </div>
              <Divider />
              <div className={style.row}>
                <Typography
                  type={TypographyTypes.label}
                  className={style.title}
                  version={version}
                >
                  {dsAdmin ? (
                    <FormattedMessage id={'360_video'} />
                  ) : (
                    '360° video'
                  )}
                </Typography>
                <Typography
                  type={TypographyTypes.label}
                  className={getClassName(data.videoStatus)}
                  version={version}
                >
                  {getLabel(data.videoStatus)}
                </Typography>
              </div>
            </div>
          }
          open={open}
        />
      </div>
    </div>
  )
}

export default DataStatus
