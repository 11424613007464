import React, { memo, useEffect, useRef, useState } from 'react'
import style from './style.less'
import Badge from '../Badge'
import { BadgeType } from '../Badge/Badge'
import Icon from '../Icon/Icon'
import { Version } from '../types'
import FilterMenuTag from '../FilterMenu/FilterMenuTag'
import { useClickOutside } from '../../customHooks/useClickOutside'
import { getTagValue, Tag, useTagsStore } from '../stores/Tags'
import { useShallow } from 'zustand/react/shallow'
import {
  OPERATORS_SHORT_SYMBOL_MAPPING,
  OPERATORS_URL_MAPPING,
  RANGE_OPERATOR,
} from './utils'

export interface SearchQueryTagProps {
  tagKey: string
}

const SearchQueryTag = ({ tagKey }: SearchQueryTagProps) => {
  const tagRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement | null>(null)
  const { tags, update } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
      update: state.update,
    }))
  )
  const [tag, setTag] = useState<Tag | null>()
  const [open, setOpen] = useState(false)
  const [badgeLabel, setBadgeLabel] = useState('')
  const [createdRef, setCreatedRef] = useState<HTMLDivElement | null>(null)
  const [operatorsRef, setOperatorsRef] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    tag && setOpen(tag.type === 'bool' || !getTagValue(tag))
  }, [tag])

  useEffect(() => {
    setTag(tags?.filter((tag) => tag.name === tagKey)[0])
  }, [tagKey, tags])

  useEffect(() => {
    ref && setCreatedRef(ref as any)
  }, [ref])

  useClickOutside(
    tagRef,
    (props) => {
      const value = getTagValue(props.tag)
      setOpen(false)
      if (!value) {
        props.tags &&
          props.update(props.tags.filter((t: Tag) => t.name !== props.tagKey))
      }
    },
    { tags, update, tagKey, tag },
    operatorsRef
  )

  useEffect(() => {
    if (tag) {
      let tagValue = tag.tagValue.replace(
        tag.displayName,
        `${tag.displayName}:`
      )
      if (tag.tagOperator === RANGE_OPERATOR) {
        const value = getTagValue(tag)
        const [min, max] = value.split('-')
        tagValue = tagValue
          .replace(OPERATORS_URL_MAPPING[tag.tagOperator], '')
          .replace(value, `(${min || ''},${max || ''})`)
      } else {
        tagValue = tagValue.replace(
          OPERATORS_URL_MAPPING[tag.tagOperator],
          OPERATORS_SHORT_SYMBOL_MAPPING[tag.tagOperator]
        )
      }
      const valuesLength = getTagValue(tag).split(',').length
      setBadgeLabel(
        valuesLength >= 3
          ? tagValue.replace(getTagValue(tag), `${valuesLength} values`)
          : tagValue
      )
    }
  }, [tag?.tagValue])

  return (
    tag && (
      <div
        className={style.tagContainer}
        ref={tagRef}
        onClick={() => {
          setOpen(true)
        }}
      >
        <Badge
          ref={ref}
          key={tagKey}
          type={BadgeType.grey}
          label={badgeLabel}
          icon={
            <Icon
              name={
                tag.type === 'string'
                  ? 'Text'
                  : tag.type === 'bool'
                  ? 'Boolean'
                  : 'Number'
              }
              version={Version.v2}
              className={style.hashIcon}
            />
          }
        />
        <FilterMenuTag
          tagKey={tagKey}
          open={open}
          ref={createdRef as any}
          setOperatorsRef={setOperatorsRef}
        />
      </div>
    )
  )
}

export default memo(SearchQueryTag)
