import config from './config'
import { Dispatch, SetStateAction } from 'react'
import { toastType, ToastTypes } from '@yaak/components/src/Toast/Toast'

export type ExamResult = {
  passed: boolean
  timestamp: string
}

export type MainContact = {
  id: string
  firstName: string
  lastName: string
  profileImageURL: string
  mainContactId: string
}

export type Partner = {
  id: string
  name: string
  language: string | null
  mainContact: MainContact | null
  mainContactId: string | null
  simEnabled: boolean
}

export type CreatePartner = {
  name: string
  language: string | null
}

export type User = {
  id: string
  firstName: string
  lastName: string
  email: string | null
  phoneNumber?: string
  roles?: string[]
  partnerId: string
  partner?: Partner
  active: boolean
  preferredLanguage?: string
  profileImageURL: string
  tosAccepted?: boolean
  rating: number | null
  durationMinutes?: number
  examResults?: ExamResult[]
  simEnabled: boolean
  driveCount?: number
  currentMonthExpertMinutes?: number
}

export type InstructorStats = {
  currentMonthExpertMins: number
  id: string
  students: User[]
  totalExpertMins: number
}

export type CreateUser = {
  firstName: string
  lastName: string
  email: string
  roles: string[]
  partnerId: string | null
  phoneNumber?: string | null
  preferredLanguage: string | null
  sendEmail: boolean
}

export enum KitPowerState {
  TurnedOn = 'turned_on',
  TurningOn = 'turning_on',
  TurnedOff = 'turned_off',
  TurningOff = 'turning_off',
  Error = 'error',
}

export type Device = {
  dongleId: string
  status: string
  partnerId: string | null
  kitType: string | null
  licensePlate: string | null
  ipAddress: string
  active: boolean
  lastStatusUpdate: string
  mqttEnabled?: boolean
  modemStatus?: {
    uptime_ms: number
    state: KitPowerState
    error?: string
    voltages: {
      battery: number
      v12: number
    }
    ublox: {
      rsrq: number
      rsrp: number
    }
  }
  pairedVehicleVIN: string
}

export type Driver = {
  firstname: string
  id: string
  lastname: string
  profileImageURL: string
}

export type SessionData = {
  active: boolean
  bitRateKbps?: number
  bookmarkCount: number
  canonicalName: string
  curriculumCount: number
  dongleId: string
  driveType: number
  driver: Driver | null
  driverDriveCount: number
  endTimestamp: string | null
  id: string
  incidentCount: number
  instructor: Driver | null
  licensePlate?: string
  vin?: string
  location: {
    street: string | null
    area: string | null
    address: string | null
  }
  metadataStatus: number
  partner: Partner | null
  startTimestamp: string
  status: number
  videoStatus: number
  fullVideoStatus: number
  lessonTopics: Array<string>
  rating: number | null
  weatherConditions: string[]
  lightningConditions: string[]
}

export type Session = {
  data: SessionData[]
  metaData: {
    count: number
    totalCount: number
  }
}

export type SearchScenario = {
  startOffset: number
  endOffset: number
}

export type SearchSessionData = {
  endTimestamp: string | null
  lightningConditions: string[]
  location: {
    street: string | null
    area: string | null
    address: string | null
  }
  safetyScore: number
  scenarioCount: number
  startTimestamp: string
  scenarios: SearchScenario[]
  sessionCanonicalName: string
  sessionDongleId: string
  sessionId: string
  sessionType: string
  sessionTypeInt: number
  weatherConditions: string[]
}

export type SearchSession = {
  data: SearchSessionData[]
  metaData: {
    count: number
    totalCount: number
  }
}

export type PairingRequest = {
  id: string
  vin: string
  kitId: string
  grantedBy?: User
  pairedAt?: string
  status: string
  calibrationAvailable: boolean
}

export type CreatePairingRequest = {
  vin: string
  kitId: string
}

export type CreateVehicle = {
  licensePlate: string | null
  partnerID: string | null
  vin: string | null
  friendlyName?: string
}

export type Vehicle = {
  calibrationAvailable?: boolean
  kit?: {
    id: string
    status: string
  }
  licensePlate: string
  partner: Partner | null
  vin: string
  friendlyName: string
}

export type VehicleResponse = {
  data: Vehicle[]
  metaData: {
    count: number
    totalCount: number
  }
}

type alertMessagesType = {
  success?: {
    text?: string
    textId?: string
  }
  error?: { text: string }
}

export type UsersResponse = {
  data: User[]
  metaData: {
    count: number
    totalCount: number
  }
}

export type PairingResponse = {
  data: PairingRequest[]
  metaData: {
    count: number
    totalCount: number
  }
}

type RatingRecord = {
  timestamp: string
  durationMinutes: number
  rating: number | null
}

type RatingSummary = {
  rating: number
  durationMinutes: number
  ratingRecords: RatingRecord[]
}

export type LessonTopic = {
  name: string
  category: string
  mandatoryDrivingMinutes: number
  restrictedCurriculumItemSubset: string[] | null
  restrictedEventTagIdSubset: string[] | null
}

export type LessonTopics = {
  data: LessonTopic[]
  metaData: {
    count: number
    totalCount: number
  }
}

export type UserStats = {
  userId: string
  rating: number
  durationMinutes: number
  ratingRecords: RatingRecord[]
  lessonTopicRatings: { [id: string]: RatingSummary }
  lessonTopicCategoryRatings: { [id: string]: RatingSummary }
}

export type PartnerStats = {
  activeInstructors: number
  activeStudents: number
  expertDriveMinutes: number
  studentDriveMinutes: number
}

export type SimRigsData = {
  id: string
  name: string
  partner: Partner | null
  status: string
}

export type SimRigs = {
  data: SimRigsData[]
  metaData: {
    count: number
    totalCount: number
  }
}

export type SimulationDrive = {
  endTimestamp: string
  id: string
  module: {
    id: string
    header: string
    title: string
    description: string
    type: string
  }
  partner: {
    id: string
    name: string
  } | null
  rating: number
  result: string
  simDeviceId: string
  simDeviceName: string
  startTimestamp: string
  status?: string
  user: Driver
}

export type SimulationDrives = {
  data: SimulationDrive[]
  metaData: {
    count: number
    totalCount: number
  }
}

export type VideoStream = {
  cameraPosition: string
  id: string
  sessionId: string
  url: string
}

export type VideoStreams = {
  data: VideoStream[]
  metaData: {
    count: number
    totalCount: number
  }
}

export type Camera = {
  name: string
  url: string
}

type GeoCollection = {
  type: string
  features: any
}

export type SessionInfo = {
  cameras: Camera[]
  canonicalName: string
  dongleId: string
  endTimestamp: string
  geoCollection: GeoCollection
  id: string
  metadata: string
  metadataMCAP: string
  startTimestamp: string
}

export type SessionTimestamp = {
  name: string
  startTimestamp: string
  endTimestamp: string
  offsetURLStartTimestamp?: string
  offsetURLEndTimestamp?: string
}

export type IncidentOutcome = {
  id: string
  label: string
  slug: string
}

export type IncidentOutcomes = {
  data: IncidentOutcome[]
  metaData: {
    count: number
    totalCount: number
  }
}

export type DriveMetric = {
  driveType: string
  duration: number
}

export type IncidentMetric = {
  tag: string
  count: number
}

export type LanesMetric = {
  lanes: string
  duration: number
}

export type LightningMetric = {
  condition: string
  duration: number
}

export type MapFeaturesMetric = {
  feature: string
  count: number
}

export type RoadTypeMetric = {
  type: string
  distance: number
}

export type SpeedHistogram = {
  bucket: number
  duration: number
  maxSpeed: number
  minSpeed: number
}

export type WeatherMetric = {
  condition: string
  duration: number
}

export type MetricsData = {
  avgDriveDurationMetric: DriveMetric[]
  drivingMetric: DriveMetric[]
  incidentMetric: IncidentMetric[]
  lanesMetric: LanesMetric[]
  lightningMetric: LightningMetric[]
  mapFeaturesMetric: MapFeaturesMetric[]
  roadTypeMetric: RoadTypeMetric[]
  speedHistogram: SpeedHistogram[]
  weatherMetric: WeatherMetric[]
}

export type Event = {
  curriculumItems: string[]
  endOffset: number
  endTimestamp: string
  id: string
  sessionId: string
  shareWatched: boolean
  shared: boolean
  startOffset: number
  startTimestamp: string
  tag: string
  videoThumbnailUrl: string
  videoUrl: string
}

export type EventsData = {
  data: Event[]
  metadata: {
    count: number
    totalCount: number
  }
}

export type Scenario = {
  endOffset: number
  sessionCanonicalName: string
  sessionDongleId: string
  sessionId: string
  sessionType: string
  sessionTypeInt: number
  startOffset: number
  lightingConditions: string[]
  location: { street: null; area: null; address: null }
  safetyScore: number
  startTimestamp: string
  weatherConditions: string[]
}

export type ScenarioData = {
  data: Scenario[]
  metaData: {
    count: number
    totalCount: number
  }
}

export type SavedQuery = {
  conditions: { query: any; filters: any }
  id: string
  name: string
}

export type SavedQueryData = {
  data: SavedQuery[]
  metaData: {
    count: number
    totalCount: number
  }
}

const processResponse = async ({
  response,
  onAlert,
  messages,
  responseText,
}: {
  response: Response
  onAlert: Dispatch<SetStateAction<toastType | null>>
  messages?: alertMessagesType
  responseText?: boolean
}) => {
  if (
    response.status === 200 ||
    response.status === 201 ||
    response.status === 202 ||
    response.status === 204
  ) {
    messages?.success &&
      onAlert({
        text: messages.success.text,
        type: messages.success ? ToastTypes.success : ToastTypes.error,
      })
    return responseText ? response?.text() : response?.json?.()
  } else {
    const text = await response.text()
    try {
      const parsed = JSON.parse(text)
      onAlert({
        text: `${parsed.code}: ${parsed.message}`,
        type: ToastTypes.error,
      })
      console.warn(parsed.code, parsed.message)
    } catch (e) {
      onAlert({
        text: `${response.status}: ${response.statusText}`,
        type: ToastTypes.error,
      })
      console.warn(response.status, response.statusText)
    }
  }
}

type QueriesParam = {
  [key: string]: boolean | number | string | string[] | null | undefined
}

const parseQueryStrings = (queries: QueriesParam) =>
  Object.keys(queries)
    .reduce(
      (pre: string[], curr: string) =>
        (queries[curr] as keyof QueriesParam) !== undefined
          ? pre.concat(`${curr}=${queries[curr]}`)
          : pre,
      []
    )
    .join('&')

const getSessionById = async ({
  token,
  id,
  onAlert,
}: {
  token: string
  id: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<SessionData> => {
  return fetch(`${config.apiUrl}/yapi/v2/sessions/${id}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getLoggedInUser = async ({
  token,
  onAlert,
}: {
  token: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<User> => {
  return await fetch(`${config.apiUrl}/yapi/v1/users/me`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getUsers = async ({
  token,
  onAlert,
  roles,
  limit,
  offset,
  partnerId,
  withExpertStats = false,
  sortBy = 'firstName',
  sortOrder = 'DESC',
  searchQuery,
}: {
  token: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
  roles?: string[]
  partnerId?: string
  withExpertStats?: boolean
  offset?: number
  sortBy?: string
  sortOrder?: string
  searchQuery?: string
  limit?: number
}): Promise<UsersResponse> => {
  const queryStrings = parseQueryStrings({
    limit,
    role: roles,
    offset,
    sort_by: sortBy,
    sort_order: sortOrder,
    partnerId,
    expertStats: withExpertStats,
    q: searchQuery,
  })

  return await fetch(`${config.apiUrl}/yapi/v2/users?${queryStrings}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getPartnerStats = async ({
  token,
  id,
  onAlert,
}: {
  token: string
  id: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<{ [key: string]: PartnerStats }> => {
  return fetch(`${config.apiUrl}/yapi/v1/partners/${id}/stats`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getInstructorData = async ({
  token,
  instructorID,
  partnerID,
  onAlert,
}: {
  token: string
  instructorID?: string
  partnerID?: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<InstructorStats> => {
  return fetch(
    `${config.apiUrl}/yapi/v1/partners/${partnerID}/instructors/${instructorID}`,
    {
      credentials: 'same-origin',
      headers: { authorization: `Bearer ${token}` },
      method: 'GET',
    }
  )
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getSuccessMessage = (user: CreateUser, intl: any) =>
  user.roles.indexOf('ds-admin') !== -1
    ? intl.formatMessage(
        {
          id: 'add_admin_success',
        },
        {
          name: `${user.firstName} ${user.lastName}`,
        }
      )
    : user.roles.indexOf('ds-instructor') !== -1
    ? intl.formatMessage(
        {
          id: 'add_instructor_success',
        },
        {
          name: `${user.firstName} ${user.lastName}`,
        }
      )
    : intl.formatMessage(
        {
          id: 'add_student_success',
        },
        {
          name: `${user.firstName} ${user.lastName}`,
        }
      )

const getDeleteUserMessage = (user: User, intl: any) =>
  user.roles?.indexOf('ds-admin') !== -1
    ? intl.formatMessage(
        {
          id: 'delete_admin_success',
        },
        {
          name: `${user.firstName} ${user.lastName}`,
        }
      )
    : user.roles.indexOf('ds-instructor') !== -1
    ? intl.formatMessage(
        {
          id: 'delete_instructor_success',
        },
        {
          name: `${user.firstName} ${user.lastName}`,
        }
      )
    : intl.formatMessage(
        {
          id: 'delete_student_success',
        },
        {
          name: `${user.firstName} ${user.lastName}`,
        }
      )

const getUpdateUserMessage = (user: User, intl: any) =>
  user.roles?.indexOf('ds-admin') !== -1
    ? intl.formatMessage(
        {
          id: 'update_admin_success',
        },
        {
          name: `${user.firstName} ${user.lastName}`,
        }
      )
    : user.roles.indexOf('ds-instructor') !== -1
    ? intl.formatMessage(
        {
          id: 'update_instructor_success',
        },
        {
          name: `${user.firstName} ${user.lastName}`,
        }
      )
    : intl.formatMessage(
        {
          id: 'update_student_success',
        },
        {
          name: `${user.firstName} ${user.lastName}`,
        }
      )

const getCreateUserNotification = (user: CreateUser) =>
  user.roles.indexOf('ds-admin') !== -1
    ? `DS Admin ${user.firstName} ${user.lastName} added successfully`
    : user.roles.indexOf('ds-student') !== -1
    ? `Student ${user.firstName} ${user.lastName} added successfully`
    : user.roles.indexOf('ds-instructor') !== -1
    ? `Instructor ${user.firstName} ${user.lastName} added successfully`
    : 'User added successfully'

const createUser = async ({
  token,
  user,
  onAlert,
  intl,
}: {
  token: string
  user: CreateUser
  onAlert: Dispatch<SetStateAction<toastType | null>>
  intl?: any
}): Promise<User[]> => {
  return fetch(
    `${config.apiUrl}/yapi/v1/users/invite?email=${user.sendEmail || false}`,
    {
      credentials: 'same-origin',
      headers: { authorization: `Bearer ${token}` },
      body: JSON.stringify(user),
      method: 'POST',
    }
  )
    .then(async (response) =>
      processResponse({
        response,
        onAlert,
        messages: {
          success: {
            text: intl
              ? getSuccessMessage(user, intl)
              : getCreateUserNotification(user),
          },
        },
      })
    )
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const createExamResult = async ({
  token,
  user,
  examResult,
  onAlert,
  intl,
}: {
  token: string
  user: User
  examResult: ExamResult
  onAlert: Dispatch<SetStateAction<toastType | null>>
  intl: any
}): Promise<User[]> => {
  return fetch(`${config.apiUrl}/yapi/v1/users/${user.id}/exams`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    body: JSON.stringify(examResult),
    method: 'POST',
  })
    .then(async (response) => {
      return processResponse({
        response,
        onAlert,
        messages: {
          success: {
            text: intl.formatMessage(
              {
                id: 'add_exam_dialog.success',
              },
              {
                name: `${user.firstName} ${user.lastName}`,
              }
            ),
          },
        },
      })
    })
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getUserStats = async ({
  userId,
  token,
  onAlert,
}: {
  userId: string
  token: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<UserStats> => {
  return await fetch(`${config.apiUrl}/yapi/v1/users/${userId}/stats`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getLessonTopics = async ({
  token,
  onAlert,
}: {
  token: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<LessonTopics> => {
  return fetch(`${config.apiUrl}/yapi/v1/lessonTopics`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const resetPassword = async ({
  email,
  onAlert,
}: {
  email: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}) => {
  const payload = {
    client_id: config.auth.clientId,
    connection: 'Username-Password-Authentication',
    email,
  }

  return fetch(`https://${config.auth.domain}/dbconnections/change_password`, {
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
    method: 'POST',
  })
    .then(async (response) => {
      return processResponse({
        responseText: true,
        response,
        onAlert,
      })
    })
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getUser = async ({
  token,
  onAlert,
  id,
}: {
  token: string
  id?: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<User> => {
  return fetch(`${config.apiUrl}/yapi/v1/users/${id}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const updateUser = async ({
  token,
  user,
  onAlert,
  intl,
}: {
  token: string
  user: User
  onAlert: Dispatch<SetStateAction<toastType | null>>
  intl?: any
}): Promise<User> => {
  return fetch(`${config.apiUrl}/yapi/v1/users/${user.id}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    body: JSON.stringify(user),
    method: 'PATCH',
  })
    .then((response) =>
      processResponse({
        response,
        onAlert,
        messages: {
          success: {
            text: intl
              ? getUpdateUserMessage(user, intl)
              : 'User successfully update',
          },
        },
      })
    )
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const deleteUser = async ({
  token,
  user,
  onAlert,
  intl,
}: {
  token: string
  user: User
  onAlert: Dispatch<SetStateAction<toastType | null>>
  intl?: any
}): Promise<void> => {
  return fetch(`${config.apiUrl}/yapi/v1/users/${user.id}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'DELETE',
  })
    .then((response) => {
      processResponse({
        response,
        onAlert,
        messages: {
          success: {
            text: intl
              ? getDeleteUserMessage(user, intl)
              : 'User successfully deleted',
          },
        },
      })
    })
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getSessions = async ({
  token,
  offset = 0,
  limit = 20,
  onAlert,
  partnerId,
  userId,
  sortBy = 'startTimestamp',
  sortOrder = 'DESC',
  sessionStart,
  sessionEnd,
  searchQuery,
  vin,
  dongleId,
  driverId,
  driveType,
}: {
  token: string
  offset?: number
  limit?: number
  sortBy?: string
  sortOrder?: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
  partnerId?: string
  userId?: string
  sessionStart?: string
  sessionEnd?: string
  searchQuery?: string
  vin?: string
  dongleId?: string
  driverId?: string
  driveType?: number
}): Promise<Session> => {
  const queryStrings = parseQueryStrings({
    limit,
    offset,
    sort_by: sortBy,
    sort_order: sortOrder,
    partner_id: partnerId,
    user_id: userId,
    q: searchQuery,
    session_start: sessionStart,
    session_end: sessionEnd,
    vin,
    dongle_id: dongleId,
    driver_id: driverId,
    drive_type: driveType,
  })
  return fetch(`${config.apiUrl}/yapi/v2/sessions?${queryStrings}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getActiveSessions = async ({
  token,
  onAlert,
}: {
  token: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<Session> => {
  return fetch(`${config.apiUrl}/yapi/v1/sessions/active`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getPartners = async ({
  token,
  searchQuery,
  onAlert,
  sortBy,
  sortOrder,
}: {
  token: string
  searchQuery?: string | null
  onAlert: Dispatch<SetStateAction<toastType | null>>
  sortBy?: string
  sortOrder?: string
}): Promise<Partner[]> => {
  const queryStrings = parseQueryStrings({
    sort_by: sortBy,
    sort_order: sortOrder,
    q: searchQuery,
  })

  return fetch(`${config.apiUrl}/yapi/v1/partners?${queryStrings}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const createPartner = async ({
  token,
  partner,
  onAlert,
}: {
  token: string
  partner: CreatePartner
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<Partner[]> => {
  return fetch(`${config.apiUrl}/yapi/v1/partners`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    body: JSON.stringify(partner),
    method: 'POST',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const updatePartner = async ({
  token,
  partner,
  onAlert,
}: {
  token: string
  partner: Partner
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<Partner> => {
  return fetch(`${config.apiUrl}/yapi/v1/partners/${partner.id}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    body: JSON.stringify(partner),
    method: 'PATCH',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getPairingRequests = async ({
  token,
  onAlert,
  searchQuery,
  sortBy,
  orderBy,
  limit,
  offset,
  status,
}: {
  token: string
  searchQuery?: string
  sortBy?: string
  orderBy?: string
  limit?: number
  offset?: number
  onAlert: Dispatch<SetStateAction<toastType | null>>
  status?: string
}): Promise<PairingResponse> => {
  const queryStrings = parseQueryStrings({
    limit,
    offset,
    sort_by: sortBy,
    order_by: orderBy,
    q: searchQuery,
    status,
  })

  return fetch(`${config.apiUrl}/yapi/v1/pairings?${queryStrings}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const updatePairingRequest = async ({
  token,
  id,
  status,
  onAlert,
}: {
  token: string
  id: string
  status: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<void> => {
  return fetch(`${config.apiUrl}/yapi/v1/pairing/${id}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    body: JSON.stringify({ status }),
    method: 'PUT',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch(() => {
      // onAlert({
      //   text: `${error.name}: ${error.message}`,
      //   type: ToastTypes.error,
      // })
    })
}

const createPairingRequest = async ({
  token,
  pairingRequest,
  onAlert,
}: {
  token: string
  pairingRequest: CreatePairingRequest
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<void> => {
  return fetch(`${config.apiUrl}/yapi/v1/pairing`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    body: JSON.stringify(pairingRequest),
    method: 'POST',
  }).then((response) =>
    processResponse({
      response,
      onAlert,
      messages: {
        success: {
          text: 'Pairing request successfully created',
        },
      },
    })
  )
}

const getDevices = async ({
  token,
  onAlert,
  searchQuery,
  paired,
  sortBy,
  orderBy,
  partnerId,
}: {
  token: string
  searchQuery?: string
  paired?: boolean
  sortBy?: string
  orderBy?: string
  partnerId?: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<Device[]> => {
  const queryStrings = parseQueryStrings({
    sort_by: sortBy,
    paired,
    sort_order: orderBy,
    q: searchQuery,
    partnerId,
  })

  return fetch(`${config.apiUrl}/yapi/v1/devices?${queryStrings}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getDevice = async ({
  token,
  dongleId,
  onAlert,
}: {
  token: string
  dongleId?: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<Device> => {
  return fetch(`${config.apiUrl}/yapi/v1/devices/${dongleId}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const updateDevice = async ({
  token,
  device,
  onAlert,
}: {
  token: string
  device: Device
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<Device> => {
  return fetch(`${config.apiUrl}/yapi/v1/devices/${device.dongleId}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    body: JSON.stringify(device),
    method: 'PATCH',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const deleteDevice = async ({
  token,
  dongleId,
  onAlert,
}: {
  token: string
  dongleId: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<void> => {
  return fetch(`${config.apiUrl}/yapi/v1/devices/${dongleId}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'DELETE',
  })
    .then(() => {})
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const forceStopSession = async ({
  token,
  sessionId,
  onAlert,
}: {
  token: string
  sessionId: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<void> => {
  return fetch(
    `${config.apiUrl}/yapi/v1/activeSessions/${sessionId}?force=true`,
    {
      credentials: 'same-origin',
      headers: { authorization: `Bearer ${token}` },
      method: 'DELETE',
    }
  )
    .then(() => {})
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const startDevice = async ({
  token,
  dongleId,
  onAlert,
}: {
  token: string
  dongleId: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<void> => {
  return fetch(`${config.apiUrl}/yapi/v1/commands/startDevice`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    body: JSON.stringify({ dongleId }),
    method: 'PUT',
  })
    .then(() => {})
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getVehicles = async ({
  token,
  onAlert,
  searchQuery,
  limit = 20,
  offset,
  sortBy = 'vin',
  sortOrder = 'DESC',
  paired,
  partnerId,
}: {
  token: string
  searchQuery?: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
  limit?: number
  offset?: number
  sortBy?: string
  sortOrder?: string
  paired?: boolean
  partnerId?: string
}): Promise<VehicleResponse> => {
  const queryStrings = parseQueryStrings({
    sort_by: sortBy,
    sort_order: sortOrder,
    q: searchQuery,
    partnerId,
    paired,
    offset,
    limit,
  })

  return fetch(`${config.apiUrl}/yapi/v1/vehicles?${queryStrings}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const updateVehicle = async ({
  token,
  vehicle,
  onAlert,
}: {
  token: string
  vehicle: CreateVehicle
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<CreateVehicle> => {
  return fetch(`${config.apiUrl}/yapi/v1/vehicles/${vehicle.vin}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    body: JSON.stringify(vehicle),
    method: 'PATCH',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const createVehicle = async ({
  token,
  vehicle,
  onAlert,
}: {
  token: string
  vehicle: CreateVehicle
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<Device> => {
  return fetch(`${config.apiUrl}/yapi/v1/vehicles/`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    body: JSON.stringify(vehicle),
    method: 'POST',
  })
    .then((response) =>
      processResponse({
        response,
        onAlert,
        messages: {
          success: {
            text: 'Vehicle successfully created',
          },
        },
      })
    )
    .catch(() => {
      // TODO: Bring back when there is response body
      // onAlert({
      //   text: `${error.name}: ${error.message}`,
      //   type: ToastTypes.error,
      // })
    })
}

const getVehicle = async ({
  token,
  vin,
  onAlert,
}: {
  token: string
  vin?: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<Vehicle> => {
  return fetch(`${config.apiUrl}/yapi/v1/vehicles/${vin}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getSimDevices = async ({
  token,
  onAlert,
  sortBy,
  sortOrder,
  offset,
  partnerId,
}: {
  token: string
  partnerId?: string
  sortBy?: string
  sortOrder?: string
  offset?: number
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<SimRigs> => {
  const queryStrings = parseQueryStrings({
    sort_by: sortBy,
    sort_order: sortOrder,
    offset,
    partner_id: partnerId,
  })

  return fetch(`${config.apiUrl}/yapi/v1/sim/devices?${queryStrings}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getSimulationDrives = async ({
  token,
  onAlert,
  searchQuery,
  partnerId,
  userId,
  limit = 20,
  offset,
  sortBy,
  sortOrder = 'DESC',
  sessionStart,
  sessionEnd,
  simDeviceId,
}: {
  token: string
  searchQuery?: string
  partnerId?: string
  userId?: string
  limit?: number
  offset?: number
  sortBy?: string
  sortOrder?: string
  sessionStart?: string
  sessionEnd?: string
  simDeviceId?: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<SimulationDrives> => {
  const queryStrings = parseQueryStrings({
    sort_by: sortBy,
    sort_order: sortOrder,
    q: searchQuery,
    offset,
    limit,
    partner_id: partnerId,
    user_id: userId,
    session_start: sessionStart,
    session_end: sessionEnd,
    sim_device_id: simDeviceId,
  })
  return fetch(`${config.apiUrl}/yapi/v1/sim/sessions?${queryStrings}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getActiveSimulationDrives = async ({
  token,
  onAlert,
  limit = 20,
  offset,
  sortOrder = 'ASC',
}: {
  token: string
  limit?: number
  offset?: number
  onAlert: Dispatch<SetStateAction<toastType | null>>
  sortOrder?: string
}): Promise<SimulationDrives> => {
  const queryStrings = parseQueryStrings({
    offset,
    limit,
    sort_order: sortOrder,
  })
  return fetch(`${config.apiUrl}/yapi/v1/sim/sessions/active?${queryStrings}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const deleteSim = async ({
  token,
  id,
  onAlert,
}: {
  token: string
  id: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<void> => {
  return fetch(`${config.apiUrl}/yapi/v1/sim/sessions/active/${id}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'DELETE',
  })
    .then(() => {})
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const updateSimRig = async ({
  token,
  simRig,
  onAlert,
}: {
  token: string
  simRig: SimRigsData
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<SimRigsData> => {
  return fetch(`${config.apiUrl}/yapi/v1/sim/devices/${simRig.id}`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    body: JSON.stringify(simRig),
    method: 'PATCH',
  })
    .then((response) =>
      processResponse({
        response,
        onAlert,
        messages: {
          success: {
            text: 'Sim successfully updated',
          },
        },
      })
    )
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getSessionInfo = async ({
  token,
  onAlert,
  sessionId,
}: {
  token: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
  sessionId: string
}): Promise<SessionInfo> => {
  return fetch(`${config.apiUrl}/yapi/v1/sessions/${sessionId}/embodied`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert?.({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getIncidentOutcomes = async ({
  token,
  onAlert,
}: {
  token: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<IncidentOutcomes> => {
  return fetch(`${config.apiUrl}/yapi/v1/incidentOutcomes`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert?.({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getMetrics = async ({
  token,
  onAlert,
}: {
  token: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<MetricsData> => {
  return fetch(`${config.apiUrl}/yapi/v1/data/metrics`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert?.({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getEvents = async ({
  sessionId,
  token,
  onAlert,
}: {
  sessionId: string
  token: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<EventsData> => {
  return fetch(`${config.apiUrl}/yapi/v1/sessions/${sessionId}/events`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert?.({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const scenariosSearch = async ({
  token,
  search,
  onAlert,
}: {
  token: string
  search: any
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<Scenario[]> => {
  return fetch(`${config.apiUrl}/yapi/v1/scenarios/search`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    body: JSON.stringify(search),
    method: 'POST',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const getSavedQueries = async ({
  userId,
  token,
  onAlert,
}: {
  userId?: string
  token: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<SavedQueryData> => {
  return fetch(`${config.apiUrl}/yapi/v1/users/${userId}/searchQueries`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    method: 'GET',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert?.({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const saveSearchQuery = async ({
  userId,
  token,
  searchQuery,
  onAlert,
}: {
  userId?: string
  token: string
  searchQuery: any
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<void> => {
  return fetch(`${config.apiUrl}/yapi/v1/users/${userId}/searchQueries`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    body: JSON.stringify(searchQuery),
    method: 'POST',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const deleteSavedQuery = async ({
  token,
  userId,
  queryId,
  onAlert,
}: {
  token: string
  queryId: string
  userId?: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<void> => {
  return fetch(
    `${config.apiUrl}/yapi/v1/users/${userId}/searchQueries/${queryId}`,
    {
      credentials: 'same-origin',
      headers: { authorization: `Bearer ${token}` },
      method: 'DELETE',
    }
  )
    .then(() => {})
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const editSavedQuery = async ({
  token,
  userId,
  queryId,
  query,
  onAlert,
}: {
  token: string
  queryId: string
  query: any
  userId?: string
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<void> => {
  return fetch(
    `${config.apiUrl}/yapi/v1/users/${userId}/searchQueries/${queryId}`,
    {
      credentials: 'same-origin',
      headers: { authorization: `Bearer ${token}` },
      body: JSON.stringify(query),
      method: 'PATCH',
    }
  )
    .then((response) =>
      processResponse({
        responseText: true,
        response,
        onAlert,
        messages: {
          success: {
            text: 'Query successfully updated',
          },
        },
      })
    )
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

const sessionsSearch = async ({
  token,
  search,
  onAlert,
}: {
  token: string
  search: any
  onAlert: Dispatch<SetStateAction<toastType | null>>
}): Promise<SearchSession> => {
  return fetch(`${config.apiUrl}/yapi/v1/sessions/search`, {
    credentials: 'same-origin',
    headers: { authorization: `Bearer ${token}` },
    body: JSON.stringify(search),
    method: 'POST',
  })
    .then((response) => processResponse({ response, onAlert }))
    .catch((error) => {
      onAlert({
        text: `${error.name}: ${error.message}`,
        type: ToastTypes.error,
      })
    })
}

export {
  createExamResult,
  createPairingRequest,
  createPartner,
  createUser,
  createVehicle,
  deleteDevice,
  deleteSavedQuery,
  deleteSim,
  deleteUser,
  editSavedQuery,
  forceStopSession,
  getActiveSessions,
  getActiveSimulationDrives,
  getPairingRequests,
  getDevice,
  getDevices,
  getEvents,
  getIncidentOutcomes,
  getLessonTopics,
  getLoggedInUser,
  getMetrics,
  getPartnerStats,
  getPartners,
  getSavedQueries,
  getSessionById,
  getSessionInfo,
  getSessions,
  getSimDevices,
  getSimulationDrives,
  getUser,
  getUserStats,
  getUsers,
  getVehicle,
  getVehicles,
  resetPassword,
  saveSearchQuery,
  scenariosSearch,
  sessionsSearch,
  startDevice,
  updateDevice,
  updatePartner,
  updateUser,
  updatePairingRequest,
  getInstructorData,
  updateSimRig,
  updateVehicle,
}
