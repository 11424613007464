import classNames from 'classnames'
import style from './style.less'
import Tooltip from '../Tooltip'
import React, { memo, useCallback } from 'react'
import { isActiveRow } from './helpers'
import GridCellText from './GridCellTextElement'
import { Version } from '../types'
import { titleCase } from '../SearchQueryBar/utils'

interface GridCellElementProps {
  idx: number
  column: any
  data: any
  i: number
  onRowClick?: (rowIndex: number) => void
}

const GridCellElement = ({
  idx,
  column,
  data,
  i,
  onRowClick,
}: GridCellElementProps) => {
  const isActive = isActiveRow(data, idx)

  const onMouseOver = useCallback((idx: number) => {
    const row = [...document.querySelectorAll(`.gridItem${idx}`)]
    row.map((r) => r.classList.add(style.hoverRow))
  }, [])

  const onMouseLeave = useCallback((idx: number) => {
    const row = [...document.querySelectorAll(`.gridItem${idx}`)]
    row.map((r) => r.classList.remove(style.hoverRow))
  }, [])

  return column ? (
    <div
      className={classNames(
        style.gridItem,
        'gridItemSelector',
        `gridItem${idx}`,
        !column.align ? style[`gridItem${i}`] : undefined,
        !isActive && column.version !== Version.v2 ? style.inactive : undefined,
        column.icon ? style.gridItemCenter : undefined,
        column.align ? style[`gridItem${titleCase(column.align)}`] : undefined
      )}
      onMouseOver={() => onMouseOver(idx)}
      onMouseLeave={() => onMouseLeave(idx)}
      onClick={() => {
        const selection = window.getSelection()
        if (selection?.type !== 'Range') {
          onRowClick?.(idx - 1)
        }
      }}
    >
      {column?.tooltip ? (
        <Tooltip
          text={column.tooltip}
          position={i === 0 || i === 1 ? 'right' : 'left'}
        >
          <GridCellText column={column} />
        </Tooltip>
      ) : (
        <GridCellText column={column} />
      )}
    </div>
  ) : (
    <div
      className={classNames(
        style.gridItem,
        'gridItemSelector',
        `gridItem${idx}`
      )}
    ></div>
  )
}

export default memo(GridCellElement)
