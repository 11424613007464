import React, { ChangeEvent, useCallback, useState } from 'react'
import { Popover } from '@mui/material'
import style from './style.less'
import Property, { ALL } from './Property'
import Divider from '@yaak/components/src/Divider'
import {
  ChartSettings,
  MapSettings,
  MetadataSettings as MSettings,
  Setting,
  SETTINGS_TYPES,
  useMetadataStore,
} from '../../stores/MetadataStore'
import { useShallow } from 'zustand/react/shallow'
import Typography, { TypographyTypes } from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'
import { capitalize } from '../../utils'
import Icon, { IconSizes } from '@yaak/components/src/Icon/Icon'
import Tooltip from '@yaak/components/src/Tooltip'
import Switch from '@yaak/components/src/Switch'

interface SettingsProps {
  id: string
  anchorEl: HTMLButtonElement | null
  handleClose: () => void
}

const Settings = ({ anchorEl, handleClose, id }: SettingsProps) => {
  const {
    metadataSettings,
    mapSettings,
    updateMetadataSettings,
    updateMapSettings,
    chartSettings,
    updateChartSettings,
  } = useMetadataStore(
    useShallow((state) => ({
      metadataSettings: state.metadataSettings,
      mapSettings: state.mapSettings,
      chartSettings: state.chartSettings,
      updateMetadataSettings: state.updateMetadataSettings,
      updateMapSettings: state.updateMapSettings,
      updateChartSettings: state.updateChartSettings,
    }))
  )

  const settingsMapping: Record<
    string,
    MSettings | MapSettings | ChartSettings
  > = {
    'settings_panel-metadata': metadataSettings,
    'settings_panel-map': mapSettings,
    'settings_panel-plot': chartSettings,
  }

  const updateSettingsMapping: Record<string, (settings: any) => void> = {
    'settings_panel-metadata': updateMetadataSettings,
    'settings_panel-map': updateMapSettings,
    'settings_panel-plot': updateChartSettings,
  }

  const [allChecked, setAllChecked] = useState(true)

  const settings = settingsMapping[id] || {}
  const updateSettings = updateSettingsMapping[id] || (() => {})

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, label: string) => {
      const checked = event.target.checked
      const updatedSettings = {
        ...settings,
        [label]: {
          ...(settings[label as keyof MSettings] as MSettings),
          display: checked,
        },
      }
      updateSettings(updatedSettings)
    },
    [settings, updateSettings]
  )

  const onChangeAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked
      setAllChecked(checked)
      const updatedSettings = Object.keys(settings).reduce((acc, key) => {
        const setting: Setting = settings[key as keyof MSettings]
        setting.display = checked
        acc[key] = setting
        return acc
      }, {} as any)
      updateSettings(updatedSettings)
    },
    [settings, updateSettings]
  )

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      disablePortal
      onClose={handleClose}
      sx={{
        '& .MuiPopover-paper': {
          border: 'none',
          boxShadow:
            '0px 0px 8px -2px rgba(26, 26, 26, 0.10), 0px 0px 4px -2px rgba(26, 26, 26, 0.06)',
          overflow: 'visible',
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className={style.settings}>
        {id === 'settings_panel-metadata' && (
          <>
            <Property label={ALL} checked={allChecked} onChange={onChangeAll} />
            <Divider small />
          </>
        )}
        <>
          <div className={style.switchProperties}>
            {Object.keys(settings).map((settingKey) => {
              return ((settings as any)[settingKey] as any)?.type ===
                SETTINGS_TYPES.SWITCH ? (
                <div key={settingKey}>
                  <div className={style.switchProperty}>
                    <div className={style.switchPropertyLabel}>
                      <Typography
                        className={style.label}
                        type={TypographyTypes.label}
                        version={Version.v2}
                      >
                        {((settings as any)[settingKey] as any)?.displayName ||
                          capitalize(settingKey)}
                      </Typography>
                      {((settings as any)[settingKey] as any)?.tooltip && (
                        <Tooltip
                          text={((settings as any)[settingKey] as any)?.tooltip}
                          position={'right'}
                        >
                          <Icon
                            name={'Information'}
                            size={IconSizes.normal}
                            className={style.icon}
                            version={Version.v2}
                          />
                        </Tooltip>
                      )}
                    </div>
                    <Switch
                      checked={((settings as any)[settingKey] as any)?.display}
                      onClick={(event) => event.stopPropagation()}
                      onChange={(event) => onChange(event, settingKey)}
                    />
                  </div>
                  {((settings as any)[settingKey] as any)?.divider && (
                    <Divider small className={style.settingsDivider} />
                  )}
                </div>
              ) : (
                <Property
                  key={settingKey}
                  label={settingKey}
                  checked={((settings as any)[settingKey] as any)?.display}
                  onChange={onChange}
                />
              )
            })}
          </div>
        </>
      </div>
    </Popover>
  )
}

export default Settings
