import { create } from 'zustand'
import { Dataset } from '@yaak/components/services/api/api'

interface DatasetStoreState {
  dataset: Dataset | null
  updateDataset: (dataset: Dataset) => void
}

const additionalSearchFilters = [
  {
    category: 'sessionInformation',
    displayName: 'Session ID',
    filterType: 'filter',
    name: 'sessionId',
    supportedOperators: ['$eq', '$ne', '$in', '$nin'],
    type: 'string',
  },
  {
    category: 'sessionInformation',
    displayName: 'Date',
    filterType: 'filter',
    name: 'date',
    supportedOperators: ['$eq'],
    type: 'string',
  },
]

const UNSUPPORTED_FILTERS = ['geoPosition']
const UNSUPPORTED_FILTERS_VALUES = ['osmRouteRestriction']
const UNSUPPORTED_VALUES = ['PEDESTRIANS']

export const useDatasetStore = create<DatasetStoreState>((set) => ({
  dataset: null,
  updateDataset: (dataset) => {
    const filteredFilters = dataset?.searchFilters?.filter(
      (filter) => !UNSUPPORTED_FILTERS.includes(filter.name)
    )
    const filteredFiltersValues =
      filteredFilters?.map((filter) =>
        UNSUPPORTED_FILTERS_VALUES.includes(filter.name)
          ? {
              ...filter,
              values: filter.values?.filter(
                (value) => !UNSUPPORTED_VALUES.includes(value)
              ),
            }
          : filter
      ) || []
    return set(() => ({
      dataset: {
        ...dataset,
        searchFilters: [...additionalSearchFilters, ...filteredFiltersValues],
      },
    }))
  },
}))
