import style from './style.less'

export const isActiveRow = (
  data: {
    rows: any[]
    options?: any
  },
  i: number
) =>
  data.options && data.options.active && data.options.active.indexOf(i) !== -1

export const onMouseOver = (idx: number) => {
  const row = [...document.querySelectorAll(`.gridItem${idx}`)]
  row.map((r) => r.classList.add(style.hoverRow))
}

export const onMouseLeave = (idx: number) => {
  const row = [...document.querySelectorAll(`.gridItem${idx}`)]
  row.map((r) => r.classList.remove(style.hoverRow))
}

export const weatherConditionsMapping: Record<string, string> = {
  'thunderstorm with light rain': 'Thunderstorm',
  'thunderstorm with rain': 'Thunderstorm',
  'thunderstorm with heavy rain': 'Thunderstorm',
  'light thunderstorm': 'Thunderstorm',
  thunderstorm: 'Thunderstorm',
  'heavy thunderstorm': 'Thunderstorm',
  'ragged thunderstorm': 'Thunderstorm',
  'thunderstorm with light drizzle': 'Thunderstorm',
  'thunderstorm with drizzle': 'Thunderstorm',
  'thunderstorm with heavy drizzle': 'Thunderstorm',
  'light intensity drizzle': 'Drizzle',
  drizzle: 'Drizzle',
  'heavy intensity drizzle': 'Drizzle',
  'light intensity drizzle rain': 'Drizzle',
  'drizzle rain': 'Drizzle',
  'heavy intensity drizzle rain': 'Drizzle',
  'shower rain and drizzle': 'Drizzle',
  'heavy shower rain and drizzle': 'Drizzle',
  'shower drizzle': 'Drizzle',
  'light rain': 'Rain',
  'moderate rain': 'Rain',
  'heavy intensity rain': 'Rain',
  'very heavy rain': 'Rain',
  'extreme rain': 'Rain',
  'freezing rain': 'Rain',
  'light intensity shower rain': 'Rain',
  'shower rain': 'Rain',
  'heavy intensity shower rain': 'Rain',
  'ragged shower rain': 'Rain',
  rain: 'Rain',
  'light snow': 'Snow',
  snow: 'Snow',
  'heavy snow': 'Snow',
  sleet: 'Snow',
  'light shower sleet': 'Snow',
  'shower sleet': 'Snow',
  'light rain and snow': 'Snow',
  'rain and snow': 'Snow',
  'light shower snow': 'Snow',
  'shower snow': 'Snow',
  'heavy shower snow': 'Snow',
  mist: 'Mist',
  smoke: 'Smoke',
  haze: 'Haze',
  'sand/dust whirls': 'Dust',
  dust: 'Dust',
  fog: 'Fog',
  sand: 'Sand',
  'volcanic ash': 'Ash',
  squalls: 'Squall',
  tornado: 'Tornado',
  'clear sky': 'Clear',
  clear: 'Clear',
  clouds: 'Clouds',
  'few clouds': 'Clouds',
  'scattered clouds': 'Clouds',
  'broken clouds': 'Clouds',
  'overcast clouds': 'Clouds',
}
