import React from 'react'
import style from './style.less'
import Icon from '../Icon'
import { Version } from '../types'
import Typography, { TypographyTypes } from '../Typography'
import { Popover } from '@mui/material'
import classNames from 'classnames'

export interface ISortingSelectProps {
  onSort: (sortBy: string, order: string) => void
  sortBy?: string
  sortOrder: string
}

const sortIcon: Record<string, string> = {
  ASC: 'ArrowDown',
  DESC: 'ArrowUp',
}

const sortOptions = [
  { icon: 'Date', label: 'Date', value: 'startTimestamp' },
  { icon: 'Number', label: 'Duration', value: 'duration' },
  { icon: 'Text', label: 'Session ID', value: 'sessionId' },
]

const listHeaderMap: Record<string, string> = {
  incidentCount: 'Incidents',
  curriculumCount: 'Map features',
  lighting: 'Lighting',
  weather: 'Weather',
  driveType: 'Type',
  dongleId: 'Kit ID',
  driver: 'Driver',
}

const toggleSort = (order: string) => (order === 'ASC' ? 'DESC' : 'ASC')

const SortingSelect = (props: ISortingSelectProps) => {
  const { sortBy = 'startTimestamp', sortOrder, onSort } = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const handleSort = (sortValue: string) => () => {
    onSort(sortValue, sortBy === sortValue ? toggleSort(sortOrder) : 'ASC')
  }

  const disabledSort =
    sortBy && sortOptions.every(({ value }) => value !== sortBy)
  return (
    <>
      <button className={style.sortButton} onClick={handleClick}>
        <Icon name="Sorting" version={Version.v2} />
        <Typography type={TypographyTypes.label} version={Version.v2}>
          Sort
        </Typography>
      </button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        disablePortal
        onClose={handleClose}
        sx={{
          '& .MuiPopover-paper': {
            border: 'none',
            boxShadow:
              '0px 0px 8px -2px rgba(26, 26, 26, 0.10), 0px 0px 4px -2px rgba(26, 26, 26, 0.06)',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={style.sortingOptionsContainer}>
          <ul>
            {disabledSort && (
              <>
                <li className={classNames(style.listItem, style.disabled)}>
                  <span className={style.textContainer}>
                    <Icon name="Text" version={Version.v2} />
                    <Typography type={TypographyTypes.label}>
                      {listHeaderMap[sortBy]}
                    </Typography>
                  </span>
                  <Icon name={sortIcon[sortOrder]} version={Version.v2} />
                </li>
                <hr />
              </>
            )}
            {sortOptions.map(({ icon, label, value }) => (
              <li
                key={value}
                className={style.listItem}
                onClick={handleSort(value)}
              >
                <span className={style.textContainer}>
                  <Icon
                    name={icon}
                    version={Version.v2}
                    color="color-neutral-040"
                  />
                  <Typography type={TypographyTypes.label}>{label}</Typography>
                </span>
                <Icon
                  className={
                    style[`sortIcon${sortBy === value ? 'Selected' : ''}`]
                  }
                  name={sortIcon[sortBy === value ? sortOrder : 'ASC']}
                  version={Version.v2}
                />
              </li>
            ))}
          </ul>
        </div>
      </Popover>
    </>
  )
}

export default SortingSelect
