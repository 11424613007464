import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useState,
} from 'react'
import style from './style.less'
import Typography, { TypographySizes, TypographyTypes } from '../Typography'
import { Popper } from '@mui/material'
import Icon from '../Icon/Icon'
import { Version } from '../types'
import FilterMenuMultiTag from './FilterMenuMultiTag'
import FilterMenuInputTag from './FilterMenuInputTag'
import OperatorSelect from './OperatorSelect'
import { useTagsStore } from '../stores/Tags'
import { useShallow } from 'zustand/react/shallow'
import { useTag } from '../../customHooks/useTag'
import { useSearchParams } from 'react-router-dom'
import FilterSearch from './FilterSearch'
import FilterMenuDateTag from './FilterMenuDateTag'
import FilterMenuSwitchTag from './FilterMenuSwitchTag'
import { SEQUENCE } from '../SearchQueryBar/utils'

export interface FilterMenuTagProps {
  tagKey: string
  open: boolean
  setOperatorsRef: any
}

const FilterMenuTag = forwardRef(function FilterMenuMulti(
  { tagKey, open, setOperatorsRef }: FilterMenuTagProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const [searchParams] = useSearchParams()
  const [search, setSearch] = useState<string>('')
  const { tags, update } = useTagsStore(
    useShallow((state) => ({
      tags: state.tags,
      update: state.update,
    }))
  )
  const tag = useTag(tagKey, tags)

  const deleteTag = useCallback(() => {
    tags && update(tags.filter((t) => t.name !== tagKey))
  }, [tags, update, searchParams, tagKey])

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value || '')
  }

  return (
    tag && (
      <Popper
        className={style.filterMenuMulti}
        anchorEl={(ref as any)?.current}
        disablePortal
        open={open}
        style={{ zIndex: 1 }}
        placement={'bottom-start'}
      >
        <div className={style.filterMenuMultiHeader}>
          <div className={style.filterMenuMultiHeaderValue}>
            <Typography
              type={TypographyTypes.body}
              size={TypographySizes.small}
            >
              {tag.displayName || 'Sequence query'}
            </Typography>
            <OperatorSelect tagKey={tagKey} setOperatorsRef={setOperatorsRef} />
          </div>
          {tag.type === 'bool' && <FilterMenuSwitchTag tagKey={tagKey} />}
          <Icon
            name={'Delete'}
            version={Version.v2}
            color={'new-color-red-050'}
            className={style.deleteIcon}
            onClick={deleteTag}
          />
        </div>
        <div className={style.filterMenuMultiTags}>
          {tag.name === SEQUENCE ? (
            <></>
          ) : tag.type === 'bool' ? (
            <></>
          ) : tag.name === 'date' ? (
            <FilterMenuDateTag tagKey={tagKey} />
          ) : tag.values ? (
            <>
              {tag.values.length > 10 && (
                <FilterSearch
                  value={search}
                  onChange={handleSearchChange}
                  tag={true}
                />
              )}
              <FilterMenuMultiTag tagKey={tagKey} searchValue={search} />
            </>
          ) : (
            <FilterMenuInputTag tagKey={tagKey} />
          )}
        </div>
      </Popper>
    )
  )
})

export default memo(FilterMenuTag)
