import React, { memo, useCallback, useEffect, useMemo } from 'react'
import NestedSelect, { ISelectMenuItem } from '../NestedSelect'
import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'
import { availableCameras } from '@yaak/nutron/src/stores/MosaicStore'

interface GridWindow {
  id: string
  type: string
}

interface GridItemSelectStoreState {
  windows: Record<string, string>
  setWindows: (window: GridWindow) => void
  camera: string
  setCamera: (camera: string) => void
}

export const WINDOW_TYPES = {
  MAP_DATA: 'MAP DATA',
  TOPIC: 'topic',
}

export const useGridItemSelectStore = create<GridItemSelectStoreState>(
  (set) => ({
    windows: {},
    camera: 'CAM_FRONT_CENTER',
    setCamera: (camera) => set(() => ({ camera })),
    setWindows: (window: GridWindow) =>
      set((state) => ({
        windows: {
          ...state.windows,
          [window.id]: window.type,
        },
      })),
  })
)

interface GridItemSelectProps {
  sensorTopics: string[]
  mapData?: boolean
  id: string
}

const GridItemSelect = ({
  mapData,
  sensorTopics = availableCameras,
  id,
}: GridItemSelectProps) => {
  const { camera, setCamera, windows, setWindows } = useGridItemSelectStore(
    useShallow((state) => ({
      windows: state.windows,
      camera: state.camera,
      setCamera: state.setCamera,
      setWindows: state.setWindows,
    }))
  )

  useEffect(() => {
    const type = sensorTopics.includes(camera) ? camera : sensorTopics[0]
    setWindows({ id, type })
  }, [sensorTopics, id, setWindows])

  const handleChange = useCallback(
    (type: string, id: string) => {
      if (type === WINDOW_TYPES.MAP_DATA) {
        setWindows({
          id,
          type,
        })
      } else {
        setCamera(type)
        Object.keys(windows).forEach((windowKey) => {
          setWindows({
            id: windowKey,
            type,
          })
        })
      }
    },
    [id, windows]
  )

  const options: ISelectMenuItem[] = useMemo(
    () => [
      {
        label: 'Map data',
        value: WINDOW_TYPES.MAP_DATA,
        icon: 'Location',
      },
      {
        label: 'Sensor topic',
        value: WINDOW_TYPES.TOPIC,
        icon: 'Sensors',
        isSearchable: true,
        subMenuItems: sensorTopics.map((v) => ({ label: v, value: v })),
      },
    ],
    [mapData, sensorTopics]
  )

  const transformValue = useCallback(
    (val: string) => (val === 'MAP DATA' ? 'MAP DATA' : `/topic/${val}`),
    []
  )

  return (
    <NestedSelect
      id={id}
      value={windows[id]}
      onChange={handleChange}
      options={options}
      transformValue={transformValue}
    />
  )
}

export default memo(GridItemSelect)
