import React, { useCallback, useState } from 'react'
import FormDialog, {
  FormDialogTypes,
  FormTextInputProps,
} from '@yaak/components/src/FormDialog/FormDialog'
import { SavedQuery } from '../SearchQueryBar'
import { Tag } from '../../stores/Tags'
import { capitalize } from '@mui/material'
import {
  createTagsFromURL,
  OPERATORS_SYMBOL_MAPPING,
  queryToTags,
  SEQUENCE,
  sequenceToTags,
} from '../utils'
import { Dataset } from '../../../services/api/api'
import { useDatasetStore } from '@yaak/nutron/src/stores/DatasetStore'
import { useShallow } from 'zustand/react/shallow'

interface SaveQueryDialogProps {
  edit: any
  open: boolean
  onCancel: () => void
  savedQueries: SavedQuery[]
  onConfirmed: (
    result: any,
    inputs: any,
    sequenceQuery?: number
  ) => Promise<any>
  tags: Tag[]
  sequenceDuration?: number
}

interface buildSaveQueryDialogInputsProps {
  edit: any
  inputs: Tag[]
  deleteCallback: (input: any) => void
  onChange: (input: FormTextInputProps, value: string) => void
}

const buildSaveQueryDialogInputs = async ({
  edit,
  inputs,
  deleteCallback,
  onChange,
}: buildSaveQueryDialogInputsProps): Promise<FormTextInputProps[]> => {
  const dialogInputs: FormTextInputProps[] = [
    {
      name: 'name',
      title: 'Query name',
      placeHolderText: 'Enter query name',
      value: edit?.value,
    },
  ]

  inputs.forEach((input) => {
    dialogInputs.push({
      disabled: input.name === SEQUENCE,
      key: input.name,
      name: input.tagValue,
      subtitle: input.category ? capitalize(input.category) : '',
      title: '',
      value: input.tagValue,
      deleteCallback: inputs.length > 1 ? deleteCallback : undefined,
      onChange,
    })
  })

  return dialogInputs
}

const findQueryById = (savedQueries: any, id: string) =>
  savedQueries.filter((query: any) => query.id === id)[0]

const getSavedQueryTags = (savedQueries: any, id: string, dataset: Dataset) => {
  const q = findQueryById(savedQueries, id)
  const tags = queryToTags(q?.conditions?.query)
    .concat(queryToTags(q?.conditions?.filters))
    .concat(sequenceToTags(q?.conditions?.sequenceQuery?.sequence))
    .join('&')
  return createTagsFromURL(tags, dataset)
}

const SaveEditQueryDialog: React.FunctionComponent<SaveQueryDialogProps> = ({
  edit,
  open,
  onCancel,
  onConfirmed,
  savedQueries,
  tags,
  sequenceDuration,
}) => {
  const [inputs, setInputs] = useState<Tag[]>([])
  const { dataset } = useDatasetStore(
    useShallow((state) => ({
      dataset: state.dataset,
    }))
  )

  const deleteCallback = useCallback((input: any) => {
    setInputs((prev) => prev?.filter((i) => i.name !== input.key))
  }, [])

  const onChange = useCallback((input: any, value: string) => {
    setInputs((prev) =>
      prev?.map((i) => {
        if (i.name === input.key) {
          i.tagValue = value
          i.tagUrl = `${i.name} ${
            OPERATORS_SYMBOL_MAPPING[i.tagOperator]
          } ${value
            .replace(i.displayName, '')
            .replace(i.tagOperator, '')
            .trim()}`
        }
        return i
      })
    )
  }, [])

  const buildInputsCallback = useCallback(() => {
    const inputs: any = dataset
      ? getSavedQueryTags(savedQueries, edit?.id, dataset)
      : null

    setInputs(edit ? inputs : tags)
    return buildSaveQueryDialogInputs({
      edit,
      inputs: edit ? inputs : tags || [],
      deleteCallback,
      onChange,
    })
  }, [edit, savedQueries, tags])

  return (
    <FormDialog
      title={edit ? 'Edit query' : 'Save query'}
      dialogType={FormDialogTypes.edit}
      inputs={buildInputsCallback}
      open={open}
      confirmationTitle={''}
      onCancel={onCancel}
      onConfirmed={(result) => onConfirmed(result, inputs, sequenceDuration)}
    />
  )
}

export default SaveEditQueryDialog
