import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import style from './style.less'
import Icon from '@yaak/components/src/Icon'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'
import React from 'react'

export interface IErrorCollection {
  name: string
  scenarios: string[]
  sessions: string[]
}

interface IErrorDetails {
  errorItems: IErrorCollection[]
}

const ErrorDetails = ({ errorItems }: IErrorDetails) => {
  return (
    <div className={style.duplicateContainer}>
      <div>
        {errorItems.map((item) => (
          <Accordion
            key={item.name}
            sx={{
              border: 'none',
              background: '#F9F9F9',
              maxWidth: '100%',
              marginBottom: 1,
            }}
          >
            <AccordionSummary
              expandIcon={
                <Icon
                  name="ExpandMore"
                  version={Version.v2}
                  color="color-neutral-000"
                />
              }
            >
              <Typography type={TypographyTypes.label} version={Version.v2}>
                {`${item.name} (${
                  item.scenarios.length + item.sessions.length
                })`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {item.scenarios.length > 0 && (
                <div>
                  <Typography
                    type={TypographyTypes.label}
                    color="color-neutral-040"
                  >
                    Scenarios
                  </Typography>
                  <ul>
                    {item.scenarios.map((scenario, i) => (
                      <li key={scenario}>
                        <a href={scenario} target="_blank" rel="noreferrer">
                          <Typography
                            type={TypographyTypes.body}
                            size={TypographySizes.small}
                          >
                            Scenario {i + 1}
                          </Typography>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {item.sessions.length > 0 && (
                <div>
                  <Typography
                    type={TypographyTypes.label}
                    color="color-neutral-040"
                  >
                    Sessions
                  </Typography>
                  <ul>
                    {item.sessions.map((session, i) => (
                      <li key={session}>
                        <a href={session} target="_blank" rel="noreferrer">
                          <Typography
                            type={TypographyTypes.body}
                            size={TypographySizes.small}
                          >
                            Session {i + 1}
                          </Typography>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  )
}
export default ErrorDetails
