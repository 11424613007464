const config = {
  apiUrl: process.env.REACT_APP_YAAK_API_URL || '{{ REACT_APP_YAAK_API_URL }}',
  nutronApiUrl:
    process.env.REACT_APP_YAAK_NUTRON_API_URL ||
    '{{ REACT_APP_YAAK_NUTRON_API_URL }}',
  auth: {
    audience:
      process.env.REACT_APP_AUTH_AUDIENCE || '{{ REACT_APP_AUTH_AUDIENCE }}',
    clientId:
      process.env.REACT_APP_AUTH_CLIENT_ID || '{{ REACT_APP_AUTH_CLIENT_ID }}',
    domain: process.env.REACT_APP_AUTH_DOMAIN || '{{ REACT_APP_AUTH_DOMAIN }}',
    scope:
      process.env.REACT_APP_AUTH_SCOPE || 'openid profile email offline_access',
  },
  environment: process.env.REACT_APP_NODE_ENV || '{{ REACT_APP_NODE_ENV }}',
  urls: (locale = 'en') => {
    return {
      privacy: 'https://www.yaak.ai/privacy-customers',
      support: `https://help.yaak.ai/${locale}`,
      terms: 'https://www.yaak.ai/terms-of-service',
    }
  },
  locales: {
    default: 'en',
    supported: ['en', 'de'],
    url: '/lang',
  },
  roles: ['yaak-admin', 'ds-admin', 'ds-instructor', 'ds-student'],
  sentry: {
    dns: process.env.SENTRY_DNS || '{{ SENTRY_DNS }}',
  },
  mapbox: {
    accessToken: process.env.MAPBOX_ACCESS_TOKEN || '{{ MAPBOX_ACCESS_TOKEN }}',
  },
  lc: {
    license:
      process.env.LIGHTNING_CHART_JS_LICENSE ||
      '{{ LIGHTNING_CHART_JS_LICENSE }}',
  },
  sandbox: process.env.SANDBOX || '{{ SANDBOX }}',
  nutronUrl: process.env.NUTRON_URL || '{{ NUTRON_URL }}',
  reCaptchaSiteKey:
    process.env.RECAPTCHA_SITE_KEY || '{{ RECAPTCHA_SITE_KEY }}',
  GA: process.env.GA || '{{ GA }}',
}

export default config
