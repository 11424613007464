import React from 'react'
import Typography from '@yaak/components/src/Typography'
import {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import style from './style.less'
import Button from '../Button'
import { ICON_POSITIONS } from '../Button/Button'

interface EmptyButton {
  icon: React.ReactNode
  onClick: (event: React.MouseEvent<HTMLElement>) => void
  text: string
  right?: boolean
}

interface EmptyProps {
  header: string | React.ReactNode
  content: string | React.ReactNode
  button?: EmptyButton
}

const Empty: React.FunctionComponent<EmptyProps> = ({
  header,
  content,
  button,
}) => {
  return (
    <div className={style.empty}>
      <Typography type={TypographyTypes.headline} size={TypographySizes.small}>
        {header}
      </Typography>
      <Typography
        type={TypographyTypes.body}
        size={TypographySizes.large}
        className={style.body}
      >
        {content}
      </Typography>
      {button && (
        <Button
          className={style.emptyButton}
          icon={button.icon}
          onClick={button.onClick}
          text={button.text}
          iconPosition={
            button.right ? ICON_POSITIONS.RIGHT : ICON_POSITIONS.LEFT
          }
        />
      )}
    </div>
  )
}

export default Empty
