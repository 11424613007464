type CategoryMapping = {
  displayName: string
  icon: string
}

export const FILTER_CATEGORY_MAPPING: Record<string, CategoryMapping> = {
  environment: {
    displayName: 'Environment',
    icon: 'Earth',
  },
  routeInfrastructure: {
    displayName: 'Route Infrastructure',
    icon: 'Traffic',
  },
  routeTopography: {
    displayName: 'Route Topography',
    icon: 'Elevation',
  },
  sessionInformation: {
    displayName: 'Session Information',
    icon: 'Information',
  },
  telemetry: {
    displayName: 'Telemetry',
    icon: 'Speedometer',
  },
  triage: {
    displayName: 'Triage',
    icon: 'Annotation',
  },
  location: {
    displayName: 'Location',
    icon: 'Location',
  },
  other: {
    displayName: 'Other',
    icon: 'MoreHorizontal',
  },
}

export const SEARCH_ALL_FILTERS_ID = 'search-all-filters'

export const RANGE_NUM_REGEX = /^(-?\d+(.\d+)?%?)-(-?\d+(.\d+)?%?)$/g
